import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import {Route, Routes, useNavigate} from "react-router-dom";
import All from "./All";
import Active from "./Active";
import Paused from "./Paused";


import Cookies from "universal-cookie";
import Blocked from "./Blocked";

const cookies = new Cookies();

const TeamManage = () => {

    const [variable, setVariable] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (cookies.get("token")) {

        } else {
            navigate("/");
        }
    },[]);

    return (
        <div className="bg-gray-100 h-screen overflow-auto">
            <Header/>
            <Sidebar/>
            <TabsUnstyled defaultValue={0} className="m-2">

                <TabsListUnstyled
                    className="bg-white relative rounded-lg flex items-center text-neutral-400 content-between min-w-[320px] text-sm md:text-base">
                    <div>
                        <button
                            className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-semibold cursor-pointer
                            m-1 py-1 px-2.5 text-sm
                            md:px-8 md:text-xl md:py-2 md:1.5
                            lg:px-10 lg:text-xl lg:py-2 lg:m-1.5
                            ${window.location.pathname === "/team-manage/all" ? "bg-[#F1F1F1] text-sky-700" : ""} `}
                            onClick={() => navigate("all")}
                        >
                            All
                        </button>
                    </div>
                    <div
                        className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-bold cursor-pointer
                            m-1 py-1 px-2.5 text-sm
                            md:px-8 md:text-xl md:py-2 md:1.5
                            lg:px-10 lg:text-xl lg:py-2 lg:m-1.5
                            ${window.location.pathname === "/team-manage/active" ? "bg-[#F1F1F1] text-sky-700" : ""} `}
                        onClick={() => {
                            navigate("active")
                        }}
                    >
                        <button className="font-semibold">
                            Active
                        </button>
                    </div>
                    <div
                        className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-bold cursor-pointer
                            m-1 py-1 px-2.5 text-sm
                            md:px-8 md:text-xl md:py-2 md:1.5
                            lg:px-10 lg:text-xl lg:py-2 lg:m-1.5
                            ${window.location.pathname === "/team-manage/pause" ? "bg-[#F1F1F1] text-sky-700" : ""} `}
                        onClick={() => {navigate("pause")}}>
                        <button className="font-semibold">
                            Paused
                        </button>
                    </div>
                    <div
                        className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-bold cursor-pointer
                            m-1 py-1 px-2.5 text-sm
                            md:px-8 md:text-xl md:py-2 md:1.5
                            lg:px-10 lg:text-xl lg:py-2 lg:m-1.5
                            ${window.location.pathname === "/team-manage/block" ? "bg-[#F1F1F1] text-sky-700" : ""} `}
                        onClick={() => {navigate("block")}}>
                        <button className="font-semibold">
                            Blocked
                        </button>
                    </div>
                    <div>
                        <button
                            className="bg-[#0865B6] my-1.5 px-1  rounded text-white mr-5 md:px-3 md:absolute md:inset-y-0 md:right-0"
                            onClick={() => navigate("add-new-member")}
                        >
                            Add New Member
                        </button>
                    </div>
                </TabsListUnstyled>

                <TabPanelUnstyled
                    className="w-full"
                    value={0}
                    onClick={(e) => setVariable(e.currentTarget.id)}
                >

                </TabPanelUnstyled>

                <TabPanelUnstyled
                    className="w-full"
                    value={1}
                    onClick={(e) => setVariable(e.currentTarget.id)}
                >

                </TabPanelUnstyled>

                <TabPanelUnstyled
                    className="w-full"
                    value={2}
                    onClick={(e) => setVariable(e.currentTarget.id)}
                >
                </TabPanelUnstyled>
                <Routes>
                    <Route path="all" element={<All/>}/>
                    <Route path="active" element={<Active/>}/>
                    <Route path="pause" element={<Paused />}/>
                    <Route path="block" element={<Blocked />}/>
                </Routes>

            </TabsUnstyled>
        </div>
    )
}

export default TeamManage;