import React from "react";

const ConnectionTab = ({title, Icon, className}) => {
  return (
    <div className={className}>
      <div className="flex px-4 py-2 space-x-1 bg-default-gray items-center justify-center text-gray-400 rounded-lg hover:cursor-pointer button_effect">
        <button className=" font-semibold">{title}</button>
        {Icon && <Icon style={{ height: 18 }} />}
      </div>
    </div>
  );
};

export default ConnectionTab;
