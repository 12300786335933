import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ApplicationLabelText from "./ApplicationLabelText";

/**
 * @typedef {object} Props
 * @property {import("../../models/UserApplicationModel").ApplicantEducation} education
 */
/**
 * @param {Props} props
 */
export default function ApplicantEducation({ education }) {
    return (
        <Box marginY={"2rem"}>
            <Typography variant="h6" fontWeight={"500"}>
                Education
            </Typography>
            <Grid container sx={{ marginTop: "1rem" }} spacing={2}>
                <Grid item lg={3} sm={4} xs={6}>
                    <ApplicationLabelText
                        label="Course"
                        text={education.course}
                    />
                </Grid>
                <Grid item lg={3} sm={4} xs={6}>
                    <ApplicationLabelText
                        label="Specialization"
                        text={education.specialization}
                    />
                </Grid>
                <Grid item lg={3} sm={4} xs={6}>
                    <ApplicationLabelText
                        label="Institute"
                        text={education.instituteName}
                    />
                </Grid>
                <Grid item lg={3} sm={4} xs={6}>
                    <ApplicationLabelText
                        label="Passing year"
                        text={education.passingYear}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
