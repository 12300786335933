import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, FormControl, InputLabel, MenuItem, Pagination, Select, Stack, TextField } from "@mui/material";
import image1 from "./3135715.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import axios from "axios";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Cookies from "universal-cookie";
import { FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { LocalizationProvider, MobileDatePicker, LoadingButton } from "@mui/lab";
import moment from "moment";
import noTeam from "../../Images/empty_states/no-teamManage.png"
import Spinner from "../../components/Spinner";
import { EmptyState } from "../../components/EmptyStates/EmptyState";

const cookies = new Cookies();

const Active = () => {
  const [active, setActive] = useState("btn1");
  const [getAllData, setGetAllData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [id, setId] = useState("");
  const [loading , setLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(1);


  async function getData(page = 1) {
    await axios
      .get('/hri_admin/team/list?status_type=Active', {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
      },
      })
      .then((resp) => {
        setGetAllData(resp.data.results);
        setLoading(false)
        setTotalPages(Math.ceil(resp.data.count / 10));

        // setJobs(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const MemberDetails = (id) => {
    axios
      .get(`hri_admin/team/detail/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setMemberData(res.data);

        setProfilePic(res.data[0].image);
        setName(res.data[0].name);
        setEmailId(res.data[0].email);
        setStatus(res.data[0].status);
        setContact(res.data[0].phone_number);
        setEmployeId(res.data[0].employee_id);
        setDob(res.data[0].dob);
        setDesignation(res.data[0].role);
        setCity(res.data[0].city);
        setState(res.data[0].state);
        setPin(res.data[0].pin);
        setAdd(res.data[0].address);
        setGender(res.data[0].gender);
        setJoinedDate(res.data[0].created_at.slice(0, 10));
        console.log("Successfully getting member data: ", res.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const [profilePic, setProfilePic] = useState("");
  const [picReview, setPicReview] = useState(null);
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [contact, setContact] = useState("");
  const [status, setStatus] = useState("");
  const [employeId, setEmployeId] = useState("");
  const [dob, setDob] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pin, setPin] = useState("");
  const [add, setAdd] = useState("");
  const [designation, setDesignation] = useState("");
  const [gender, setGender] = useState("Male");
  const [joinedDate, setJoinedDate] = useState("");

  const PostMemberData = (id) => {
    let formField = new FormData();

    formField.append("name", name);
    formField.append("email", emailId);
    formField.append("status", status);
    formField.append("otp_phone", contact);
    formField.append("employee_id", employeId);
    formField.append("dob", dob);
    formField.append("role", designation);
    formField.append("city", city);
    formField.append("state", state);
    formField.append("pin", pin);
    formField.append("address", add);
    formField.append("gender", gender);
    formField.append("created_at", joinedDate);
    if (profilePic !== null) {
      formField.append("image", profilePic);
    }

    axios
      .put(`hri_admin/team/update/${id}`, formField, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        toast.info("Succesfully added details");
        setActive("btn2");
        MemberDetails(id);
        getData();
      })
      .catch((err) => {
        console.log("Error: ", err);
        toast.info("plz fill the required fields")

      });
  };

  useEffect(() => {
    // GetDetails();
    getData()
  }, []);

  return (
    <div>
      {getAllData.length ? (
        <div className="mt-3 mx-2 pb-20">
        {active === "btn1" ? (
          <div>
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {getAllData.map((data) => (
                <Card elevation={0} sx={{ borderRadius: "10px" }} key={data}>
                  <CardContent className="space-y-4">
                    <div className="flex flex-row px-3 gap-4 relative">
                      <div className="w-16 rounded-full ">
                        <img src={data.image ? data.image : image1}
                        className="h-16 rounded-full" alt="" />
                      </div>
                      <div>
                        <div className="flex flex-row ">
                          <p className="font-bold text-xl">{data.name}</p>
                              <p className="absolute inset-y-0 right-0 text-green-500 font-bold">
                                {data.status}
                              </p>
                        </div>
                        <p>
                          {data.role}
                          {/*<EditIcon className="ml-2 text-[#0865B6]" sx={{fontSize: "small"}}/>*/}
                        </p>
                      </div>
                    </div>

                    <div className="px-3 flex flex-row relative">
                      <div>
                        <p className="pt-2">
                          <AccessTimeIcon
                            className="mr-3 text-[#A9A9A9]"
                            sx={{ fontSize: "large" }}
                          />
                          since : {data.updated_at.slice(0, 10)}
                        </p>
                      </div>
                      <div className="absolute inset-y-0 right-0 cursor-pointer">
                        <p
                          className="text-[#0865B6] font-semibold hover:bg-[#F1F1F1] px-4 py-2 rounded"
                          onClick={() => {
                            setActive("btn2");
                            MemberDetails(data.id);
                            setId(data.id);
                          }}
                        >
                          View Details
                        </p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
          <Box
                sx={{ display: "flex", justifyContent: "center" }}
                marginBottom={"2rem"}
                mt={"1rem"}
            >
                <Pagination
                    count={totalPages}
                    color="primary"
                    shape="rounded"
                    onChange={(e, page) => {
                        getData(page);
                    }}
                />
            </Box>
          </div>
        ) : (
          <></>
        )}

        {active === "btn2" ? (
          <div>
            <Card className="" elevation={0} sx={{ borderRadius: "10px" }}>
              <CardContent>
                <div className="my-4 mx-2 flex flex-row">
                  <div className="flex-grow">
                    <p
                      className="w-20 text-[#968a8a] rounded py-2 text-center hover:text-white hover:bg-sky-700 cursor-pointer"
                      onClick={() => setActive("btn1")}
                    >
                      <ArrowBackIosNewIcon
                        className="mr-2 mb-2"
                        sx={{ fontSize: "small" }}
                      />
                      Back
                    </p>
                  </div>

                  <div className="cursor-pointer">
                    <p
                      className="text-[#0865B6] py-1 px-3 text-lg font-semibold bg-[#F1F1F1] text-center w-24 rounded"
                      onClick={() => setActive("btn3")}
                    >
                      Edit
                      <EditIcon className="ml-2" sx={{ fontSize: "medium" }} />
                    </p>
                  </div>
                </div>

                {memberData.map((data) => (
                  <div className="mx-5" key={data}>
                    <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5">
                      <div align="center w-32">
                        <img
                          className=" w-32 h-32 rounded-full"
                          src={data.image ? data.image : image1}
                          alt=""
                        />
                      </div>
                      <div className="col-span-4">
                        <p className="mt-4 text-4xl font-semibold">
                          {data.name ? data.name : "not provide"}
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 text-xl">
                          <div>
                            <p className="text-neutral-400">Email ID</p>
                            <p>{data.email ? data.email : "not provide"}</p>
                          </div>

                          <div>
                            <p className="text-neutral-400">Contact</p>
                            <p>{data.phone_number ? data.phone_number : "not provide"}</p>
                          </div>

                          <div>
                            <p className="text-neutral-400">Current Status</p>
                            <p>{data.status}</p>
                          </div>

                          <div>
                            <p className="text-neutral-400">Employee ID</p>
                            <p>{data.employee_id ? data.employee_id : "not provide"}</p>
                          </div>

                          <div>
                            <p className="text-neutral-400">Date of Birth</p>
                            <p>{data.dob ? data.dob : "not provide"}</p>
                          </div>

                          <div>
                            <p className="text-neutral-400">Designation</p>
                            <p>{data.role ? data.role : "not provide"}</p>
                          </div>

                          <div>
                            <p className="text-neutral-400">City</p>
                            <p>{data.city ? data.city : "not provide"}</p>
                          </div>

                          <div>
                            <p className="text-neutral-400">Sate</p>
                            <p>{data.state ? data.state : "not provide"}</p>
                          </div>

                          <div>
                            <p className="text-neutral-400">Pin</p>
                            <p>{data.pin ? data.pin : "not provide"}</p>
                          </div>

                          <div>
                            <p className="text-neutral-400">Address</p>
                            <p>{data.address ? data.address : "not provide"}</p>
                          </div>

                          <div>
                            <p className="text-neutral-400">Gender</p>
                            <p>{data.gender ? data.gender : "not provide"}</p>
                          </div>

                          <div>
                            <p className="text-neutral-400">Joined in Company</p>
                            <p>{data.created_at.slice(0, 10)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </CardContent>
            </Card>
          </div>
        ) : (
          <></>
        )}

        {active == "btn3" ? (
          <div>
            <Card className="" elevation={0} sx={{ borderRadius: "10px" }}>
              <CardContent>
                <div className="my-4 mx-2 flex flex-row">
                  <div className="flex-grow">
                    <p
                      className="w-20 text-[#9A9A9A] rounded py-2 text-center hover:text-white hover:bg-sky-700 cursor-pointer"
                      onClick={() => setActive("btn2")}
                    >
                      <ArrowBackIosNewIcon
                        className="mr-2"
                        sx={{ fontSize: "small" }}
                      />
                      Back
                    </p>
                  </div>
                  <div className="ml-10">
                    <p
                      className="text-[#0865B6] py-1 px-3 text-lg font-semibold bg-[#F1F1F1] text-center w-24 rounded cursor-pointer hover:text-green-500"
                      onClick={() => {
                        setActive("btn3");
                        PostMemberData(id);
                      }}
                    >
                      Save
                      <DoneAllIcon className="ml-2" sx={{ fontSize: "medium" }} />
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
                  <div className=" md:col-span-3 lg:col-span-1" align="center">
                  <div className="w-32 border-gray-600 rounded-full relative">
                      <img
                        className="h-32 rounded-full"
                        src={profilePic ? picReview || profilePic : image1}
                        alt="profilePic"
                        id="pic"
                      />
                      <label className="">
                        <FaRegEdit className="absolute ml-8 mt-[-3rem] text-lg xl:text-lg 2xl:text-2xl md:text-base  cursor-pointer" />
                        <input
                          className="w-20"
                          type="file"
                          onChange={(e) => {
                            setProfilePic(e.target.files[0]);
                            setPicReview(URL.createObjectURL(e.target.files[0]));
                          }}
                          style={{ visibility: "hidden" }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div>
                      <TextField
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="grid col-span-1 md:grid-cols-2 gap-4 mt-4">
                      <div>
                        <TextField
                          fullWidth
                          label="Email ID"
                          value={emailId ? emailId : ""}
                          onChange={(e) => setEmailId(e.target.value)}
                        />
                      </div>

                      <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Status</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="Status*"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Pause">Pause</MenuItem>
                        <MenuItem value="Block">Block</MenuItem>
                      </Select>
                    </FormControl>

                      <div>
                        <TextField
                          fullWidth
                          label="Contact"
                          value={contact ? contact : ""}
                          onChange={(e) => setContact(e.target.value)}
                        />
                      </div>

                      <div>
                        <TextField
                          fullWidth
                          label="Employee ID"
                          value={employeId ? employeId : ""}
                          onChange={(e) => setEmployeId(e.target.value)}
                        />
                      </div>

                      <Stack width="full">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            fullWidth
                            disableFuture
                            openTo="day"
                            views={['year', 'month', 'day']}
                            label="Date Of Birth"
                            value={dob}
                            onChange={(newValue) => {
                              setDob(moment(newValue).format("YYYY-MM-DD"));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Stack>

                      <div>
                        <TextField
                          fullWidth
                          label="Designation"
                          value={designation ? designation : ""}
                          onChange={(e) => setDesignation(e.target.value)}
                        />
                      </div>

                      <div>
                        <TextField
                          fullWidth
                          label="City"
                          value={city ? city : ""}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <div>
                        <TextField
                          fullWidth
                          label="State"
                          value={state ? state : ""}
                          onChange={(e) => setState(e.target.value)}
                        />
                      </div>

                      <div>
                        <TextField
                          fullWidth
                          label="Pin"
                          value={pin ? pin : ""}
                          onChange={(e) => setPin(e.target.value)}
                        />
                      </div>
                      <div>
                        <TextField
                          fullWidth
                          label="Address"
                          value={add ? add : ""}
                          onChange={(e) => setAdd(e.target.value)}
                        />
                      </div>

                      <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={gender ? gender : ""}
                        label="Gender*"
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="NA">Not specify</MenuItem>       
                      </Select>
                    </FormControl>

                      <Stack width={"full"}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            fullWidth
                            disableFuture
                            openTo="day"
                            views={['year', 'month', 'day']}
                            label="Joined in Company"
                            value={joinedDate ? joinedDate : ""}
                            onChange={(newValue) => {
                              setJoinedDate(moment(newValue).format("YYYY-MM-DD"));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>

                      </Stack>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ) : (
          <></>
        )}
      </div>
      ) : (
      <div>
        {loading ? (
          <Spinner />
        ) : (
          <div className="mt-11">
            <EmptyState 
              btn="Home"
              img={noTeam}
              title="No active members found"
              message="There are currently no active members to display. Invite new members or wait for existing members to become active"
              btnLink="/dashboard"
            />
            </div>
        )
        }
      </div>
      )
      }
      
    </div>
  );
};
export default Active;
