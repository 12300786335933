import axios from "axios";
import { handleException } from "../utils/handleException";
import { BackendAPI } from "../constants/backendAPI";
import Cookies from "universal-cookie";
import { createUserObj } from "../models/StudentModel";

const cookies = new Cookies();

/**
 * @param {string} searchText
 * @returns {Promise<[import("../models/StudentModel").StudentModel[] | null, import("../models/HttpResponse").HttpResponse | null]>}
 */

export async function getUserList(searchText = "") {
    const result = [null, null];
    try {
        const res = await axios.get(BackendAPI.student.studentList, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
            params: {
                keyword: searchText,
            },
        });
        const studentArr = [];
        for (let x = 0; x < res.data.length; x++) {
            studentArr.push(createUserObj(res.data[x]));
        }
        result[0] = studentArr;
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}
