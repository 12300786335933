import { Event } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    Modal,
    TextField
} from "@mui/material";
import { message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserList } from "../../controller/StudentController";
import { getCompanyMailList } from "../../controller/companyController";
import { scheduleMeeting } from "../../controller/meetingController";
import { handleControllerResultException } from "../../utils/handleException";
import { HTTPStatus } from "../../utils/helperObj";
import MultiSelectSuggestionTextField from "./MultiSelectSuggestionTextField";
import SuggestionTextField from "./SuggestionTextField";

/**
 * @typedef {object} Props
 * @property {boolean} open
 * @property {()=>void} onClose
 * @property {string | undefined} userEmail
 */

/**
 * @param {Props} props
 */

export default function ScheduleMeetingModal({
    open,
    onClose,
    userEmail = undefined,
}) {
    const [studentList, setStudentList] = useState(studentListType);
    const [selectedStudentEmails, setSelectedStudentEmails] = useState([]);
    const [companyList, setCompanyList] = useState(companyListType);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [meetingTitle, setMeetingTitle] = useState("");
    const [meetingDate, setMeetingDate] = useState("");
    const [meetingStartTime, setMeetingStartTime] = useState("");
    const [meetingDuration, setMeetingDuration] = useState("");
    const [meetingLink, setMeetingLink] = useState("");
    const [meetingPassCode, setMeetingPassCode] = useState("");
    const [meetingNote, setMeetingNote] = useState("");
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();
    const [savingMeeting, setSavingMeeting] = useState(false);

    const resetFields = () => {
        setSelectedStudentEmails(userEmail ? [userEmail] : []);
        setSelectedCompany("");
        setMeetingTitle("");
        setMeetingDate("");
        setMeetingStartTime("");
        setMeetingDuration("");
        setMeetingLink("");
        setMeetingPassCode("");
        setMeetingNote("");
        setShowError(false);
    };

    useEffect(() => {
        if (open) {
            resetFields();
        }
    }, [open, userEmail]);

    function validateFields() {
        return (
            selectedStudentEmails.length > 0 &&
            meetingTitle !== "" &&
            meetingDate !== "" &&
            meetingStartTime !== "" &&
            meetingDuration !== "" &&
            meetingLink !== ""
        );
    }

    async function findUserList(searchText) {
        const res = await getUserList(searchText);
        if (res[0] !== null) {
            setStudentList(res[0]);
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }

    async function findCompanyList(searchText) {
        const res = await getCompanyMailList(searchText);
        if (res[0] !== null) {
            setCompanyList(res[0]);
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }

    async function handleMeetingSave() {
        if (validateFields()) {
            setSavingMeeting(true);
            const res = await scheduleMeeting({
                studentEmails: selectedStudentEmails,
                companyEmail: selectedCompany,
                title: meetingTitle,
                date: meetingDate,
                startingTime: meetingStartTime,
                duration: meetingDuration,
                meetingNote,
                meetingPassCode,
                meetingLink,
            });
            setSavingMeeting(false);
            if (res.statusCode === HTTPStatus.CREATED) {
                message.success(res.success);
                onClose();
            } else {
                handleControllerResultException(res, navigate);
            }
        } else {
            setShowError(true);
        }
    }

    const date = moment().format();
    const today = date.split("T")
    const todayDate = today[0]

    return (
        <Modal
            open={open}
            onClose={() => {
                resetFields();
                onClose();
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                        lg: "40%",
                        xs: "95%",
                        sm: "40%"
                    },
                    bgcolor: "background.paper",
                    p: 4,
                    borderRadius: "5px",
                    maxHeight : {
                        lg : "700px",
                        xs : "550px",
                        sm : "600px"
                    },
                    overflowY: "auto",
                    margin : {
                        lg : "0px",
                        xs : "5px",
                        sm : "0px"
                    },
                    
                    
                }}
            >
                {!userEmail && (
                    <MultiSelectSuggestionTextField
                        options={studentList.map(data => data.email)}
                        searchFunction={findUserList}
                        selectedOptions={selectedStudentEmails}
                        setSelectOption={setSelectedStudentEmails}
                        textFieldLabel="Tag user email"
                        error={showError && selectedStudentEmails.length === 0}
                    />
                )}
                <Box marginTop={"1rem"}>
                    <SuggestionTextField
                        options={companyList.map(data => data.email)}
                        searchFunction={findCompanyList}
                        setSelectOption={setSelectedCompany}
                        textFieldLabel="Tag company (optional)"
                    />
                </Box>
                <TextField
                    label="Title"
                    value={meetingTitle}
                    onChange={e => setMeetingTitle(e.target.value)}
                    multiline
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "1rem" }}
                    error={showError && meetingTitle === ""}
                />
                <Box marginTop={"1rem"} display={"flex"} alignItems={"center"}>
                    <TextField
                        label="Meeting date"
                        value={meetingDate}
                        onChange={e => setMeetingDate(e.target.value)}
                        fullWidth
                        type="date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            inputProps: {
                                min: todayDate,
                            }
                            }}
                        error={showError && meetingDate === ""}
                    />
                    <TextField
                        label="Meeting start time"
                        value={meetingStartTime}
                        onChange={e => setMeetingStartTime(e.target.value)}
                        fullWidth
                        type="time"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        sx={{ marginLeft: "10px" }}
                        error={showError && meetingStartTime === ""}
                    />
                </Box>
                <Box marginTop={"1rem"} display={"flex"} alignItems={"center"}>
                    <TextField
                        label="Meeting duration (in minute)"
                        type="number"
                        value={meetingDuration}
                        onChange={e => setMeetingDuration(e.target.value)}
                        fullWidth
                        variant="outlined"
                        error={showError && meetingDuration === ""}
                    />
                    <TextField
                        label="Meeting link"
                        value={meetingLink}
                        onChange={e => setMeetingLink(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginLeft: "10px" }}
                        error={showError && meetingLink === ""}
                    />
                </Box>
                <TextField
                    label="Meeting passcode (optional)"
                    value={meetingPassCode}
                    onChange={e => setMeetingPassCode(e.target.value)}
                    fullWidth
                    variant="outlined"
                    sx={{ marginTop: "1rem" }}
                />
                <TextField
                    label="Meeting note (optional)"
                    value={meetingNote}
                    onChange={e => setMeetingNote(e.target.value)}
                    fullWidth
                    variant="outlined"
                    multiline
                    sx={{ marginTop: "1rem" }}
                    rows={5}
                />
                <Box
                    sx={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        disabled={savingMeeting}
                        sx={{ width: "250px" }}
                        variant="contained"
                        onClick={handleMeetingSave}
                    >
                        {savingMeeting ? (
                            <CircularProgress />
                        ) : (
                            <>
                                Schedule meeting
                                <Event fontSize="small" />
                            </>
                        )}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

/**
 * @type {import("../../models/StudentModel").StudentModel[]}
 */
const studentListType = [];

/**
 * @type {import("../../models/CompanyModel").CompanyMailModel[]}
 */

const companyListType = [];
