import React, {useEffect, useState} from 'react'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import {Link} from 'react-router-dom';
import Cookies from 'universal-cookie';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    TextField
} from "@mui/material";
import axios from 'axios';

const cookies = new Cookies();
const BillDetails = () => {

    const [billEdit, setEditBill] = useState(false)
    const [amount, setAmount] = useState("")
    const [decs, setDesc] = useState('')
    const [status, setStatus] = useState(false)
  

    const [note, setNote] = useState('')
    const [id, setId] = useState('')
    const navigate = useNavigate();

    async function updateData(e) {
        e.preventDefault();

        axios
            .put(
                `hri_admin/bill/update/${id}`,
                {
                    amount: amount,
                    bill_description: decs,
                    note: note,
                },
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {
                navigate("/Financials");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        if (cookies.get("token")) {
            setDesc(cookies.get("BillDesc"));
            setNote(cookies.get("BillNotes"));
            setId(cookies.get("BillId"));
            setStatus(cookies.get("BillStatus"));
            setAmount(cookies.get("BillAmount"));
        } else {
            navigate("/");
        }
    }, []);

    return (
        <>
            <Header/>
            <Sidebar/>
            <div className="bg-gray-100 ">
         
                <div
        
                >
                    <div className='pt-4'>
                        <div className=" mx-4 rounded-t-lg bg-sky-100 ">
                            <div className="flex items-center justify-between px-5 py-2 rounded-md z-10">
                                <Link to={"/Financials"}>
                                    <div
                                        className="text-blue-700 text-lg font-semibold flex items-center space-x-1 button_effect px-3 py-2 rounded-lg cursor-pointer">
                                        <ArrowBackIosNewIcon style={{height: 20}}/>
                                        <span>Back</span>
                                    </div>
                                </Link>
                                <div>

                                    <ToastContainer/>
                                </div>
                                {!billEdit ?
                                    <Button onClick={() => setEditBill(true)} className='w-auto px-5' size="small"
                                            variant="contained">Edit Bill</Button> :
                                    <Button onClick={() => setEditBill(true)} className='w-auto px-5' size="small"
                                            variant="contained" disabled>Edit Bill</Button>}


                            </div>
                            
                        </div>
                        {!billEdit ? <div className="bg-white px-5 lg:text-lg rounded-b-lg py-4 mx-4 z-10">
                            <div>
                                <div className='text-lg font-semibold flex'>Bill Description :</div>
                                {decs}</div>
                            <div>
                                <div className='text-lg mt-4 font-semibold flex'>Bill Amount :</div>
                                {amount}</div>
                            <div>
                                <div className='text-lg mt-4 font-semibold flex'>Status :</div>
                                {status ? "Paid" : "Pending"}</div>


                            <div className='mt-4'>
                                <div className='text-lg font-semibold flex '>Notes :</div>
                                {note}</div>


                        </div> : <div className="bg-white px-5 md:px-20 rounded-b-lg py-4 mx-4 z-10">

                            <div className='grid  mb-5'>

                                <TextField
                                    id="outlined-basic"
                                    type="number"
                                    label="Amount"
                                    variant="outlined"
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => {
                                        setAmount(e.target.value);
                                    }}

                                />
                            </div>
                            <div className='grid gap-y-5'>
                                <div>
                                    <div className='mb-2 font-semibold'>Description :</div>
                                    <textarea

                                        className="h-28 2xl:placeholder:pl-6 placeholder:text-xs md:placeholder:text-base xl:placeholder:text-lg 2xl:placeholder:text-3xl 2xl:mt-6 mt-1 rounded-lg block  w-full border border-slate-300  py-2 pl-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                        placeholder="Discribe anything in message"
                                        cols="10"
                                        rows="5"
                                        value={decs}
                                        onChange={(e) => setDesc(e.target.value)}
                                    /></div>
                                <div>

                                    <div className='mb-2 font-semibold'>Note :</div>
                                    <textarea
                                        className="h-28 2xl:placeholder:pl-6 placeholder:text-xs md:placeholder:text-base xl:placeholder:text-lg 2xl:placeholder:text-3xl 2xl:mt-6 mt-1 rounded-lg block  w-full border border-slate-300  py-2 pl-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                        placeholder="Discribe anything in message"
                                        cols="10"
                                        rows="5"
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                    />
                                </div>
                                paid
                            </div>
                            <div className='mt-4'>
                                <Button onClick={updateData} className='w-auto px-5' size="small"
                                        variant="contained">Save</Button>
                            </div>
                        </div>}

                    </div>

                </div>
            </div>
        </>
    )
}

export default BillDetails