import { useEffect } from "react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/high-res.css";
import "react-phone-input-2/lib/material.css";

export function MobileNumberInput({ onChange, initVal, label }) {
    const [num, setNum] = useState(null);

    useEffect(() => {
        if (initVal) setNum(initVal);
    }, [initVal]);

    function handleNumChange(num) {
        setNum(num);
        if (onChange) onChange(num);
    }

    return (
        <PhoneInput
            country={"in"}
            value={num}
            onChange={handleNumChange}
            placeholder={"Contact"}
            inputStyle={{ width: "100%", backgroundColor: "rgba(0,0,0,0)" }}
            containerStyle={{ backgroundColor: "rgba(0,0,0,0)" }}
            buttonStyle={{ backgroundColor: "rgba(0,0,0,0)" }}
            searchPlaceholder="Search Country"
        />
    );
}
