import ArticleIcon from "@mui/icons-material/Article";
import { LocalizationProvider, PickersDay, StaticDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Box,
  Modal,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import image from "./undraw_beach_day_cv97 1.png";

import AddIcon from "@mui/icons-material/Add";
import { green } from "@mui/material/colors";
import { isSameDay } from "date-fns";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import ScheduleMeetingModal from "../../components/ScheduleMeet/ScheduleMeetingModal";
import { handleControllerResultException, handleException } from "../../utils/dataFetchHelper";

const cookies = new Cookies();

const Calendar = () => {
  const navigate = useNavigate();

  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthValue = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const dateValue = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];

  const [value, setValue] = useState(new Date());

  const [meetDetail, setMeetDetail] = useState([]);
  const [calendarMeet, setCalendarMeet] = useState([]);
  const [nextMeet, setNextMeet] = useState([]);
  const [meetingDates, setMeetingDates] = useState([]);
  const [modal, setModal] = useState(false);
  const [meetId, setMeetId] = useState(null)

  const fetchMeetingDate = useCallback(async () => {
    try {
        const res = await axios.get('hri_admin/next-meeting-dates', {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        });
        setMeetingDates(res.data.next_dates);
    } catch (err) {
        const errRes = handleException(err);
        handleControllerResultException(errRes, navigate);
    }
}, []);

useEffect(() => {
    fetchMeetingDate();
}, [fetchMeetingDate]);

  const meetingDetail = (id) => {
    axios
      .get(`hri_admin/meeting/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setMeetDetail(res.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const getCalendar = (date, month, year) => {
    if (date === undefined && month === undefined && year === undefined) {
      date = dateValue[value.getDate() - 1];
      month = monthValue[value.getMonth()];
      year = value.getFullYear();
    }

    axios
      .get(`hri_admin/meeting-by-date/${year}-${month}-${date}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setCalendarMeet(res.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const nextMeeting = () => {
    axios
      .get(`hri_admin/next-meeting`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        console.log("Meeting Data: ", res.data);
        setNextMeet(res.data);
        setValue(new Date(res.data[0].date));
        getCalendar(
          res.data[0].date.slice(8, 10),
          res.data[0].date.slice(5, 7),
          res.data[0].date.slice(0, 4)
        );
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  async function cancelMeet(id) {
    if (cancel.length < 2) return toast.error("Please provide a cancel reason")

    try {
      const res = await axios.post(`hri_admin/meeting-cancel/${id}`, {
        cancel_reason: cancel
      }, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      if (res.status !== 200) return toast.error("Opps! Unable to cancel meeting")
      toast.warn("Meeting canceled!")
      getCalendar()
    } catch (error) {
      toast.error("Something went wrong")
      throw new Error("Unable to cancel meeting")
    } finally {
      setModal(false)
    }
  }

  function handleCancelClick(id) {
    setMeetId(id)
    setModal(!modal)
  }

  const [open, setOpen] = React.useState(false);
  const [cancel, setCancel] = React.useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function renderDay(day, _selectedDay, props) {
    const isMarked = meetingDates.some((meetingDate) =>
        isSameDay(new Date(meetingDate), day)
    );
    if (isMarked) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <PickersDay {...props} sx={{ backgroundColor: green[200] }} />
            </Box>
        );
    } else {
        return <PickersDay {...props} />;
    }
}

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  useEffect(() => {
    if (cookies.get("token")) {
      getCalendar();
    } else {
      navigate("/");
    }
  }, []);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  };

  return (
    <div className="bg-default-background h-screen overflow-auto">
      <Header />
      <Sidebar />
      <ScheduleMeetingModal
        open={open}
        onClose={handleClose}
      />
      <div>
        <div className="bg-white flex flex-col m-1 rounded-lg md:flex-row md:m-4">
          <div className="text-center md:mx-4 ">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                views={["year", "month", "day"]}
                value={value}
                renderDay={renderDay}
                onChange={(newValue) => {
                  setValue(newValue);
                  getCalendar(
                    dateValue[newValue.getDate() - 1],
                    monthValue[newValue.getMonth()],
                    newValue.getFullYear()
                  );
                }}
              // renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>

          <div className="bg-[#0865B6] rounded-lg text-white px-4 flex-grow">
            <div className="flex pt-3">
              <div className="text-5xl">
                <p className="flex justify-end pr-2">
                  {dateValue[value.getDate() - 1]}
                </p>
              </div>
              <div className="flex flex-col">
                <p>{monthList[value.getMonth()]}</p>
                <p>{value.getFullYear()}</p>
              </div>
            </div>

            {calendarMeet.length > 0 ? (
              <div className="pt-3 pb-8 px-4 space-y-2">
                <p className="text-lg">MEETINGS</p>
                {calendarMeet.map((meet) => (
                  <div key={meet.id}>
                    <div className="flex flex-col px-2 py-1 gap-2 bg-[#FDFDFD80] text-[16px] md:flex-row md:gap-6">
                      <p>
                        {meet.start_time} - {meet.end_time}
                      </p>
                      <p>{meet.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center pt-4">
                <p className="text-xl ">No meetings Scheduled for the day</p>
              </div>
            )}
          </div>
        </div>

        <div>
          {calendarMeet.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2">
              {calendarMeet.map((data, i) => {
                return (
                  <div className="bg-white rounded-lg relative mx-4 my-3" key={data.id}>

                    <div className="absolute top-0 left-0 flex items-center justify-center bg-[#4F95FD]
                                text-white w-24 h-12 text-base font-semibold rounded-br-lg rounded-tl-lg"
                    >
                      {moment(data.date).format("dddd")}
                    </div>
                    <div className="pt-16 px-5 pb-3">
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <p className="text-2xl py-1">
                          <ArticleIcon className="mr-2" fontSize="medium" sx={{ color: "#A9A9A9" }} />
                          {" "}
                          {data.title}
                        </p>
                        {data.cancel ?
                          <div className="bg-red-500 text-white py-1 px-2 rounded-lg text-xs font-semibold cursor-default">Canceled</div> :
                          <div onClick={() => handleCancelClick(data.id)} style={{ borderWidth: 1, borderColor: "#4f95fd" }} className="text-[#4f95fd] py-1 px-2 rounded-lg text-xs font-semibold cursor-pointer">Cancel Meeting</div>}

                      </div>
                      <p>User: {data.user_name}</p>
                      <p>Company name : {data.company_name}</p>

                      <h3 className="text-lg font-semibold">Meeting details</h3>
                      <div className="grid grid-cols-2">
                        <div className="">
                          <p>
                            Meeting start at : {moment(data.start_time, "HH:mm:ss").format("hh mm A")}
                          </p>
                        </div>
                        <div className="flex justify-end">
                          <p>Duration : {data.duration}min</p>
                        </div>
                        <div className="col-span-full">
                          {data.cancel ?
                            <div className="">
                              <p className="">
                                Cancel Reason : {data.cancel_reason}
                              </p>
                            </div>

                            : <div className="flex justify-between">
                              <p>Meeting password : {data.meeting_password ? data.meeting_password : "No password required"}</p>

                              <p>Meet on: <a href={data.meeting_link} target="_blank" className={`font-semibold no-underline text-blue-500`} >Join</a></p>

                            </div>}
                        </div>

                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div
              className="flex md:h-[30vh] overflow-hidden
                    sm:h-[auto] flex-col justify-center md:flex-row"
            >
              <img src={image} alt="" className="md:scale-[1.7] p-5" />
              <div className="flex-col px-3 self-center">
                <p className="text-3xl font-bold">
                  No events or meetings today
                </p>
                {/* <p className=" text-xl">
                  <span
                    onClick={() => {
                      nextMeeting();
                    }}
                    className="text-[#0865B6] cursor-pointer"
                  >
                    Click here{" "}
                  </span>
                  to go to next day with the shedule.
                </p> */}
              </div>
            </div>
          )}
        </div>
        {modal &&
          <Modal
            open={modal}
            onClose={() => setModal(!modal)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure, you want to cancel meeting?
              </Typography>
              <TextField variant="outlined" size="small" sx={{ mt: 2 }} label="Reason for cancellation" onChange={(e) => setCancel(e.target.value)} />
              <div style={{ display: "flex", justifyContent: 'flex-start', gap: 5, marginTop: 20 }}>
                <Button variant="contained" sx={{ borderColor: "#4f95fd", color: "#fff", fontSize: 10 }} size="small" onClick={() => setModal(false)}>No</Button>
                <Button variant="contained" color="error" size="small" sx={{ fontSize: 10 }} onClick={() => cancelMeet(meetId)}>Yes Cancel</Button>
              </div>
            </Box>
          </Modal>}
      </div>

      {meetDetail.map((detail) => {
        const detailDate = new Date(detail.date);
        return (
          <Modal
            open={open2}
            onClose={handleClose2}
            className="flex justify-center items-center"
          >
            <Box className="space-y-2 bg-white p-5 rounded-lg w-5/6 md:w-4/6 lg:w-2/6">
              <div>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Title: {detail.title}
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Company: {detail.company ? detail.company : "null"}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Note: {detail.note}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Date: {detailDate.getDate()}&nbsp;
                  {monthList[detailDate.getMonth()]}, {detailDate.getFullYear()}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Time: {detail.start_time} - {detail.end_time}
                </Typography>
              </div>
            </Box>
          </Modal>
        );
      })}
      <Tooltip title="Add meeting" placement="left">
        <div
          className="z-10 bg-blue-200 w-16 h-16 rounded-full flex mb-20 items-center justify-center absolute bottom-10 right-10 hover:cursor-pointer hover:opacity-50 active:opacity-70 duration-300"
          onClick={handleOpen}
        >
          <span>
            <AddIcon />
          </span>
        </div>
      </Tooltip>
    </div>
  );
};

export default Calendar;
