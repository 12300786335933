import { MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HTTPStatus } from "../../utils/helperObj";
import { message } from "antd";
import { handleControllerResultException } from "../../utils/handleException";
import { changeApplicationStatus } from "../../controller/UserApplicationController";

/**
 * @typedef {object} Props
 * @property {number} applicationId
 * @property {string} currentStatus
 */

/**
 * @param {Props} props
 */
export default function ApplicationStatus({
    applicationId,
    currentStatus = "Applied",
}) {
    const [status, setStatus] = useState(currentStatus);
    const [changingStatus, setChangingStatus] = useState(false);
    const navigate = useNavigate();

    async function handleChangeStatus(status) {
        setChangingStatus(true);
        const res = await changeApplicationStatus(applicationId, status);
        setChangingStatus(false);
        if (res.statusCode === HTTPStatus.SUCCESS) {
            message.success(res.success);
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    return (
        <Select
            disabled={changingStatus}
            value={status}
            onChange={e => {
                handleChangeStatus(e.target.value);
                setStatus(e.target.value);
            }}
            variant="standard"
        >
            <MenuItem value={"Applied"}>Applied</MenuItem>
            <MenuItem value={"In-Review"}>In-Review</MenuItem>
            <MenuItem value={"Interview"}>Interview</MenuItem>
            <MenuItem value={"Selected"}>Selected</MenuItem>
            <MenuItem value={"Rejected"}>Rejected</MenuItem>
        </Select>
    );
}
