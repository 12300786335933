import React from "react";
import Header from "../../components/Header";
import {Route, Routes, useNavigate} from "react-router-dom";
import IndividualUser from "./IndividualUser";
import UnRegisteredUser from "./UnRegisteredUser";
import BasicDetails from "./BasicDetails";
import {IconButton} from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Education from "./Education";
import WorkExperience from "./WorkExperience";
import Skills from "./Skills";

const UserDetails = () => {

    const navigate = useNavigate();

    return (
        <div className="bg-[#EDEBEE] h-screen overflow-auto">
            <Header/>
            <div className="flex bg-white space-x-4 rounded m-2 p-1.5">
                <IconButton onClick={() => navigate("/user-management/unregistered-user")}>
                    <ArrowBackIosNewIcon fontSize="small"/>
                </IconButton>
                <button
                    className={`px-3 py-1.5 font-bold text-lg rounded-md
                        ${window.location.pathname === "/user-management/unregistered-user/details/personal" ? "text-[#0865B6] bg-[#F1F1F1]" : "text-[#5F5F5F] hover:text-[#0865B6] hover:bg-[#F1F1F1]"}`}
                    onClick={() => navigate("/user-management/unregistered-user/details/personal")}
                >
                    Personal
                </button>
                <button
                    className={`px-3 py-1.5 font-bold text-lg rounded-md
                        ${window.location.pathname === "/user-management/unregistered-user/details/skills" ? "text-[#0865B6] bg-[#F1F1F1]" : "text-[#5F5F5F] hover:text-[#0865B6] hover:bg-[#F1F1F1]"}`}
                    onClick={() => navigate("/user-management/unregistered-user/details/skills")}
                >
                    Skills
                </button>
                <button
                    className={`px-3 py-1.5 font-bold text-lg rounded-md
                        ${window.location.pathname === "/user-management/unregistered-user/details/education" ? "text-[#0865B6] bg-[#F1F1F1]" : "text-[#5F5F5F] hover:text-[#0865B6] hover:bg-[#F1F1F1]"}`}
                    onClick={() => navigate("/user-management/unregistered-user/details/education")}
                >
                    Education
                </button>
                <button
                    className={`px-3 py-1.5 font-bold text-lg rounded-md
                        ${window.location.pathname === "/user-management/unregistered-user/details/work-experience" ? "text-[#0865B6] bg-[#F1F1F1]" : "text-[#5F5F5F] hover:text-[#0865B6] hover:bg-[#F1F1F1]"}`}
                    onClick={() => navigate("/user-management/unregistered-user/details/work-experience")}
                >
                    Work Experience
                </button>
            </div>

            <Routes>
                <Route path="personal" element={<BasicDetails/>}/>
                <Route path="skills" element={<Skills/>}/>
                <Route path="education" element={<Education/>}/>
                <Route path="work-experience" element={<WorkExperience/>}/>
            </Routes>
        </div>
    )
}

export default UserDetails;