import React from 'react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import Header from '../../components/Header';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Fresher from "./Fresher";
import Experience from './Experience';

export default function ProfileVerify() {
    const location = useLocation();
    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        navigate(newValue);
    };

    const getTabValue = () => {
        if (location.pathname.includes('fresher')) {
            return "/profileVerification/fresher/All";
        } else if (location.pathname.includes('experience')) {
            return "/profileVerification/experience/All";
        }
        return "/profileVerification/fresher/All";
    };

    const getSubTab = () => {
        if (location.pathname.includes('Initiated')) {
            return 'Initiated';
        } else if (location.pathname.includes('Verified')) {
            return 'Verified';
        } else if (location.pathname.includes('Rejected')) {
            return 'Rejected';
        }
        return 'All';
    };

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ margin: "3rem auto" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Tabs
                        value={getTabValue()}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Fresher" value="/profileVerification/fresher/All" />
                        <Tab label="Experienced" value="/profileVerification/experience/All" />
                    </Tabs>
                </Box>
            </Container>
            <Routes>
                <Route
                    path="fresher/:fresherType"
                    element={<Fresher currentSubTab={getSubTab()} onSubTabChange={(tab) => navigate(`/profileVerification/fresher/${tab}`)} />}
                />
                <Route
                    path="experience/:experienceType"
                    element={<Experience currentSubTab={getSubTab()} onSubTabChange={(tab) => navigate(`/profileVerification/experience/${tab}`)} />}
                />
            </Routes>
        </Box>
    );
}
