
const SocialTabs = ({Link , img }) => {
 
 console.log(Link)

  return (
    <div>
    
        {/* <a  > */}
        <a href={"https://"+Link} target="_blank"><div className="flex px-4 py-2 gap-2 md:gap-4 space-x-2 bg-white items-center justify-start text-default-dark-gray border border-gray-500 rounded-md hover:cursor-pointer button_effect">
          <img src={img} alt="social images" className='w-10 h-10' />
          <div className="w-12 md:w-[10rem] truncate">{Link}</div>
        </div></a>
        {/* </a> */}
      
    </div>
  )
}

export default SocialTabs
