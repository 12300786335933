/**
 * @typedef {object} CompanyMailModel
 * @property {string} email
 * @property {string} name
 * @property {number} id
 * @property {number} userId
 */

/**
 * @typedef {object} CompanyModel
 * @property {number} id
 * @property {string} name
 * @property {boolean} status
 * @property {boolean} companyBlock
 * @property {string} companyEmail
 * @property {string} contactEmail
 * @property {string} founder
 * @property {string} ceo
 * @property {string} contactNumber
 * @property {string} contactPerson
 * @property {string} address
 * @property {string} city
 * @property {string} state
 * @property {string} country
 * @property {string} pinCode
 * @property {string} companyLogo
 * @returns
 */

export function createCompanyMailObj(data) {
    /**
     * @type {CompanyMailModel}
     */
    const result = {
        email: data.email,
        name: data.name !== null ? data.name : "",
        id: data.id,
        userId: data.user_id,
    };
    return result;
}

export function createCompanyObj(data) {
    /**
     * @type {CompanyModel}
     */
    const result = {
        id: data.id,
        name: data.name,
        ceo: data.company_ceo,
        address: data.address,
        city: data.city,
        companyBlock: data.company_block,
        companyEmail: data.company_email,
        companyLogo: data.company_logo,
        contactEmail: data.contact_email,
        contactNumber: data.contact_number,
        contactPerson: data.contact_person,
        country: data.country,
        founder: data.company_founder,
        pinCode: data.pin,
        state: data.state,
        status: data.status,
    };
    return result;
}
