import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../utils/request";
import moment from "moment";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { isValidMessage } from "../../helpers/validate_message";
import axios from "axios";
import { toast } from "react-toastify";

import Cookies from "universal-cookie";
import useCreateEditor from "../../hooks/useCreateEditor";
import Editor from "../../components/richEditor/Editor";

const cookies = new Cookies();

const BroadcastMessage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [active1, setActive1] = useState("Users");
    const [active2, setActive2] = useState("email");

    const [select, setSelect] = useState("Users");
    // const [type, setType] = useState("username");
    const [type2, setType2] = useState("email");
    const [user, setUser] = useState([]);
    const [team, setTeam] = useState([]);
    const [company, setCompany] = useState([]);

    const [receiver_id, setReceiver_id] = useState([]);
    const [message_title, setMessageTitle] = useState("");
    const [message_body, setMessageBody] = useState("");

    const editor = useCreateEditor(message_body);

    console.log(receiver_id, "reciener_id is ");

    const sendMessage = () => {
        const submitData = {
            receiver: receiver_id.toString(),
            header: message_title,
            message: editor.getHTML(),
            broadcast_type: type2,
        };

        const error = isValidMessage(submitData);
        if (error) return toast.error(error);

        axios
            .post(`hri_admin/broadcast/create`, submitData, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(res => {
                const sentMsgUsers = user.filter(user =>
                    receiver_id.includes(user.id)
                );
                navigate("/broadcast/message-success", { state: sentMsgUsers });
            })
            .catch(err => {
                console.log("Error: ", err);
                toast.error("Some error occurred");
            });
    };

    const [list, setList] = useState([]);

    const getUserEmail = () => {
        axios
            .get(`hri_admin/username`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(res => {
                console.log("Users data: ", res.data);
                setUser(res.data);
            })
            .catch(err => {
                console.log("Error occurred while fetching User data", err);
            });
    };

    const getTeamEmail = () => {
        axios
            .get(`hri_admin/teamemail`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(res => {
                console.log("Team members data: ", res.data);
                setTeam(res.data);
            })
            .catch(err => {
                console.log(
                    "Error occurred while fetching Team members data",
                    err
                );
            });
    };

    const getCompanyEmail = () => {
        axios
            .get(`hri_admin/companyemail`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(res => {
                console.log("Company data: ", res.data);
                setCompany(res.data);
            })
            .catch(err => {
                console.log("Error occurred while fetching Company data", err);
            });
    };

    useEffect(() => {
        if (cookies.get("token")) {
            getUserEmail();
            getTeamEmail();
            getCompanyEmail();
        } else {
            navigate("/");
        }
    }, []);

    console.log("user", user);
    console.log("team", team);
    console.log("company email", company);

    const newData = user.concat(company, team);

    const date = new Date();
    const newDate = moment(date).format("YYYY-MM-DD");

    return (
        <div className="bg-default-background h-screen overflow-auto">
            <Header />
            <Sidebar />

            <div className="bg-white text-lg px-3 py-4 m-2 rounded-md z-10 md:px-5 md:m-4">
                <div className="flex items-center justify-between mb-4 rounded-md ">
                    <Link to={"/broadcast"}>
                        <div className="text-default-blue flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer">
                            <ArrowBackIosNewIcon style={{ height: 20 }} />
                            <span>Back</span>
                        </div>
                    </Link>
                    <div
                        className="text-red-500 flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer"
                        onClick={() => navigate("/broadcast")}
                    >
                        <RemoveCircleOutlineIcon style={{ height: 20 }} />
                        <span>Discard</span>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-left md:items-center">
                    <div className="space-x-4 flex flex-grow flex-col md:flex-row">
                        <div
                            id="Message From"
                            className={`font-lighter px-4 py-2 rounded-md duration-300 cursor-pointer`}
                        >
                            Broadcast Message To:
                        </div>
                        <div
                            id="Users"
                            className={`${active1 === "Users" &&
                                "bg-default-gray text-default-blue"
                                } tab_span w-20 text-center`}
                            onClick={e => {
                                setActive1(e.currentTarget.id);
                                setSelect(e.currentTarget.id);
                                // setType("username")
                            }}
                        >
                            Users
                        </div>
                        <div
                            id="Companies"
                            className={`${active1 === "Companies" &&
                                "bg-default-gray text-default-blue"
                                } tab_span w-28 text-center`}
                            onClick={e => {
                                setActive1(e.currentTarget.id);
                                setSelect(e.currentTarget.id);
                                // setType("companyemail")
                            }}
                        >
                            Companies
                        </div>
                        <div
                            id="Teams"
                            className={`${active1 === "Teams" &&
                                "bg-default-gray text-default-blue"
                                } tab_span w-20 text-center`}
                            onClick={e => {
                                setActive1(e.currentTarget.id);
                                setSelect(e.currentTarget.id);
                                // setType("teamemail")
                            }}
                        >
                            Teams
                        </div>
                    </div>

                    <div className="flex ml-6 items-center">
                        <p className="font-semibold text-gray-500">
                            Date:{" "}
                            <span className="text-default-blue">{newDate}</span>
                        </p>
                    </div>
                </div>

                <div className="mx-4 my-4 flex gap-4 flex-col md:flex-row ">
                    <p className="mt-2">Select Message Type:</p>
                    <button
                        id="email"
                        className={`${active2 === "email" &&
                            "bg-default-gray text-default-blue"
                            } tab_span w-20`}
                        onClick={e => {
                            setActive2(e.currentTarget.id);
                            setType2(e.currentTarget.id);
                        }}
                    >
                        Email
                    </button>
                    <button
                        id="notification"
                        className={`${active2 === "notification" &&
                            "bg-default-gray text-default-blue"
                            } tab_span w-28`}
                        onClick={e => {
                            setActive2(e.currentTarget.id);
                            setType2(e.currentTarget.id);
                        }}
                    >
                        HRI Portal
                    </button>
                </div>

                <div className="space-y-5 my-4">
                    {select === "Users" ? (
                        <div className="mx-4 flex flex-col">
                            {/*<span className="text-default-blue font-semibold text-lg">*/}
                            {/*    Select User*/}
                            {/*</span>*/}
                            <Autocomplete
                                multiple
                                fullWidth
                                spacing={3}
                                options={[{ email: "Everyone" }, ...user]}
                                filterSelectedOptions
                                getOptionLabel={option => option.email}
                                onChange={(_, value) => {
                                    const ids = [];
                                    let flagAll = false;

                                    value.forEach(v => {
                                        if (v.email === "Everyone")
                                            flagAll = true;
                                        else ids.push(v.user_id);
                                    });

                                    if (flagAll === false) setReceiver_id(ids);
                                    else
                                        setReceiver_id(
                                            user.map(item => item.user_id)
                                        );
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Select User"
                                    />
                                )}
                            />
                        </div>
                    ) : select === "Companies" ? (
                        <div className="mx-4 flex flex-col">
                            {/*<span className="text-default-blue font-semibold text-lg">*/}
                            {/*    Select Company*/}
                            {/*</span>*/}
                            <Autocomplete
                                multiple
                                fullWidth
                                spacing={3}
                                options={company}
                                filterSelectedOptions
                                getOptionLabel={option => option.email}
                                onChange={(event, value) =>
                                    console.log(
                                        value.forEach((item, i) => {
                                            setReceiver_id(
                                                receiver_id.concat(item.user_id)
                                            );
                                        })
                                    )
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Select Company"
                                    />
                                )}
                            />
                        </div>
                    ) : select === "Teams" ? (
                        <div className="mx-4 flex flex-col">
                            {/*<span className="text-default-blue font-semibold text-lg">*/}
                            {/*    Select Team*/}
                            {/*</span>*/}
                            <Autocomplete
                                multiple
                                fullWidth
                                spacing={3}
                                options={team}
                                filterSelectedOptions
                                getOptionLabel={option => option.email}
                                onChange={(event, value) =>
                                    console.log(
                                        value.forEach((item, i) => {
                                            setReceiver_id(
                                                receiver_id.concat(item.user_id)
                                            );
                                        })
                                    )
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Select Team"
                                    />
                                )}
                            />
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="px-4 space-y-2 flex flex-col">
                        <span className="text-default-blue font-semibold text-lg">
                            Message
                        </span>
                        <input
                            type="text"
                            placeholder="Subject of the message"
                            className="p-2 border-[2px] rounded-md border-gray-300 outline-none max-w-none"
                            value={message_title}
                            onChange={e => setMessageTitle(e.target.value)}
                        />
                    </div>

                    <div className="px-4 space-y-2 flex flex-col">

                        <Editor editor={editor} />
                    </div>

                    <div>
                        <button
                            className="bg-[#0865B6] text-white font-semibold rounded-md hover:opacity-60 duration-300 active:opacity-80 my-4 max-w-[120px] mx-4 px-4 py-2 flex items-center space-x-2"
                            onClick={sendMessage}
                        >
                            Send
                            <SendIcon
                                className="ml-1"
                                sx={{ fontSize: "medium" }}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BroadcastMessage;
