import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";

import { Box, Button, IconButton, InputBase, Paper, Tab, Tabs } from "@mui/material";
import Cookies from "universal-cookie";
import ActiveJobs from "./ActiveJobs";
import InactiveJobs from "./InactiveJobs";
import NewRequestJobs from "./NewRequestJobs";

const cookies = new Cookies();

const Applications = () => {
    const [variable, setVariable] = useState(0);
    const navigate = useNavigate();
    const [input, setInput] = useState("");
    const location = useLocation();

    useEffect(() => {
        if (!cookies.get("token")) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        if (location.pathname.includes("active-jobs")) setVariable(0);
        if (location.pathname.includes("new-jobs")) setVariable(1);
        if (location.pathname.includes("inactive-jobs")) setVariable(2);
    }, [location.pathname]);

    const handleTabChange = (event, newValue) => {
        setVariable(newValue);
        if (newValue === 0) navigate("active-jobs");
        if (newValue === 1) navigate("new-jobs");
        if (newValue === 2) navigate("inactive-jobs");
    };

    return (
        <div style={{ overflow: "auto", backgroundColor: "#f5f5f5" }}>
            <Header />
            <Box marginTop="0rem">
                <TabsUnstyled defaultValue={0}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} bgcolor="white" borderRadius={1}>
                        <Tabs value={variable} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                            <Tab label="Active" />
                            <Tab label="New Requests" />
                            <Tab label="Inactive" />
                        </Tabs>
                        <Paper
                            component="form"
                            sx={{ display: "flex", alignItems: "center", width: { xs: "100%", sm: 500 }, marginLeft: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 } }}
                        >
                            <InputBase
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Search by name or email"
                                sx={{ ml: 1, flex: 1 }}
                            />
                            <IconButton onClick={() => setInput("")} sx={{ display: input ? "block" : "none" }}>
                                <CloseIcon />
                            </IconButton>
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                        <Link to="/add-job">
                            <Button variant="contained" color="primary">
                                Add New Position
                            </Button>
                        </Link>
                    </Box>

                    <TabPanelUnstyled value={0}></TabPanelUnstyled>
                    <TabPanelUnstyled value={1}></TabPanelUnstyled>
                    <TabPanelUnstyled value={2}></TabPanelUnstyled>

                    <Routes>
                        <Route path="active-jobs" element={<ActiveJobs input={input} />} />
                        <Route path="new-jobs" element={<NewRequestJobs input={input} />} />
                        <Route path="inactive-jobs" element={<InactiveJobs input={input} />} />
                    </Routes>
                </TabsUnstyled>
            </Box>
        </div>
    );
};

export default Applications;
