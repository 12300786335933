import {
    Box,
    Button,
    Container,
    Pagination,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { instituteStudentList } from "../../controller/instituteController";
import { KeyboardBackspace } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import { handleControllerResultException } from "../../utils/handleException";

/**
 * @type {import("../../models/StudentModel").StudentModel[]}
 */
const studentListType = [];

export default function InstituteStudents() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [studentList, setStudentList] = useState(studentListType);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPages, setCurrentPages] = useState(1);
    const [loading, setLoading] = useState(false)
    const handleStudentList = useCallback(
        async (id, currentPage) => {
            setLoading(true)
            const res = await instituteStudentList(id, currentPage);
            setLoading(false)
            if (res[0] !== null) {
                setStudentList(res[0].results);
                setCurrentPages(Math.ceil(res[0].count / 10));
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    useEffect(() => {
        if (id) {
            handleStudentList(id, currentPages);
        }
    }, [id, handleStudentList, currentPages]);

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ marginX: "auto", paddingY: "5rem" }}>
                <Button variant="contained" onClick={() => navigate(-1)}>
                    <KeyboardBackspace
                        fontSize="small"
                        sx={{ marginRight: "10px" }}
                    />
                    Return
                </Button>
                {!loading ? <TableContainer
                    component={Paper}
                    elevation={2}
                    sx={{
                        marginTop: "5rem",
                        minWidth: 650,
                        minHeight: "500px",
                    }}
                >
                    <Table>
                        <TableHead sx={{ backgroundColor: blue[700] }}>
                            <TableRow>
                                <TableCell sx={{ color: "white" }}>
                                    Name
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                    Contact
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                    Branch
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                    Address
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {studentList.length > 0 &&
                                studentList.map(data => (
                                    <TableRow key={data.id}>
                                        <TableCell>
                                            {data.firstName} {data.lastName}
                                        </TableCell>
                                        <TableCell>
                                            {" "}
                                            Email - {data.email} <br /> Phone -{" "}
                                            {data.phoneNumber}{" "}
                                        </TableCell>
                                        <TableCell>
                                            {data.designation}
                                        </TableCell>
                                        <TableCell>
                                            {data.city}, {data.state},{" "}
                                            {data.country}, {data.pin}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer> : <Skeleton variant="rectangular" height={"500px"} width={"650px"} sx={{ marginTop: "5rem" }} />}
                {studentList.length > 0 && (
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        marginTop={"2rem"}
                    >
                        <Pagination
                            color="primary"
                            count={totalPages}
                            onChange={(_e, value) => setCurrentPages(value)}
                        />
                    </Box>
                )}
            </Container>
        </Box>
    );
}
