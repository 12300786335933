import React, { useCallback, useState } from "react";
import BaseModal from "../BaseModal";
import { getAdminEmails, sendMessage } from "../../controller/MessageController";
import { handleControllerResultException } from "../../utils/dataFetchHelper";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { HTTPStatus } from "../../utils/helperObj";
import { toastSuccess } from "../../utils/Notification";

/**
 * @type {import('../../controller/MessageController').AdminEmailModel[]}
 */
const initialAdminEmailList = [];

export default function SendMessageModal({ onClose, open }) {
    const [adminEmailList, setAdminEmailList] = useState(initialAdminEmailList);
    const [selectedAdminEmail, setSelectedAdminEmail] = useState("");
    const [messageSubject, setMessageSubject] = useState("");
    const [messageDesc, setMessageDesc] = useState("");
    const [sendingMesssage, setSendingMessage] = useState(false)
    const navigate = useNavigate();
    // const handleFetchAdminEmail = useCallback(async () => {
    //     const res = await getAdminEmails();
    //     if (res[0] !== null) {
    //         setAdminEmailList(res[0], navigate);
    //     } else {
    //         handleControllerResultException(res[1]);
    //     }
    // }, []);

    // useEffect(() => {
    //     handleFetchAdminEmail();
    // }, [handleFetchAdminEmail]);

    async function onSubmit(e) {
        e.preventDefault();
        setSendingMessage(true)
        const res = await sendMessage(messageSubject, messageDesc, selectedAdminEmail);
        setSendingMessage(false)
        if (res.statusCode === HTTPStatus.SUCCESS) {
            toastSuccess(res.successMsg)
            onClose()
        } else {
            handleControllerResultException(res, navigate)
        }
    }

    return (
        <BaseModal onClose={onClose} open={open} sx={{
            padding: "2rem", width: {
                md: "600px",
                xs: "70%"
            }
        }}>
            <form onSubmit={onSubmit}>
                <FormControl fullWidth>
                    <InputLabel id="adminEmail">Admin Email</InputLabel>
                    <Select
                        labelId="adminEmail"
                        value={selectedAdminEmail}
                        label="Admin Email"
                        onChange={(e) => setSelectedAdminEmail(e.target.value)}
                        required
                    >
                        {adminEmailList.map((data) => (
                            <MenuItem value={data.id} key={data.id}>
                                {data.email}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField required label="Subject" sx={{ marginTop: "1rem" }} value={messageSubject} onChange={(e) => setMessageSubject(e.target.value)} fullWidth />
                <TextField required label="Description" multiline sx={{ marginTop: "1rem" }} rows={5} value={messageDesc} onChange={(e) => setMessageDesc(e.target.value)} fullWidth />
                <Button type="submit" variant="contained" disabled={sendingMesssage} sx={{ marginTop: "2rem" }} fullWidth>{sendingMesssage ? <CircularProgress size={"2em"} /> : "Sending message"}</Button>
            </form>
        </BaseModal>
    );
}
