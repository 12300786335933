import React, {useEffect} from "react";
import SuccessImage from "./SuccessBroadcast.png"
import {Card, CardContent} from "@mui/material";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {useNavigate,useLocation} from "react-router-dom";


import Cookies from "universal-cookie";
const cookies = new Cookies();

const SuccessBroadcast = () => {
    // const location = useLocation();
    // const state = location.state;

    const navigate = useNavigate();


    useEffect(() => {
        if (cookies.get("token")) {

        } else {
            navigate("/");
        }
    },[]);

    return (
        <div className="bg-default-background h-screen overflow-auto">
            <Header/>
            <Sidebar/>
            <div>
                <Card className="m-4 h-[85vh]" elevation={0}>
                    <CardContent className="flex justify-center my-20">
                        <div className="text-[#5B5B5B] text-xl space-y-3">
                            <img src={SuccessImage} alt=""/>
                            <p>
                                Message Broadcasted Successfully
                            </p>
                            <div className="flex flex-row space-x-1">
                                <p>
                                    Type another
                                </p>
                                <p className="text-[#0865B6] cursor-pointer" onClick={() => navigate("/broadcast")}>
                                    broadcast
                                </p>
                                <p>
                                    message or
                                </p>
                                <p className="text-[#0865B6] cursor-pointer" onClick={() => navigate("/broadcast/message")}>
                                    go back
                                </p>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default SuccessBroadcast;