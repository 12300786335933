import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";


import Cookies from "universal-cookie";
import {useNavigate} from "react-router-dom";
const cookies = new Cookies();

const ContactInfo = () => {

    const navigate = useNavigate();

    const [contact, setContact] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [facebookUrl, setFacebookUrl] = useState("");
    const [instaUrl, setInstaUrl] = useState("");
    const [twitterUrl, setTwitterUrl] =  useState("");
    const [linkedinUrl, setLinkedInUrl] = useState("");
    const [queryEmail, setQueryEmail] = useState("");


    const getBusinessDetails = () => {
        axios
            .get(
                `hri_admin/business-detail-list`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {
                // console.log("Business Details: ", res.data);
                setContact(res.data[0].ofc_phone);
                setEmail(res.data[0].email_smtp);
                setAddress(res.data[0].ofc_address);
                setFacebookUrl(res.data[0].ofc_fb);
                setInstaUrl(res.data[0].ofc_instagram);
                setTwitterUrl(res.data[0].ofc_twitter);
                setLinkedInUrl(res.data[0].ofc_linkedin);
                setQueryEmail(res.data[0].query_email);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const putContactInfo = () => {
        axios
            .put(
                `hri_admin/business-detail-contact-info-create-update`,
                {
                    ofc_phone: contact,
                    ofc_email: email,
                    ofc_address: address,
                    ofc_fb: facebookUrl,
                    ofc_instagram: instaUrl,
                    ofc_linkedin: linkedinUrl,
                    ofc_twitter: twitterUrl,
                    query_email: queryEmail,
                    // ofc_address: address,
                },
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {
                console.log("Contact Info updated", res.data);
                window.location.reload();
            })
            .catch((err) => {
                console.log("Error updating the Contact Info data", err);
            })
    }

    useEffect(() => {
        if (cookies.get("token")) {
            getBusinessDetails();
        } else {
            navigate("/");
        }
    },[]);
    
    return (
        <div className="py-4 px-4 md:px-10 space-y-5">
            <div>
                <p className="text-[#5F5F5F] text-xl font-bold">
                    BUSINESS CONTACT INFORMATION
                </p>
            </div>

            <div className="flex flex-col md:flex-row gap-4">
                <TextField
                    fullWidth
                    label="Contact"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                />
                <TextField
                    fullWidth
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>

            <div className="flex flex-col md:flex-row gap-4">
                <TextField
                    fullWidth
                    label="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                {/*<TextField*/}
                {/*    fullWidth*/}
                {/*    label="Area"*/}
                {/*/>*/}
            </div>

            {/*<div className="flex gap-4">*/}
            {/*    <div className="flex w-1/2 gap-4">*/}
            {/*        <TextField*/}
            {/*        fullWidth*/}
            {/*            label="Map Co-ordinates lat"*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*        fullWidth*/}
            {/*            label="Map Co-ordinates long"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className="w-1/2">*/}
            {/*        <TextField*/}
            {/*        fullWidth*/}
            {/*        label="Map URL"*/}
            {/*    />*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div>*/}
            {/*    <p className="text-[#5F5F5F] text-xl font-bold">*/}
            {/*        Social Media Information of your Business*/}
            {/*    </p>*/}
            {/*</div>*/}

            <div className="flex flex-col md:flex-row gap-4">
                <TextField
                    fullWidth
                    label="Facebook Profile URL"
                    value={facebookUrl}
                    onChange={(e) => setFacebookUrl(e.target.value)}
                />
                <TextField
                    fullWidth
                    label="Instagram Profile URL"
                    value={instaUrl}
                    onChange={(e) => setInstaUrl(e.target.value)}
                />
            </div>

            <div className="flex flex-col md:flex-row gap-4">
                <TextField
                    fullWidth
                    label="Twitter Profile URL"
                    value={twitterUrl}
                    onChange={(e) => setTwitterUrl(e.target.value)}
                />
                <TextField
                    fullWidth
                    label="Linkedin Profile URL"
                    value={linkedinUrl}
                    onChange={(e) => setLinkedInUrl(e.target.value)}
                />
            </div>

            <div>
                <p className="text-[#5F5F5F] text-xl font-bold">
                    Other Email IDs
                </p>
            </div>

            <div className="flex flex-col md:flex-row gap-4">
                <TextField
                    fullWidth
                    label="Email Ids for queries/Feedback"
                    value={queryEmail}
                    onChange={(e) => setQueryEmail(e.target.value)}
                />
                <TextField
                    fullWidth
                    label="Email Id for interested job applicants"
                />
            </div>

            <div className="flex flex-col md:flex-row md:space-x-5 mt-10">
                <Button
                    style={{color: "#0865B6", backgroundColor: "#F1F1F1", borderRadius: "5px", fontWeight: "bold"}}
                    className="my-2 px-4"
                    onClick={putContactInfo}
                >
                    Save
                </Button>
                <Button
                    style={{color: "#0865B6", backgroundColor: "#F1F1F1", borderRadius: "5px", fontWeight: "bold"}}
                    className="my-2 px-4"
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default ContactInfo;