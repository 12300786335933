import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditCategory from "./EditCategory";
import axios from "axios";
import { IconButton } from "@mui/material";

import Cookies from "universal-cookie";
import { toast } from "react-toastify";
const cookies = new Cookies();

const Category = () => {
  const navigate = useNavigate();

  const [category, setCategory] = useState([]);
  const [questions, setQuestions] = useState([]);

  const getCategory = () => {
    axios
      .get(
        `hri_admin/help-category-list?help_section=${localStorage.getItem(
          "help-type"
        )}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        console.log("Category data: ", res.data);
        setCategory(res.data);
      })
      .catch((err) => {
        console.log("Error Loading data", err.response.data);
      });
  };

  const getQuestions = () => {
    axios
      .get(
        `hri_admin/help-qa-list?help_section=${localStorage.getItem(
          "help-type"
        )}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        console.log("Questions: ", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log("Error: ", err.response);
      });
  };

  const deleteQuestion = (id) => {
    axios
      .delete(
        `hri_admin/help-qa-delete/${id}?help_section=${localStorage.getItem(
          "help-type"
        )}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        window.location.reload();
        console.log("Question Deleted", res.data);
        toast.success("Question Deleted", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        console.log("Error: ", err.response);
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  useEffect(() => {
    getCategory();
    getQuestions();
  }, []);

  return (
    <div>
      {/* Timeline */}
      <div className="flex justify-start bg-white px-5 py-3 m-4 rounded-md z-10">
        <div className="w-screen">
          <Timeline>
            <p className="text-2xl pb-4 font-semibold text-[#0865B6]">
              Category
            </p>
            {category.map((info) => {
              return (
                <TimelineItem key={info.id} className="before:hidden">
                  <TimelineSeparator>
                    <TimelineDot sx={{ background: "#0865B6" }} />
                    <TimelineConnector sx={{ background: "#0865B6" }} />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className="pb-4">
                      <div>
                        <div className="space-y-3">
                          <p className="text-xl font-semibold">{info.name}</p>
                          <p>{info.description}</p>
                        </div>

                        {questions.map((data) => {
                          if (info.name === data.help_category_name) {
                            return (
                              <div className="flex flex-row py-3" key={data.id}>
                                <div className="flex-grow">
                                  <p className="font-bold">
                                    Q.{data.help_question}
                                  </p>
                                  <p>A. {data.help_answer}</p>
                                </div>

                                <div>
                                  <IconButton
                                    onClick={() => {
                                      navigate(`edit`);
                                      localStorage.setItem(
                                        "Cat-QuestionId",
                                        data.id
                                      );
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => deleteQuestion(data.id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </div>
      </div>
    </div>
  );
};

export default Category;
