import Cookies from "universal-cookie";
import { handleException } from "../utils/handleException";
import axios from "axios";
import { BackendAPI } from "../constants/backendAPI";
import { createTeamObj } from "../models/TeamModel";

const cookies = new Cookies()

/**
 * 
 * @param {string} searchText 
 * @returns {Promise<[import("../models/TeamModel").TeamModel[] | null, import("../models/HttpResponse").HttpResponse | null]>}
 */
export async function getTeamList(searchText = "") {
    const result = [null, null]
    try {
        const res = await axios.get(BackendAPI.team.teamMailList, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
            params: {
                keyword: searchText
            }
        })
        const teamArr = []
        for (let x of res.data) {
            teamArr.push(createTeamObj(x))
        }
        result[0] = teamArr
        return result
    } catch (err) {
        result[1] = handleException(err)
        return result
    }
}