import { Add, Delete } from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

/**
 * @typedef {object} Props
 * @property {number} index
 * @property {import("../../models/JobModel").JobQuestionnaire[]} JobQuestionnaire
 * @property {React.Dispatch<React.SetStateAction<import("../../models/JobModel").JobQuestionnaire[]>>} setJobQuestionnaire
 * @property {boolean} open
 * @property {()=>void} onClose
 */

/**
 * @param {Props} props
 */
export default function JobQuestionFormModal({
    index = 0,
    JobQuestionnaire,
    setJobQuestionnaire,
    open,

    onClose,
}) {
    const [question, setQuestion] = useState("");
    const [description, setDescription] = useState("");
    const [questionType, setQuestionType] = useState("");
    const [showError, setShowError] = useState(false);
    const [choices, setChoices] = useState([]);
    const [required, setRequired] = useState(false);

    function validationField() {
        return question !== "" && questionType !== "";
    }

    useEffect(() => {
        if (index >= 0) {
            setQuestion(JobQuestionnaire[index].question);
            setDescription(JobQuestionnaire[index].description);
            setQuestionType(JobQuestionnaire[index].answerMode);
            setChoices(JobQuestionnaire[index].choiceFieldQuestions);
            setRequired(JobQuestionnaire[index].required);
        }
    }, [index]);

    function handleSave() {
        if (validationField()) {
            /**
             * @type {import("../../models/JobModel").JobQuestionnaire}
             */
            const result = {
                question,
                description,
                answerMode: questionType,
                choiceFieldQuestions: choices,
                required: required
            };
            setJobQuestionnaire(prevState => [...prevState, result]);
            setQuestion("")
            setDescription("")
            setQuestionType("");
            setRequired(false)
            onClose();
        } else {
            setShowError(true);
        }
    }

    function handleUpdate() {
        setJobQuestionnaire(prevState => {
            const newArr = [...prevState];
            newArr[index].answerMode = questionType;
            newArr[index].description = description;
            newArr[index].question = question;
            newArr[index].choiceFieldQuestions = choices;
            newArr[index].required = required;
            return newArr;
        });
        setQuestion("")
        setDescription("")
        setQuestionType("");
        setRequired(false)
        onClose();
    }

    function handleCoiceFiledData(index, value) {
        const newArr = [...choices];
        newArr[index] = value;
        setChoices(newArr);
    }

    function removeChoice(index) {
        const newArr = [...choices];
        newArr.splice(index, 1);
        setChoices(newArr);
    }
    return (
        <Modal
            open={open}
            onClose={() => {
                setQuestion("");
                setDescription("");
                setQuestionType("");
                setChoices([]);
                setShowError(false);
                onClose();
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    width: "60%",
                    maxHeight: "700px",
                    overflowY: "auto",
                }}
            >
                <Box display={"flex"} justifyContent={"flex-end"}>
                    <FormControlLabel control={<Checkbox checked={required} onChange={(e) => setRequired(e.target.checked)} />} label={"Required"} />
                </Box>
                <TextField
                    label="Question"
                    value={question}
                    onChange={e => setQuestion(e.target.value)}
                    fullWidth
                    error={showError && question === ""}
                    sx={{ marginTop: "1rem" }}
                />
                <TextField
                    sx={{ marginTop: "12px" }}
                    label="Description (optional)"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    multiline
                    rows={5}
                    fullWidth
                />
                <FormControl fullWidth sx={{ marginTop: "12px" }}>
                    <InputLabel id="questionType">Answer Type</InputLabel>
                    <Select
                        labelId="questionType"
                        value={questionType}
                        onChange={e => setQuestionType(e.target.value)}
                        label="Answer Type"
                        error={showError && questionType === ""}
                    >
                        <MenuItem value={"TextField"}>TextField</MenuItem>
                        <MenuItem value={"ChoiceField"}>ChoiceField</MenuItem>
                        <MenuItem value={"FileField"}>FileField</MenuItem>
                        <MenuItem value={"BoolField"}>Boolean</MenuItem>
                        <MenuItem value={"NumberField"}>NumberField</MenuItem>
                        <MenuItem value={"RadioField"}>RadioField</MenuItem>
                        <MenuItem value={"VideoField"}>VideoField</MenuItem>
                        <MenuItem value={"ImageField"}>ImageField</MenuItem>
                    </Select>
                </FormControl>

                {questionType === "ChoiceField" && (
                    <Box marginTop={"20px"}>
                        <Button
                            variant="contained"
                            onClick={() =>
                                setChoices(prevState => [...prevState, ""])
                            }
                        >
                            <Add />
                            Add Choice
                        </Button>
                        <Box marginTop={"20px"}>
                            {choices.length > 0 &&
                                choices.map((data, index) => (
                                    <Box
                                        key={index}
                                        sx={{ marginTop: "15px" }}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                    >
                                        <TextField
                                            label="Question"
                                            value={data}
                                            onChange={e =>
                                                handleCoiceFiledData(
                                                    index,
                                                    e.target.value
                                                )
                                            }
                                            fullWidth
                                        />
                                        <button
                                            style={{ marginLeft: "15px" }}
                                            onClick={() => removeChoice(index)}
                                        >
                                            <Delete color="error" />
                                        </button>
                                    </Box>
                                ))}
                        </Box>
                    </Box>
                )}

                <Box
                    marginTop={"20px"}
                    display={"flex"}
                    justifyContent={"flex-end"}
                >
                    <Button
                        variant="contained"
                        onClick={index >= 0 ? handleUpdate : handleSave}
                    >
                        <Add />
                        {index >= 0 ? "Edit Question" : "Add question"}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}
