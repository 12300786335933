import axios from "axios";
import { HttpResponse } from "../models/HttpResponse";
import { handleException } from "../utils/handleException";
import Cookies from "universal-cookie";

const cookie = new Cookies();


export async function getInterview(interviewType = "All", currentPage = 1) {
    const result = [{ count: 0, result: [] }, null];
    try {
        const res = await axios.get(`hri_admin/interview-list`, {
            params: {
                interview_type: interviewType,
                page: currentPage,
            }, headers: {
                Authorization: `Token ${cookie.get("token")}`,
            }
        })
        result[0].count = res.data.count;
        result[0].result = res.data.results;
        return result
    } catch (err) {
        result[1] = handleException(err);
        return result
    }
}


export async function changeInterviewStatus(applicationId, progress_status) {
    try {
        const res = await axios.post(
            `hri_admin/interview-status-update/${applicationId}`,
            {
                progress_status,
            },
            {
                headers: {
                    Authorization: `Token ${cookie.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Status changed");
    } catch (err) {
        return handleException(err);
    }
}