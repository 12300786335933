import React, {useEffect, useState} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {Button, Divider} from "@mui/material";
import GeneralInfo from "./GeneralInfo";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import BizZone from "./BizZone";
import ContactInfo from "./ContactInfo";
import axios from "axios";


import Cookies from "universal-cookie";
const cookies = new Cookies();

const BusinessDetails = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (cookies.get("token")) {

        } else {
            navigate("/");
        }
    },[]);

    const [businessDetails, setBusinessDetails] = useState([]);

    const getBusinessDetails = () => {
        axios
            .get(
                `hri_admin/business-detail-list`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {
                console.log("Business Details: ", res.data);
                setBusinessDetails(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getBusinessDetails();
    },[]);

    return (
        <div>
            <div className="flex py-2 px-3 gap-2 md:px-10 md:gap-4">
                <Button
                    style={{color: "#0865B6", backgroundColor: "#F1F1F1", borderRadius: "5px", fontWeight: "bold"}}
                    className="my-2 px-4"
                    onClick={() => navigate("/settings")}
                >
                    General Info
                </Button>
                <Button
                    style={{color: "#0865B6", backgroundColor: "#F1F1F1", borderRadius: "5px", fontWeight: "bold"}}
                    className="my-2 px-4"
                    onClick={() => navigate("biz-zone")}
                >
                    Biz Zone
                </Button>
                <Button
                    style={{color: "#0865B6", backgroundColor: "#F1F1F1", borderRadius: "5px", fontWeight: "bold"}}
                    className="my-2 px-4"
                    onClick={() => navigate("contact-info")}
                >
                    Contact Info
                </Button>
                {/*<Button*/}
                {/*    style={{color: "#0865B6", backgroundColor: "#F1F1F1", borderRadius: "5px", fontWeight: "bold"}}*/}
                {/*    className="my-2 px-4"*/}
                {/*    onClick={() => navigate("achievement")}*/}
                {/*>*/}
                {/*    Achievements*/}
                {/*</Button>*/}
            </div>

            <Divider/>

            <Routes>
                <Route path={"/"} element={<GeneralInfo/>}/>
                <Route path={"biz-zone"} element={<BizZone/>}/>
                <Route path={"contact-info"} element={<ContactInfo/>}/>
            </Routes>
        </div>
    )
}

export default BusinessDetails;