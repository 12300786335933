import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
} from "@mui/material";
import React, { useState } from "react";

/**
 * @typedef {object} Props
 * @property {number[]} skillIds
 * @property {React.Dispatch<React.SetStateAction<number[]>>} setSkillIds
 * @property {import('../../../models/UserApplicationModel').UserSkillModel[]} skillList
 */

/**
 * @param {Props} props
 */
export default function SkillsSelector({ skillIds, setSkillIds, skillList }) {
    const [selectAll, setSelectAll] = useState(false);
    function handleCheck(checked, id) {
        if (checked) {
            setSkillIds(prevState => [...prevState, id]);
        } else {
            const arr = [...skillIds];
            let idIndex = arr.findIndex(data => data === id);
            idIndex >= 0 && arr.splice(idIndex, 1);
            setSkillIds(arr);
        }
    }

    function handleCheckAll(checked) {
        if (checked) {
            const newIdArr = [];
            skillList.forEach(data => {
                newIdArr.push(data.id);
            });
            setSkillIds(newIdArr);
            setSelectAll(true);
        } else {
            setSkillIds([]);
            setSelectAll(false);
        }
    }

    return (
        <Box>
            <Typography fontSize={"18px"}>Skills</Typography>
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={"10px"}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectAll}
                            onChange={e => handleCheckAll(e.target.checked)}
                        />
                    }
                    label={"Select all"}
                />
            </Box>

            {skillList.length > 0 && (
                <Grid container>
                    {skillList.map(data => (
                        <Grid item xs={6} key={data.id}>
                            <Box display={"flex"} alignItems={"center"}>
                                <Checkbox
                                    checked={skillIds.includes(data.id)}
                                    onChange={e =>
                                        handleCheck(e.target.checked, data.id)
                                    }
                                />
                                <Box>
                                    <Typography
                                        fontSize={"16px"}
                                        fontWeight={"bold"}
                                    >
                                        {data.skill}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
}
