import React, { useEffect, useState } from "react";
import "./styles.css";
import axios from "axios";

const ActiveJobHeader = () => {

  const [companyData , setCompanyData] = useState([])

  async function getCompanyData() {
    await axios
      .get("hri_admin/business-detail-open")
      .then((resp) => {
        setCompanyData(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCompanyData();
  }, []);
  return (
    <>
      <div className=" z-10 bg-white flex justify-between border-b-4  w-full h-auto sticky  top-0 ">
        <div className=" md:space-x-1  mx-4 md:mx-20  flex  ">
          <div className="flex text-[#0865B6] ml-2 logo-container space-x-2 text-xl px-2">
            <a href="/dashboard">
              <img
                src={companyData.business_image}
                alt=""
                style={{ height: 40, marginTop: 8 }}
                className="border-2 border-slate-300 p-0.5 rounded-full"
              />
            </a>
            <p className="font-semibold mt-3 self-center hidden md:block text-sm md:text-base">
              {companyData.business_name}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveJobHeader;
