import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import {styled} from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {getRequest} from "../../../utils/request";
import moment from "moment";
import Button from "@mui/material/Button";
import {InputLabel, TextField, ToggleButton} from "@mui/material";
import axios from "axios";
import {toast} from "react-toastify";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


import Cookies from "universal-cookie";

const cookies = new Cookies();

const AddQA = () => {

    const navigate = useNavigate();

    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [category, setCategory] = useState("");

    const [categoryList, setCategoryList] = useState("");


    const getCategory = () => {
        axios
            .get(
                `hri_admin/help-category-list?help_section=${localStorage.getItem("help-type")}`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                console.log("Category List: ", res.data);
                setCategoryList(res.data)
            })
    }

    const addQuestion = () => {
        axios
            .post(
                `hri_admin/help-qa-create?help_section=${localStorage.getItem("help-type")}`,
                {
                    help_question: question,
                    help_answer: answer,
                    help_category: category,
                },
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                navigate(`/help/${localStorage.getItem("help-type")}/category`);
                console.log("Question Added Successfully", res.data)
                toast.success("Question Added Successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: {backgroundColor: "#1ab394", color: "white"},
                    icon: false,
                    progressStyle: {backgroundColor: "#BAE8DE"},
                });
            })
            .catch((err) => {
                console.log("Error: ", err.response);
                if (category === "") {
                    toast.error("Please select a Category or Add new Category", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: {backgroundColor: "#FF6347", color: "white"},
                        icon: false,
                        progressStyle: {backgroundColor: "#FFB1A3"},
                    });
                } else if (question === "") {
                    toast.error("Please fill the Question", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: {backgroundColor: "#FF6347", color: "white"},
                        icon: false,
                        progressStyle: {backgroundColor: "#FFB1A3"},
                    });
                } else if (answer === "") {
                    toast.error("Please fill the Answer", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: {backgroundColor: "#FF6347", color: "white"},
                        icon: false,
                        progressStyle: {backgroundColor: "#FFB1A3"},
                    });
                } else {
                    toast.error("Error", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: {backgroundColor: "#FF6347", color: "white"},
                        icon: false,
                        progressStyle: {backgroundColor: "#FFB1A3"},
                    });
                }

            })
    }


    useEffect(() => {
        getCategory();
    }, []);

    return (
        <div>

            <div className="bg-white pl-2 pr-2 py-4 m-4 rounded-md z-10 md:pl-5 md:pr-5">

                <p className="text-2xl text-[#0856B6] font-bold">
                    Add New Q & A
                </p>
                <div className="space-y-5 my-4">
                    <div className="mx-4 flex flex-col gap-4 md:flex-row">


                        <FormControl className="flex">
                            <InputLabel id="demo-simple-select-label">Choose Category</InputLabel>
                            <Select
                                label="Choose Category"
                                value={category}
                                className="w-96"
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                {categoryList.length ? (
                                    categoryList.map((item) => (
                                        <MenuItem value={item.id} key={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="">No user available</MenuItem>
                                )}
                            </Select>
                        </FormControl>


                        <div className="mt-2">
                            <Button
                                variant="contained"
                                className="bg-[#0865B6] h-10"
                                sx={{borderRadius: "5px"}}
                                onClick={() => navigate(`/help/${localStorage.getItem("help-type")}/category/add-category`)}
                            >
                                Add New Category
                            </Button>
                        </div>

                    </div>

                    <div className="px-4 flex space-y-2 ">
                        <TextField
                            className="w-96"
                            label="Question"
                            type="text"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                    </div>

                    <div className="px-4 space-y-2">
                        <TextField
                            multiline
                            fullWidth
                            minRows={4}
                            label="Answer"
                            type="text"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                        />

                    </div>

                    <div className="flex flex-col items-center space-x-3 mx-4 md:flex-row">
                        <Button variant="contained" onClick={addQuestion} endIcon={<AddCircleOutlineIcon/>}>
                            Add
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => navigate(`/help/${localStorage.getItem("help-type")}/category`)}
                        >
                            x {" "}Cancel
                        </Button>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default AddQA;