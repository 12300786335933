import { Box, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

/**
 * @typedef {object} Props
 * @property {import('../../models/UserApplicationModel').UserApplicationModel} application
 */

/**
 * @param {Props} props
 */
export default function ApplicantWorkExperience({ application }) {
    return (
        <Box marginY={"2rem"}>
            <Typography variant="h6" fontWeight={"500"}>
                Work Experience
            </Typography>
            <Grid container spacing={2} marginTop={"1rem"}>
                {application.workExperience.length > 0 &&
                    application.workExperience.map(experience => (
                        <Grid key={experience.id} item lg={3} sm={4} xs={6}>
                            <WorkExperienceCard
                                experience={experience}
                            />
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
}

/**
 * @typedef {object} WorkExperienceParam
 * @property {import("../../models/UserApplicationModel").WorkExperienceModel} experience
 */

/**
 * @param {WorkExperienceParam} props
 */

function WorkExperienceCard({ experience }) {
    return (
        <Box>
            <Typography fontSize={"15px"} fontWeight={"bold"}>
                {experience.position}
            </Typography>
            <Typography fontSize={"13px"} color={grey[500]}>
                <span style={{ color: grey[600], fontWeight: "bold" }}>
                    {experience.companyName}
                </span>{" "}
                - {experience.city},{experience.country}
            </Typography>
        </Box>
    );
}
