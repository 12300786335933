import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "tw-elements";
import axios from "axios";
import "antd/dist/antd.min.css";

// axios.defaults.baseURL = "https://stghribe.karmaalab.com";
axios.defaults.baseURL = "https://ps.prod.hribe.karmaalab.com";

export const User_Fe_url = "https://user.pshire.plausibilitysolutions.com/"
// export const User_Fe_url = "https://stghriuser.mybizmo.com/"

export const Admin_FE_url ="https://admin.pshire.plausibilitysolutions.com/"
// export const Admin_FE_url ="https://stghriadmin.mybizmo.com/"


ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode> 
  ,document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
