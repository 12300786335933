import React, { useEffect, useState } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'
import Button from "@mui/material/Button";
import BIll from './BIll';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import Payout from './Payout';
import PaymentDetails from './PaymentDetails';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';

import Cookies from "universal-cookie";
import PaymentDetailsCopy from './PaymentDetails copy';
const cookies = new Cookies();

const FinanceHeader = () => {

  const navigate = useNavigate();

  const [location, setLocation] = useState("")

  useEffect(()=>{
    setLocation(window.location.pathname.split("/")[2])
    })
 

  useEffect(() => {
    if (cookies.get("token")) {

    } else {
      navigate("/");
    }
  }, []);


  return (
    <>
      <Header />
      <Sidebar />


      <div className='bg-gray-100 w-full '>
        <div >
          <div className=" bg-white w-full  h-auto md:flex justify-between">
            <div className="bg-white mt-1 space-x-1 flex  justify-around items-center lg:space-x-4 text-sm md:text-lg xl:text-xl  font-semibold p-1 text-gray-600  md:p-2">

              <div onClick={() => { navigate("/Financials/your-bills"); }} className={ location === "your-bills" || location === "team-bills" || location === "requested-bills" ? ' rounded-lg md:px-8 py-1 bg-slate-100 text-blue-700 cursor-pointer px-4' : 'cursor-pointer px-4 py-1 rounded-lg md:px-8  hover:bg-slate-100 hover:text-blue-700'}>Billing</div>

              <div onClick={() => { navigate("/Financials/payout"); }} className={location === "payout" ? 'rounded-lg md:px-8 py-1 bg-slate-100 text-blue-700 cursor-pointer px-4' : 'cursor-pointer px-4 py-1 rounded-lg md:px-8  hover:bg-slate-100 hover:text-blue-700'}>Payout</div>

              <div onClick={() => { navigate("/Financials/team-payment-details"); }} className={location === "team-payment-details" || location === "payment-details" ? ' rounded-lg md:px-8 py-1 bg-slate-100 text-blue-700 cursor-pointer px-4' : 'cursor-pointer px-4 py-1 rounded-lg md:px-8  hover:bg-slate-100 hover:text-blue-700'}>Payment Details</div>
            </div>


            <div className="pb-1 flex items-center justify-center md:justify-end">
              <div className='mx-4'>
                {location === "your-bills" || location === "team-bills" || location === "requested-bills" ?
                  <Link to="/AddBill" className=''>
                    <Button className='w-auto ' size="small" variant="contained"><IoAddCircleOutline className='text-lg mr-2' />Create New Bill</Button>
                  </Link>
                  : null}
                {location === "payout" ?
                  <Link to="/Financials/bill/payout">
                    <Button className='w-auto' size="small" variant="contained"><IoAddCircleOutline className='text-lg mr-2' />Add New Payout</Button>
                  </Link>
                  : null}
                  {location === "payment-details" ?
                  <Link to="/AddPaymentDetails">
                    <Button className='w-auto' size="small" variant="contained"><IoAddCircleOutline className='text-lg mr-2' />Add Payment Details</Button>
                  </Link>
                  : null}
                  {location === "team-payment-details" ?
                  <Link to="/team-add-payment-details">
                    <Button className='w-auto' size="small" variant="contained"><IoAddCircleOutline className='text-lg mr-2' />Team Payment Details</Button>
                  </Link>
                  : null}
              </div>
            </div>
          </div>          
          <div>

            <Routes>
              <Route path='/payout' element={<Payout />} />
              <Route path='/team-payment-details' element={<PaymentDetailsCopy />} />
              <Route path='/payment-details' element={<PaymentDetails />} />
              <Route path='/*' element={<BIll />} />
            </Routes>
            {/* {colorChangeBill ?
              <BIll /> : null}
            {colorChangePayout ?
              <Payout /> : null}
            {colorChangePaymentDetails ?
              <PaymentDetails /> : null} */}
          </div>
        </div>
      </div>
    </>

  )
}

export default FinanceHeader