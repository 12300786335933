import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Button, Button as Button1 } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import Facebook from "../../Images/Facebook_logo.png";
import Google from "../../Images/Google_logo.png";
import LinkedIn from "../../Images/Linkedin_logo.png";
import ConnectionTab from "../../components/ConnectionTab";
import DetailsTab from "../../components/DetailsTab";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import SocialTabs from "../../components/SocialTabs";

const cookies = new Cookies();
function CompanyDetails() {
  const navigate = useNavigate();

  // const currentValue = useSelector(selectUser);
  // console.log(currentValue?.id, 'current')

  // const currentValue = localStorage.getItem("company_id")
  // console.log(currentValue, 'current')

  const [data, setData] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [status, setStatus] = useState(null);
  const {id} = useParams()
  useEffect(() => {
    if (cookies.get("token")) {
      getData();
    } else {
      navigate("/");
    }
  }, []);

  function getData() {
    axios
      .get(`hri_admin/company/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        setData(resp.data);
        setStatus(resp.data[0].status);
        console.log(resp);
        console.log(status);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function statusChange() {
    axios
      .get(`hri_admin/company-status-change/${cookies.get("CompanyId")}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        getData();
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.warning(err.message);
      });
  }

  // const dispatch = useDispatch();

  // const allocate = (id) => {
  //   navigate("/companies/companies-details/edit")
  //   dispatch(set({
  //     id:id
  //   }))
  //   localStorage.setItem("company_edit_id", id)
  // }

  return (
    <div className="bg-default-background h-screen overflow-y-scroll">
      <Header />
      <Sidebar />

      <div className="px-1 md:px-4">

        <div className="bg-white rounded-lg p-6 mt-4">
        <Button
          variant="text"
          startIcon={<ArrowBackIosNewIcon />}
          onClick={() => navigate(-1)}
          sx={{marginBottom:2}}
        >
          Back
        </Button>
          {data.map((item) => (
            <div key={item.id}>
              <div className="md:flex items-center space-x-3">
                <Avatar
                  className="hover: cursor-pointer"
                  sx={{ width: 60, height: 60 }}
                  src={item.company_logo}
                />
                <div className="flex-1">
                  <div className="flex items-center">
                    <h1 className="text-lg font-semibold text-black">
                      {item.name ? item.name : "Not Available"}
                    </h1>
                    <span
                      style={{
                        height: "12px",
                        width: "12px",
                        display: "inline-block",
                      }}
                      className={`rotate-45 border-2 bg-[#9AE293] ${
                        item.status === false && "bg-[#e73724]"
                      }  hover:cursor-pointer ml-2`}
                    ></span>
                    <span className="ml-2">
                      {item.status !== false
                        ? "Active"
                        : "Blocked"}
                    </span>
                  </div>
                  {/* <div className="flex items-center text-gray-500 text-sm">
                    {[...Array(5)].map((_, starIdx) => (
                      <StarOutlinedIcon
                        key={starIdx}
                        className="text-[#FFAB00]"
                      />
                    ))}
                    <span className="ml-2">4.0 (321 Ratings)</span>
                  </div> */}
                </div>
                <div className="flex md:gap-4 gap-2 my-2 justify-center">
                  <div>
                    {!status ? (
                      <Button1 variant="contained" onClick={statusChange}>
                        UnBlock
                      </Button1>
                    ) : (
                      <Button1
                        variant="contained"
                        sx={{ width: "6.5rem" }}
                        color="error"
                        onClick={statusChange}
                      >
                        Block
                      </Button1>
                    )}
                  </div>
                  <div
                    className="flex px-4 py-2 space-x-1 bg-default-gray items-center justify-center text-default-blue rounded-lg hover:cursor-pointer button_effect"
                    onClick={() => {
                      navigate("edit");
                      setCompanyId(item.id);
                    }}
                  >
                    <button className=" font-semibold">Edit</button>
                    <EditOutlinedIcon style={{ height: 18 }} />
                  </div>
                </div>
              </div>

              <div className="my-4 justify-center lg:mx-[65px] flex flex-wrap md:grid grid-cols-3 gap-4">
                <DetailsTab
                  title={"CEO"}
                  subtitle={
                    item.company_ceo ? item.company_ceo : "Not Available"
                  }
                />
                <DetailsTab
                  title={"Founder"}
                  subtitle={
                    item.company_founder
                      ? item.company_founder
                      : "Not Available"
                  }
                />
                <div className="space-y-2">
                  <div className="lg:flex items-center justify-evenly lg:space-x-2 space-y-2 lg:space-y-0">
                    {item.company_number && (
                      <a href={`tel:${item.company_number}`}>
                        <ConnectionTab
                          Icon={CallOutlinedIcon}
                          title={item.company_number}
                        />
                      </a>
                    )}
                    {item.company_email && (
                      <a href={`mailto:${item.company_email}`}>
                        <ConnectionTab
                          Icon={EmailOutlinedIcon}
                          title={item.company_email}
                        />
                      </a>
                    )}
                    {/* <ConnectionTab Icon={MessageOutlinedIcon} title="Message" /> */}
                  </div>
                  <div>
                    <ConnectionTab
                      Icon={CalendarTodayOutlinedIcon}
                      title="Schedule meeting"
                    />
                  </div>
                </div>
                <DetailsTab
                  title={"Human Resources (HR)"}
                  subtitle={
                    item.contact_person ? item.contact_person : "Not Available"
                  }
                />
                <DetailsTab
                  title={"HR Office contact"}
                  subtitle={
                    item.contact_number ? item.contact_number : "Not Available"
                  }
                />
                <DetailsTab />
                <DetailsTab
                  title={"Established In"}
                  subtitle={
                    item.established_in ? item.established_in : "Not Available"
                  }
                />
                <DetailsTab
                  title={"Employees"}
                  subtitle={
                    item.company_employee
                      ? item.company_employee
                      : "Not Available"
                  }
                />
                <DetailsTab />
                <DetailsTab
                  title={"Working Sector"}
                  subtitle={
                    item.working_sector ? item.working_sector : "Not Available"
                  }
                />
                <DetailsTab
                  title={"Location"}
                  subtitle={item.address ? item.address : "Not Available"}
                  location
                />
                <DetailsTab />
                <DetailsTab
                  title={"Company Description"}
                  subtitle={
                    item.company_description
                      ? item.company_description
                      : "Not Available"
                  }
                  span="col-span-2"
                />
                <DetailsTab />
                <SocialTabs
                  img={Google}
                  Link={item.company_url ? item.company_url : "Not Available"}
                />
                <SocialTabs
                  img={Facebook}
                  Link={item.facebook_url ? item.facebook_url : "Not Available"}
                />
                <DetailsTab />
                <SocialTabs
                  img={LinkedIn}
                  Link={item.linkedin_url ? item.linkedin_url : "Not Available"}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CompanyDetails;
