import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Close } from "@mui/icons-material";

/**
 * @typedef {object} Props
 * @property {string[]} options
 * @property {(searchText:string)=>Promise<void>} searchFunction
 * @property {string} textFieldLabel
 * @property {string[]} selectedOptions
 * @property {React.Dispatch<React.SetStateAction<string[]>>}setSelectOption
 * @property {boolean} error
 */

/**
 * @param {Props} props
 */
export default function MultiSelectSuggestionTextField({
    options,
    searchFunction,
    textFieldLabel = "Text Field",
    selectedOptions,
    setSelectOption,
    error = false
}) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    async function handleSearch(searchText) {
        setLoading(true);
        await searchFunction(searchText);
        setLoading(false);
    }

    useEffect(() => {
        let timeOutId = 0;

        timeOutId = setTimeout(() => {
            handleSearch(searchText);
        }, [700]);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [searchText]);



    function handleFieldChange(value) {
        if (value !== null && !selectedOptions.includes(value)) {
            setSelectOption(prevState => [...prevState, value]);
        }
    }

    return (
        <Box>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {selectedOptions.map((data, index) => (
                    <Box
                        key={data}
                        margin={"5px"}
                        sx={{
                            padding: "2px 20px",
                            backgroundColor: blue[50],
                            color: blue[700],
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {" "}
                        <Typography fontSize={"14px"} marginRight={"5px"}>
                            {data}
                        </Typography>{" "}
                        <button
                            onClick={() =>
                                setSelectOption(selectedOptions.filter(value => value !== data))
                            }
                        >
                            {" "}
                            <Close fontSize="small" />
                        </button>
                    </Box>
                ))}
            </Box>
            <Autocomplete
                fullWidth
                open={open}
                sx={{ marginTop: "1rem" }}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={option => option}
                options={options}
                loading={loading}
                onChange={(_event, value) => {
                    handleFieldChange(value);
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={textFieldLabel}
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        error={error}
                    />
                )}
            />
        </Box>
    );
}
