import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Questionnaire from "../jobs/Questionnaire";
import ReturnButton from "../../components/ReturnButton";
import {
    createSurvey,
    getSurveyDetails,
    updateSurveyData,
} from "../../controller/SurveyController";
import { createSurveyServerObj } from "../../models/SurveyModel";
import { Save } from "@mui/icons-material";
import { HTTPStatus } from "../../utils/helperObj";
import { message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { handleControllerResultException } from "../../utils/handleException";
import { removeServeyQuestion } from "../../controller/JobController";

export default function SurveyForm() {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [formType, setFormType] = useState("");
    const [questionList, setQuestionList] = useState(questionListType);
    const [savingForm, setSavingForm] = useState(false);
    const [fetchingDetails, setFetchingDetails] = useState(false);
    const navigate = useNavigate();
    const questionnaireId = useRef(0);

    const { id } = useParams();

    const handleSurveyDetails = useCallback(
        async id => {
            setFetchingDetails(true);
            const res = await getSurveyDetails(id);
            setFetchingDetails(false);
            if (res[0] !== null) {
                setTitle(res[0].title);
                setDescription(res[0].description);
                setFormType(res[0].formType);
                setQuestionList(res[0].questions);
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    useEffect(() => {
        if (id) {
            handleSurveyDetails(id);
        }
    }, [id, handleSurveyDetails]);

    async function handleFormSave() {
        setSavingForm(true);
        const res = await createSurvey(
            createSurveyServerObj({
                title,
                description,
                formType,
                questions: questionList,
            })
        );
        setSavingForm(false);
        if (res.statusCode === HTTPStatus.CREATED) {
            navigate(-1)
            message.success(res.success);
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    async function handleFormUpdate() {
        setSavingForm(true);
        const res = await updateSurveyData({
            ...createSurveyServerObj({
                id,
                title,
                description,
                formType,
                questions: questionList,
            }),
            id,
        });
        setSavingForm(false);
        if (res[0] !== null) {
            questionnaireId.current = res[0].questionnaireId;
            setQuestionList(res[0].questions)
            message.success("Question successfully updated");
        } else {
            handleControllerResultException(res[1], navigate)
        }
    }


    async function handleRemoveQuestion(index, questionId) {
        const res = await removeServeyQuestion(questionId);
        if (res.statusCode === HTTPStatus.SUCCESS) {
            message.success(res.success);
            const newArr = [...questionList];
            newArr.splice(index, 1);
            setQuestionList(newArr);
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    if (fetchingDetails) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <CircularProgress size={"3rem"} />
            </Box>
        );
    }

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ marginX: "auto", paddingY: "5rem" }}>
                <Box
                    sx={{
                        marginBottom: "2rem",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <ReturnButton />
                    <Button
                        onClick={id ? handleFormUpdate : handleFormSave}
                        variant="contained"
                        disabled={savingForm}
                        sx={{ width: "120px", height: "40px" }}
                    >
                        {savingForm ? (
                            <CircularProgress size={"20px"} />
                        ) : (
                            <>
                                <Save
                                    fontSize="small"
                                    sx={{ marginRight: "5px" }}
                                />
                                Save
                            </>
                        )}
                    </Button>
                </Box>
                <Questionnaire
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    JobQuestionnaire={questionList}
                    setJobQuestionnaire={setQuestionList}
                    removeQuestionFunc={handleRemoveQuestion}
                >
                    <FormControl fullWidth>
                        <InputLabel id="formTypeLabel">Form type</InputLabel>
                        <Select
                            labelId="formTypeLabel"
                            value={formType}
                            onChange={e => setFormType(e.target.value)}
                            label="Form type"
                        >
                            <MenuItem value={"survey"}>Survey</MenuItem>
                            <MenuItem value={"assignment"}>Assignment</MenuItem>
                            <MenuItem value={"interview_review"}>
                                Interview Review
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Questionnaire>
            </Container>
        </Box>
    );
}

/**
 * @type {import('../../models/JobModel').JobQuestionnaire[]}
 */
const questionListType = [];
