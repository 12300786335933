import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import axios from "axios";
import { Timeline } from "@mui/lab";
import noDataImg from "../../Images/noData.png";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const WorkExperience = () => {
    const navigate = useNavigate();

    const [workExData, setWorkExData] = useState([]);

    const getWorkexData = () => {
        axios
            .get(
                `hri_admin/bulk-resume-detail/${localStorage.getItem(
                    "Un-registered User"
                )}`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then(res => {
                console.log("Work Experience Data: ", res.data.work_exp_list);
                setWorkExData(res.data.work_exp_list);
            })
            .catch(err => {
                console.log(
                    "Error occurred fetching Work Experience Data: ",
                    err
                );
            });
    };

    useEffect(() => {
        getWorkexData();
    }, []);

    return (
        <div className="bg-white rounded-md p-4 m-4">
            {workExData ? (
                <p className="text-2xl text-[#0865B6] font-semibold">
                    Work Experience
                </p>
            ) : (
                <img src={noDataImg} />
            )}

            <div>
                {workExData &&
                    workExData?.map(experience => (
                        <Timeline>
                            <TimelineItem
                                key={experience.id}
                                className="before:hidden"
                            >
                                <TimelineSeparator>
                                    <TimelineDot
                                        sx={{ background: "#0865B6" }}
                                    />
                                    <TimelineConnector
                                        sx={{ background: "#0865B6" }}
                                    />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <div className="pb-4">
                                        <div className="space-y-3">
                                            <p className="text-xl font-semibold">
                                                {experience.company
                                                    ? experience.company
                                                    : "NA"}
                                            </p>
                                            <p>
                                                {experience.position
                                                    ? experience.position
                                                    : "NA"}
                                            </p>
                                            <p>
                                                {experience.start_year
                                                    ? experience.start_year
                                                    : "NA"}
                                                -
                                                {experience.end_year
                                                    ? experience.end_year
                                                    : "NA"}
                                            </p>
                                        </div>
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    ))}
            </div>
        </div>
    );
};

export default WorkExperience;
