import Cookies from "universal-cookie";
import { handleException } from "../utils/handleException";
import axios from "axios";
import { BackendAPI } from "../constants/backendAPI";
import { createBroadcastMessageObj } from "../models/BroadcastMessageModel";
import { HttpResponse } from "../models/HttpResponse";

const cookies = new Cookies();

/**
 * @param {"All" | "Active" | "Old" | "Request"} messageType
 * @returns {Promise<[{results:import("../models/BroadcastMessageModel").BroadcastMessageModel[], count:number} | null, import("../models/HttpResponse").HttpResponse | null]>}
 */
export async function getBroadCastMessage(messageType = "All", page = 1) {
    const result = [null, null];
    try {
        const res = await axios.get(BackendAPI.broadcastMessage.messageList, {
            params: {
                broadcast_type: messageType,
                page,
            },
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            },
        });
        const messageArr = [];
        for (let x of res.data.results) {
            messageArr.push(createBroadcastMessageObj(x));
        }
        result[0] = {
            results: messageArr,
            count: res.data.count,
        };
        return result;
    } catch (err) {
        console.log(err);
        result[1] = handleException(err);
        return result;
    }
}

export async function changeBroadcastMessageStatus(messageId) {
    try {
        const res = await axios.get(
            `${BackendAPI.broadcastMessage.changeStatus}/${messageId}`,
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Broadcast status changed");
    } catch (err) {
        return handleException(err);
    }
}

export async function deleteBroadcastMessage(messageId) {
    try {
        const res = await axios.delete(
            `${BackendAPI.broadcastMessage.deleteMessage}/${messageId}`,
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Broadcast status changed");
    } catch (err) {
        return handleException(err);
    }
}

export async function createBroadcastMessage({
    reciverEmails,
    subject,
    message,
    broadcastMedium,
}) {
    try {
        const res = await axios.post(
            BackendAPI.broadcastMessage.createMessage,
            {
                receiver_email: reciverEmails,
                header: subject,
                message,
                broadcast_type: broadcastMedium,
            },
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Broadcast message is sent");
    } catch (err) {
        return handleException(err);
    }
}

/**
 *
 * @param {string} broadcastId
 * @returns {Promise<[import("../models/BroadcastMessageModel").BroadcastMessageModel | null, HttpResponse | null]>}
 */
export async function getBroadcastMessageDetails(broadcastId) {
    const result = [null, null];
    try {
        const res = await axios.get(
            `${BackendAPI.broadcastMessage.messageDetails}/${broadcastId}`,
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        result[0] = createBroadcastMessageObj(res.data);
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

export async function updateBroadcastMessage({ header, message, broadcastId }) {
    try {
        const res = await axios.put(
            `${BackendAPI.broadcastMessage.updateBroadcastMessage}/${broadcastId}`,
            {
                header,
                message,
            },
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Broadcast is updated")
    } catch (err) {
        return handleException(err);
    }
}
