/**
 * @typedef {object} JobRoleModel
 * @property {number} id
 * @property {string} description
 * @property {string} name
 */

export function createJobRoleObj(data) {
    /**
     * @type {JobRoleModel}
     */
    const result = {
        id: data.id,
        name: data.name,
        description: data.description !== null ? data.description : "",
    };
    return result;
}
