import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect } from "react";

/**
 * @param {import("@tiptap/react").Content | undefined} contents
 */
export default function useCreateEditor(contents = "<p>Hello world</p>") {
    const editor = useEditor({
        content: "<p>Hello</p>",
        editorProps: {
            attributes: {
                class: "editor-base  mt-4",
            },
        },
        extensions: [
            StarterKit.configure({
                bulletList: {
                    keepMarks: true,
                    keepAttributes: true,
                },
                orderedList: {
                    keepMarks: true,
                    keepAttributes: true,
                },
            }),
        ],
    });

    useEffect(() => {
        if (editor !== null && contents !== undefined) {
            editor.commands.setContent(contents);
        }
    }, [contents, editor]);
    return editor;
}
