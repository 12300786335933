import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/Header";
import SelectBroadcastRecivers from "./SelectBroadcastRecivers";
import useCreateEditor from "../../hooks/useCreateEditor";
import Editor from "../../components/richEditor/Editor";
import { ArrowBackIos, Save } from "@mui/icons-material";
import {
    createBroadcastMessage,
    getBroadcastMessageDetails,
    updateBroadcastMessage,
} from "../../controller/broadcastMessageController";
import { useNavigate, useParams } from "react-router-dom";
import { HTTPStatus } from "../../utils/helperObj";
import { message } from "antd";
import { handleControllerResultException } from "../../utils/handleException";
import { blue } from "@mui/material/colors";

export default function BroadcastMessageForm() {
    const navigate = useNavigate();
    const [broadcastReciverType, setBroadcastReciverType] = useState("Users"); // Users, Companies, Teams
    const [broadcastMedium, setBroadcastMedium] = useState("email"); // email ,  notification
    const [selectedUserList, setSelectedUserList] = useState([]);
    const [selectedCompanyList, setSelectedCompanyList] = useState([]);
    const [selectedTeamList, setSelectedTeamList] = useState([]);
    const [messageSubject, setMessageSubject] = useState("");
    const [messageBody, setMessageBody] = useState("");
    const [showError, setShowError] = useState(false);
    const [sendingBroadcast, setSendingBroadcast] = useState(false);
    const [messageReciverEmails, setMessageReciverEmails] = useState([]);
    const [fetchingBroadcastDetails, setFetchingBroadcastDetails] =
        useState(false);
    const { id } = useParams();

    const editor = useCreateEditor(messageBody);

    function checkFields() {
        return (
            (selectedUserList.length > 0 ||
                selectedTeamList.length > 0 ||
                selectedCompanyList.length > 0) &&
            messageSubject !== ""
        );
    }

    async function handleCreateFrom() {
        if (checkFields()) {
            let reciverEmailList = "";
            if (broadcastReciverType === "Users") {
                reciverEmailList = selectedUserList.toString();
            } else if (broadcastReciverType === "Teams") {
                reciverEmailList = selectedTeamList.toString();
            } else {
                reciverEmailList = selectedCompanyList.toString();
            }
            setSendingBroadcast(true);
            const res = await createBroadcastMessage({
                reciverEmails: reciverEmailList,
                broadcastMedium,
                message: editor.getHTML(),
                subject: messageSubject,
            });
            setSendingBroadcast(false);
            if (res.statusCode === HTTPStatus.CREATED) {
                message.success(res.success);
            } else {
                handleControllerResultException(res, navigate);
            }
        } else {
            setShowError(true);
        }
    }

    const handleBroadcastMessageDetails = useCallback(
        async id => {
            setFetchingBroadcastDetails(true);
            const res = await getBroadcastMessageDetails(id);
            setFetchingBroadcastDetails(false);
            if (res[0] !== null) {
                setMessageReciverEmails(res[0].reciverEmailList);
                setMessageSubject(res[0].header);
                setMessageBody(res[0].message);
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    useEffect(() => {
        if (id) {
            handleBroadcastMessageDetails(id);
        }
    }, [id, handleBroadcastMessageDetails]);

    async function handleBroadcastUpdate() {
        if (messageSubject !== "") {
            setSendingBroadcast(true);
            const res = await updateBroadcastMessage({
                broadcastId: id,
                header: messageSubject,
                message: messageBody,
            });
            setSendingBroadcast(false);
            if (res.statusCode === HTTPStatus.SUCCESS) {
                message.success(res.success);
            } else {
                handleControllerResultException(res, navigate);
            }
        } else {
            setShowError(true);
        }
    }

    if (fetchingBroadcastDetails) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ paddingY: "3rem" }}>
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Button onClick={() => navigate(-1)}>
                        <ArrowBackIos />
                        Return
                    </Button>
                    <Button
                        disabled={sendingBroadcast}
                        variant="contained"
                        onClick={id ? handleBroadcastUpdate : handleCreateFrom}
                        sx={{ width: "100px", height: "40px" }}
                    >
                        {sendingBroadcast ? (
                            <CircularProgress size={"20px"} />
                        ) : (
                            <>
                                <Save sx={{ marginRight: "5px" }} />
                                Save
                            </>
                        )}
                    </Button>
                </Box>
                {!id && (
                    <>
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            marginTop={"3rem"}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="broadcast-reciver">
                                    Broadcast message reciver
                                </InputLabel>
                                <Select
                                    labelId="broadcast-reciver"
                                    value={broadcastReciverType}
                                    label="Broadcast message reciver"
                                    onChange={e =>
                                        setBroadcastReciverType(e.target.value)
                                    }
                                >
                                    <MenuItem value={"Users"}>Users</MenuItem>
                                    <MenuItem value={"Companies"}>
                                        Companies
                                    </MenuItem>
                                    <MenuItem value={"Teams"}>Teams</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ marginLeft: "2rem" }}>
                                <InputLabel id="broadcast-medium">
                                    Broadcast medium
                                </InputLabel>
                                <Select
                                    labelId="broadcast-medium"
                                    value={broadcastMedium}
                                    label="Broadcast medium"
                                    onChange={e =>
                                        setBroadcastMedium(e.target.value)
                                    }
                                >
                                    <MenuItem value={"email"}>Email</MenuItem>
                                    <MenuItem value={"notification"}>
                                        Notification
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <SelectBroadcastRecivers
                            broadcastReciverType={broadcastReciverType}
                            selectedUserList={selectedUserList}
                            setSelectedUserList={setSelectedUserList}
                            selectedCompanyList={selectedCompanyList}
                            setSelectedCompanyList={setSelectedCompanyList}
                            selectedTeamList={selectedTeamList}
                            setSelectedTeamList={setSelectedTeamList}
                            error={showError}
                        />
                    </>
                )}

                {messageReciverEmails.length > 0 && <Box marginY={"2rem"}>
                    <Typography fontSize={"18px"}>Email Recivers: </Typography>
                    <Box display={"flex"} marginTop={"10px"} flexWrap={"wrap"}>
                        {
                            messageReciverEmails.map((data, index) => (
                                <Typography
                                    key={index}
                                    sx={{
                                        marginLeft: "10px",
                                        backgroundColor: blue[200],
                                        padding: "5px 10px",
                                        borderRadius: "20px",
                                    }}
                                >
                                    {data.email}
                                </Typography>
                            ))}
                    </Box>
                </Box>}

                <TextField
                    label="Message Subject"
                    value={messageSubject}
                    onChange={e => setMessageSubject(e.target.value)}
                    fullWidth
                    sx={{ marginTop: "1rem" }}
                    error={showError && messageSubject === ""}
                />
                <Box sx={{ marginTop: "1rem" }}>
                    <Typography fontSize={"18px"} sx={{ marginBottom: "10px" }}>
                        Message{" "}
                    </Typography>
                    <Editor editor={editor} />
                </Box>
            </Container>
        </Box>
    );
}

/**
 * @type {import("../../models/StudentModel").StudentModel[]}
 */

const userListType = [];
