/**
 * @typedef {object} SurveyModel
 * @property {id} id
 * @property {string} title
 * @property {string} description
 * @property {string} formType
 * @property {import("./JobModel").JobQuestionnaire[]} questions
 */

import { createJobQuestionnaireList } from "./JobModel";

/**
 *
 * @param {SurveyModel} data
 */
export function createSurveyServerObj(data) {

    const result = {
        id: data.id,
        name: data.title,
        description: data.description,
        form_type: data.formType,
        questions: data.questions.map(data => ({
            id: data.id,
            question: data.question,
            description: data.description,
            question_type: data.answerMode,
            required: data.required,
            options:
                data.answerMode === "ChoiceField"
                    ? data.choiceFieldQuestions.toString()
                    : null,
        })),
    };
    return result;
}

export function createSurveyObj(data) {
    /**
     * @type {SurveyModel}
     */
    const result = {
        id: data.id,
        title: data.name,
        description: data.description,
        formType: data.form_type,
        questions: data.questions
            ? createJobQuestionnaireList(data.questions)
            : [],
    };
    return result
}
