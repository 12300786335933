import { isValidSkills } from "../utils/validation";

export function isValidInterview(data) {
    if (!data.user) return "Please select an user";
    if (!data.interviewer_no) return "Please add a phone number";
    if (!data.date || data.date === "Invalid date")
        return "Please select a date";
    if (!data.skill || isValidSkills(data.skill) === false)
        return "Please add valid skills";
    if (!data.experience) return "Please select an experience";
    if (!data.time || data.time === "Invalid date")
        return "Please select a time";
    if (!data.job_description) return "Please select Position";

    return null;
}
