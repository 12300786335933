/**
 *
 * @param {import("../models/InstituteModel").InstituteModel & {instituteLogo:File | undefined}} props
 */

import axios from "axios";
import { handleException } from "../utils/handleException";
import { BackendAPI } from "../constants/backendAPI";
import Cookies from "universal-cookie";
import { HttpResponse } from "../models/HttpResponse";
import { createInstituteObj } from "../models/InstituteModel";
import { createUserObj } from "../models/StudentModel";

const cookies = new Cookies();

function createInstituteFormData({
    id,
    instituteLogo,
    email,
    instituteName,
    recruitmentHead,
    phoneNumber,
    city,
    state,
    country,
    address,
    pinCode,
}) {
    const formData = new FormData();
    id && formData.append("id", id);
    instituteLogo && formData.append("institute_logo", instituteLogo);
    email && formData.append("email", email);
    formData.append("institute_name", instituteName);
    formData.append("name", recruitmentHead);
    formData.append("phone_number", phoneNumber);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("country", country);
    formData.append("add", address);
    formData.append("pin", pinCode);
    return formData;
}

/**
 *
 * @param {import("../models/InstituteModel").InstituteModel & {instituteLogo:File | undefined}} props
 */
export async function createInstitute({
    instituteLogo,
    email,
    instituteName,
    recruitmentHead,
    phoneNumber,
    city,
    state,
    country,
    address,
    pinCode,
}) {
    const instituteForm = createInstituteFormData({
        instituteLogo,
        email,
        instituteName,
        recruitmentHead,
        phoneNumber,
        city,
        state,
        country,
        address,
        pinCode,
    });
    try {
        const res = await axios.post(
            BackendAPI.institute.createInstitute,
            instituteForm,
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        return new HttpResponse(
            res.status,
            "Institute created successfully",
            null
        );
    } catch (err) {
        return handleException(err);
    }
}

/**
 * @returns {Promise<[{results:import("../models/InstituteModel").InstituteModel[], count:number} | null, import("../models/HttpResponse").HttpResponse | null]>}
 */

export async function getInsituteList(page = 1) {
    const result = [null, null];
    try {
        const res = await axios.get(BackendAPI.institute.instituteList, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            },
            params: {
                page,
            },
        });
        const instituteArr = [];
        for (let x = 0; x < res.data.results.length; x++) {
            instituteArr.push(createInstituteObj(res.data.results[x]));
        }
        result[0] = {
            results: instituteArr,
            count: res.data.count,
        };
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

/**
 *
 * @param {string} instituteId
 * @returns {Promise<[import("../models/InstituteModel").InstituteModel | null, import("../models/HttpResponse").HttpResponse | null]>}
 */

export async function instituteDetails(instituteId) {
    const result = [null, null];
    try {
        const res = await axios.get(
            `${BackendAPI.institute.instituteDetails}/${instituteId}`,
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        result[0] = createInstituteObj(res.data);
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

/**
 *
 * @param {import("../models/InstituteModel").InstituteModel & {instituteLogo:File | undefined}} props
 */
export async function updateInstituteDetails({
    instituteLogo,
    id,
    instituteName,
    recruitmentHead,
    phoneNumber,
    city,
    state,
    country,
    address,
    pinCode,
}) {
    try {
        const res = await axios.put(
            `${BackendAPI.institute.instituteInfoUpdate}/${id}`,
            createInstituteFormData({
                instituteLogo,
                instituteName,
                recruitmentHead,
                phoneNumber,
                city,
                state,
                country,
                address,
                pinCode,
            }),
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        return new HttpResponse(res.status, "Update successful", null);
    } catch (err) {
        return handleException(err);
    }
}

/**
 *
 * @param {string} instituteId
 * @returns {Promise<[{results:import("../models/StudentModel").StudentModel[], count:number}|null, HttpResponse|null]>}
 */
export async function instituteStudentList(instituteId, page = 1) {
    const result = [null, null];
    try {
        const res = await axios.get(
            `${BackendAPI.institute.studentList}/${instituteId}`,
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
                params: {
                    page,
                },
            }
        );
        const studentArr = [];
        for (let x = 0; x < res.data.results.length; x++) {
            studentArr.push(createUserObj(res.data.results[x]));
        }
        result[0] = {
            results: studentArr,
            count: res.data.count,
        };
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}
