import { Visibility } from "@mui/icons-material";
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";

/**
 * @typedef {object} Props
 * @property {import('../../models/UserApplicationModel').UserApplicationModel} application
 */

/**
 * @param {Props} props
 */

export default function ApplicantCertificates({ application }) {
    return (
        <Box marginY={"2rem"}>
            <Typography variant="h6" fontWeight={"500"}>
                Certifications
            </Typography>
            <TableContainer
                component={Paper}
                elevation={2}
                sx={{ marginTop: "1rem" }}
            >
                <Table>
                    <TableHead sx={{ backgroundColor: blue[700] }}>
                        <TableRow>
                            <TableCell sx={{ color: "white" }}>
                                Subject
                            </TableCell>
                            <TableCell sx={{ color: "white" }}>
                                Institute
                            </TableCell>
                            <TableCell sx={{ color: "white" }}>
                                Starting / ending year
                            </TableCell>
                            <TableCell sx={{ color: "white" }}>
                                Address
                            </TableCell>
                            <TableCell sx={{ color: "white" }}>View</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {application.certificates.length > 0 &&
                            application.certificates.map(certificate => (
                                <TableRow key={certificate.id}>
                                    <TableCell>{certificate.degree}</TableCell>
                                    <TableCell>{certificate.university}</TableCell>
                                    <TableCell>{certificate.startingYear} - {certificate.endingYear}</TableCell>
                                    <TableCell>{certificate.city}, {certificate.country}</TableCell>
                                    <TableCell>
                                        <a href={certificate.certificateLink} target="_blank">
                                            <Button>
                                                <Visibility fontSize="small" />
                                            </Button>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
