// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBeqB2K6Suth4ZefpUWBLfzaMBh-KAYUC4",
  authDomain: "pstest-fce85.firebaseapp.com",
  projectId: "pstest-fce85",
  storageBucket: "pstest-fce85.appspot.com",
  messagingSenderId: "435080678397",
  appId: "1:435080678397:web:58a86178a40803d7056794"
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider }; 