import { useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import React, { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";


const Contact = ({ open, onClose }) => {

  const cookies = new Cookies();

  const date = new Date();
  const newDate = moment(date).format("YYYY-MM-DD");
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false)
  const headers = {
    "Authorization": `Token ${cookies.get("token")}`
  }


  function Send() {
    if (header.length > 2 && body.length > 10) {
      setLoading(true)
      axios.post("/hri_admin/connect-us", { header, body }, { headers: headers })
        .then(res => {
          setLoading(false)
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#1ab394", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#BAE8DE" },
          })

        }).catch(err => {
          setLoading(false)
          toast.error(err.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#BAE8DE" },
          })
        })
    }
  }




  return (
    <Modal
      open={open}
      onClose={() => {
        setHeader("");
        setBody("");
        onClose();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "5px",
          p: 4,
        }}
      >
        <Typography fontSize={"15px"}>
          Date:{" "}
          <span style={{ fontWeight: "bold", color: blue[700] }}>
            {newDate}
          </span>
        </Typography>
        <TextField
          value={header}
          onChange={(e) => setHeader(e.target.value)}
          fullWidth
          variant="outlined"
          label="Subject of the message"
          sx={{ margin: "1.4rem 0rem" }}
        />
        <TextField
          value={body}
          onChange={(e) => setBody(e.target.value)}
          fullWidth
          multiline
          rows={5}
          variant="outlined"
          label="Message (minimum 10 characters)"
        />
        <Box
          sx={{
            marginTop: "1.5rem",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={Send}
            sx={{ width: "7rem", padding: "8px 0px" }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={"1.4rem"} />
            ) : (
              <span>
                <SendIcon sx={{ marginRight: "10px" }} />
                Send
              </span>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );

};

export default Contact;
