import React from "react";
import { sanitize } from "dompurify";
import styles from "./jobdesc.module.css";
export default function RichDescription({ children }) {
    let parsedData = "";
    const sanitizedHTML = () => {
        try {
            parsedData = JSON.parse(children);
        } catch (_err) {
            parsedData = children;
        }
        const sanitizedData = sanitize(parsedData);
        return (
            <div
                className={styles.job_desc}
                dangerouslySetInnerHTML={{ __html: sanitizedData }}
            ></div>
        );
    };
    return <div>{sanitizedHTML()}</div>;
}
