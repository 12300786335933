import React, { useState, useEffect } from "react";
import image from "./HRI-home.png";
import { useNavigate } from "react-router";
import axios from "axios";
import google from "./Google.png";
import facebook from "./Facebook.png";
import { useDispatch } from "react-redux";
import { save } from "../../features/InfoSlice";
import { toast } from "react-toastify";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { signInWithPopup } from "firebase/auth";
// import { auth, provider } from "../../firebase";
// import linkedIn from "../../Images/Linkedin_logo.png";
import Cookies from "universal-cookie";
// import { postRequest } from "../../utils/request";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { ArrowRight, ArrowRightAlt, LoginSharp, Visibility, VisibilityOff } from "@mui/icons-material";

const cookies = new Cookies();

const Login = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("btn1");
  const [info, setInfo] = useState({name:"HRI Intelligence", tagline:"Plan your next recruitment here", logo:""})

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  async function getLandingPageInfo() {
    try {
      const res = await axios.get("profile/landing-page");
      const business = res.data.business_details[0] || null;
  
      if (business) {
        const name = business.name.length > 1 ? business.name : null;
        const tagline = business.tag_line.length > 1 ? business.tag_line : null;
        setInfo({ logo: business.logo, name, tagline });
      } 
      else {
        throw new Error("Business details not found");
      }
    } 
    catch (err) {
      throw new Error("Can't get landing-page info", err);
    }
  }
  

  useEffect(()=>{
    getLandingPageInfo()
  },[])

  // const [user] = useAuthState(auth);

  // console.log(user);

  // const signInWithGoogle = () => {
  //   signInWithPopup(auth, provider)
  //     .then(() => {
  //       let formField = new FormData();
  //       formField.append("access_token", user?.accessToken);
  //       postRequest("auth/google/login", formField, "/dashboard");
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const navigate = useNavigate();

  //login in
  const [email_login, setEmail_login] = useState("");
  const [password_login, setPassword_login] = useState("");
  const data = {
    email: email_login,
    password: password_login,
  };

  const LoginUser = async (e) => {
    e.preventDefault();

    axios
      .post(`auth/admin/login`, data)
      .then((res) => {
        dispatch(
          save({
            user: res.data,
          })
        );
        localStorage.setItem("user", res.data.user.id.toString());
        cookies.set("token", res.data.user.token);
        cookies.set("profileName", res.data.user.profile.name);
        cookies.set("profileImage", res.data.user.profile.image);
        navigate("/dashboard");
        toast.success("Successfully logged in", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.errors.error[0] || err?.message || "ERROR  from update in form", err);
        toast.error(err?.response?.data?.errors.error[0] || err?.message ||"Error logging in", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  // const handleClickShowPassword = () => {
  //   setValues({
  //     ...values,
  //     showPassword: !values.showPassword,
  //   });
  // };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  // const handleClickShowPassword2 = () => {
  //   setValues({
  //     ...values,
  //     showPassword2: !values.showPassword2,
  //   });
  // };

  // const handleMouseDownPassword2 = (event) => {
  //   event.preventDefault();
  // };

  const handleClickShowPassword3 = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      showPassword3: !values.showPassword3,
    });
  };

  // const handleMouseDownPassword3 = (event) => {
  //   event.preventDefault();
  // };

  //registeration of the user
  const [email_signup, setEmail_signup] = useState("");
  const [password_signup, setPassword_signup] = useState("");

  const SignupUser = async () => {
    let formField = new FormData();

    formField.append("email", email_signup);
    formField.append("password", password_signup);

    axios
      .post(`auth/user/register`, formField)
      .then((response) => {
        console.log(response.data);
        console.log(response);
        window.location.reload();
        toast.success("Successfully signed in", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        console.log("ERROR in form", err);
        toast.error("Error signing in", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  //Forgot password
  const [userEmail, setUserEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const dataToForgetPassword = {
    email: userEmail,
  };

  const ForgotPassword = (e) => {
    e.preventDefault();

    axios
      .post(`auth/user/forgot/password`, dataToForgetPassword)
      .then((res) => {
        cookies.set("token", res.data.token);
        setOtp(res.data.otp);
        setActive("btn4");
        toast.success(`OTP sent to your email`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        console.log(err);
        if (userEmail === "") {
          toast.error("Please enter E-mail ID", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        }
      });
  };

  //Verify OTP
  const dataToVerifyOtp = {
    email: userEmail,
    otp: otp,
  };

  const VerifyOtp = (e) => {
    e.preventDefault();

    axios
      .post(`auth/user/forgot/password/verify`, dataToVerifyOtp, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        console.log(res)
        cookies.set("token", res.data.token);
        setActive("btn5");
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        if (otp === "") {
          toast.error("Please enter  OTP", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        } else {
          toast.error(err.error || err.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        }
      });
  };

  //Reset Password
  const dataToResetPassword = {
    password: newPassword,
  };

  const handleClickShowNewPassword = () => {
    setValues({
      ...values,
      showNewPassword: !values.showNewPassword,
    });
  };

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const ResetPassword = (e) => {
    e.preventDefault();

    axios
      .post(`auth/user/reset/password`, dataToResetPassword, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setActive("btn1");
        toast.success("Successfully Changed the password", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center pt-10 mx-4 md:mx-7 lg:mx-10 md:space-x-5">
        <div className=" mt-5 md:mr-4 md:mx-3">

          <div style={{display:"flex", alignItems:"flex-start", gap:5}}>
            {info.logo && <img src={info.logo} style={{width: "auto", maxHeight: 50, backgroundPosition:"cover"}}/>}
            <div className="h-max text-2xl">
              Welcome to <b style={{ color: "#0865B6" }}>{info.name}</b>
                <p
                  style={{ color: "rgb(1,1,1,0.4)" }}
                  className="text-sm font-semibold"
                  >
                  {info.tagline}
                </p>
            </div>
          </div>

          <img src={image} className="w-0 md:w-full mt-10 px-4" alt="" />
        </div>

        <div
          className="p-6 md:px-8 md:py-2 lg:px-10 lg:py-6
                md:w-10/12 border-3 flex flex-wrap rounded-xl border-[#0865B6]"
        >
          {active === "btn1" ? (
            <div className="m-auto">
              <p className="text-xl mb-4 md:mb-6 lg:mb-10 md:text-2xl lg:text-3xl font-bold">
                Login as <span style={{color:"#0865b6"}}>Admin</span>
              </p>
              <form className="space-y-3 sm:space-y-4 lg:space-y-5">
                <TextField
                  fullWidth
                  type="email"
                  label="Email ID"
                  placeholder="Enter your Email ID"
                  value={email_login}
                  onChange={(e) => setEmail_login(e.target.value)}
                />
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    label="Password"
                    value={password_login}
                    type={values.showPassword3 ? "text" : "password"}
                    placeholder="Enter Your Password"
                    onChange={(e) => setPassword_login(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          type={values.showPassword ? "text" : "password"}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword3}
                          // onMouseDown={handleMouseDownPassword3}
                          edge="end"
                        >
                          {values.showPassword3 ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    required
                  />
                </FormControl>
                <div align="end" style={{ color: "rgb(1,1,1,0.5)" }}>
                  <p
                    className="cursor-pointer"
                    onClick={(e) => setActive("btn3")}
                  >
                    Forgot Password?
                  </p>
                </div>
                <div className="">
                  <Button
                    fullWidth
                    className="text-lg"
                    onClick={LoginUser}
                    style={{ backgroundColor: "#0865B6", color: "white" }}
                  >
                    LogIn
                  </Button>
                </div>

                {/* <div>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ borderColor: "#0856B6" }}
                    onClick={() => setActive("btn2")}
                  >
                    Not a Member? SignUp
                  </Button>
                </div> */}

                {/* <p className="text-center text-lg">Or login with</p>

                <div className="row text-center">
                  <div
                    className="col-4"
                    onClick={() => {
                      signInWithGoogle();
                    }}
                  >
                    <button className="" onClick={(e) => e.preventDefault()}>
                      <img src={google} className="h-7 md:h-10" alt="" />
                    </button>
                  </div>
                  <div className="col-4">
                    <button className="">
                      <img src={facebook} className="h-7 md:h-10" alt="" />
                    </button>
                  </div>
                  <div className="col-4">
                    <button className="">
                      <img src={linkedIn} className="h-7 md:h-10" alt="" />
                    </button>
                  </div>
                </div> */}
                <p align="center" style={{ color: "rgb(1,1,1,0.4)" }}>
                  The <b style={{ color: "#0865B6" }}>terms of use</b> and{" "}
                  <b style={{ color: "#0865B6" }}>our Policy</b>
                </p>
              </form>
            </div>
          ) : (
            <></>
          )}

          {/* {active === "btn2" ? (
            <div>
              <p className="text-5xl mb-5">Sign Up as Admin</p>
              <form className="space-y-3 sm:space-y-4 lg:space-y-5">
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  placeholder="Enter your Email ID"
                  value={email_signup}
                  onChange={(e) => setEmail_signup(e.target.value)}
                />
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    label="Password"
                    
                    id="Password"
                    type={values.showPassword ? "text" : "password"}
                    onChange={(e) => setPassword_signup(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          type={values.showPassword ? "text" : "password"}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    required
                  />
                </FormControl>

                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    label="Confirm Password"
                    id="confirm_password"
                    type={values.showPassword2 ? "text" : "password"}
                    onChange={(e) => setNewPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          type={values.showPassword ? "text" : "password"}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                          edge="end"
                        >
                          {values.showPassword2 ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    required
                  />
                </FormControl>
                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  placeholder="Enter your Password"
                  value={password_signup}
                  onChange={(e) => setPassword_signup(e.target.value)}
                />
                <div>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: "#0865B6", color: "white" }}
                    onClick={SignupUser}
                  >
                    SignUp
                  </Button>
                </div>
                <div>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ color: "#0865B6" }}
                    onClick={() => setActive("btn1")}
                  >
                    Already have an account? LogIn
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <></>
          )} */}

          {active === "btn3" ? (
            <div className="m-auto lg:w-[27rem] ">
              <p className="text-xl mb-5 md:mb-6 lg:mb-10 left-0  md:text-2xl lg:text-3xl font-bold">
                Forgot Password
              </p>
          
              <div className="space-y-3 sm:space-y-4 lg:space-y-5">
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  placeholder="Enter your email address"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />

                <div className="m-auto w-full">
                <div className="flex w-full justify-end">
                <Button onClick={()=>setActive("btn1")} variant="outlined" className="my-2" color="error" >Previous <ArrowRightAlt className="scale-125" /></Button>
                </div>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: "#0865B6" }}
                    onClick={ForgotPassword}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
          ) : null}

          {active === "btn4" ? (
            <div className="my-auto w-full">
              <p className="text-xl mb-5 md:mb-6 lg:mb-10 md:text-2xl lg:text-3xl font-bold">
                Verify OTP
              </p>
              <form className="space-y-3 sm:space-y-4 lg:space-y-5 w-full">
                <TextField
                  fullWidth
                  type="tel"
                  label="OTP"
                  placeholder="Enter your OTP to verify"
                  // value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <div className="mt-2">
                <div className="flex w-full justify-end">
                <Button onClick={()=>setActive("btn3")} variant="outlined" className="my-2" color="error" >Previous <ArrowRightAlt className="scale-125" /></Button>
                </div>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ background: "#0865b6" }}
                    onClick={VerifyOtp}
                  >
                    
                    Verify
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <></>
          )}

          {active === "btn5" ? (
            <div className="m-auto">
              <p className="text-xl mb-5 md:mb-6 lg:mb-10 md:text-2xl lg:text-3xl font-bold">
                New Password
              </p>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Enter your New Password
                </InputLabel>
                <OutlinedInput
                  label="Enter your new password"
                  value={newPassword}
                  type={values.showNewPassword ? "text" : "password"}
                  onChange={(e) => setNewPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        type={values.showNewPassword ? "text" : "password"}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownNewPassword}
                        edge="end"
                      >
                        {values.showNewPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                />
              </FormControl>
              <div className="mt-3">
              <div className="flex w-full justify-end">
               <h2 className="mr-2 my-auto">Or Goto</h2> <Button onClick={()=>setActive("btn1")} variant="contained" className="my-2" color="success" >Login <LoginSharp className="scale-125" /></Button>
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  style={{ background: "#0865B6" }}
                  onClick={ResetPassword}
                >
                  Reset
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
