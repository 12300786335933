/**
 * @typedef {object} Props
 * @property {string} label
 * @property {string} text
 */

import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

/**
 * @param {Props} props
 */
export default function ApplicationLabelText({ label, text }) {
    return (
        <Box>
            <Typography fontSize={"13px"} fontWeight={"bold"} color={grey[500]}>
                {label}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={"bold"} color={grey[800]}>
                {text}
            </Typography>
        </Box>
    );
}
