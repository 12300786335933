import React, { useEffect, useState } from 'react'
import Spinner from '../Spinner/Spinner';
import Cookies from "universal-cookie";
import { MdArrowBackIos } from "react-icons/md";
import axios from 'axios';
import { BiChevronDown } from 'react-icons/bi'
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { MdDelete } from 'react-icons/md'
import { EmptyState } from '../../components/EmptyStates/EmptyState';
import noProfile from "../../Images/empty_states/no-payment.png"

const cookies = new Cookies();

const PaymentDetails = () => {
    const [bankAccount, setBankAccount] = useState(0)
    const [data, setData] = useState([]);
    const [billDetails, setBillDetails] = useState("")
    const navigate = useNavigate();

    async function getData() {
        await axios
            .get("hri_admin/paymentdetails", {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((resp) => {
                setData(resp.data);

            })
            .catch((err) => {
                console.log(err);
            });

    }

    function deleteMessage(id) {
        console.log(id)
        axios
            .delete(`hri_admin/paymentdetails/delete/${id}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((resp) => {
                getData()
            })
            .catch((err) => {
                console.log(err);
            });

    };

    useEffect(() => {
        if (cookies.get("token")) {
            getData();
        } else {
            navigate("/");
        }
    }, []);
    return (
        <>
            <div className="bg-white mt-2 space-x-1 flex items-center lg:space-x-4 text-sm md:text-lg xl:text-xl  font-semibold p-[.2rem] md:pl-8 text-gray-600  md:p-1">
                <Link to="/Financials/team-payment-details" className={'text-gray-600 rounded-lg md:px-8 py-1 hover:bg-slate-100 hover:text-blue-700'}>Team Payment Details</Link>
                <Link to="/Financials/payment-details" className={'rounded-lg md:px-8 py-1 bg-slate-100 text-blue-700 cursor-pointer'}>Your Payment Details</Link>

            </div>
            <div className='text-lg font-semibold mb-4 mx-4 mt-4'>Primary Payment Details :</div>
            <div className='overflow-auto mx-4 rounded-lg  mt-4 shadow  mb-20'>

                {data.length ? (
                    <div>

                        <table className="w-full ">
                            <thead className="bg-slate-200 border-b-2 border-gray-200">
                                <tr className='text-blue-700 '>
                                    <th className="w-10 p-3 pl-10 text-lg font-semibold tracking-wide text-center">

                                    </th>
                                    <th className="w-40 p-3 text-lg font-semibold tracking-wide text-center">
                                        Account Number
                                    </th>

                                    <th className="w-40 p-3 text-lg font-semibold tracking-wide text-center">
                                        Account Holder Name
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Bank Name
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Details
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Delete
                                    </th>

                                </tr>
                            </thead>


                            <tbody className="divide-y divide-gray-300 text-center">
                                {data.map((user) => {

                                    if (user.detail_type === "Bank Account") {

                                        return (

                                            <tr

                                                key={user.id}

                                                className="bg-white  hover:bg-slate-100"

                                            >
                                                <td className="p-3  pl-10 text-base text-gray-700 whitespace-nowrap">
                                                    <div className='w-12 '>
                                                        <img src={user.logo} alt="..." className='' />
                                                    </div>


                                                </td>
                                                <td className="p-3  MessageLine text-base text-gray-700 whitespace-nowrap">

                                                    {user.account_no}
                                                </td>

                                                <td className="p-3  text-base text-gray-700 whitespace-nowrap">
                                                    {user.person_name}

                                                </td>
                                                <td className="p-3  text-base text-gray-700 whitespace-nowrap">

                                                    {user.bank_name}

                                                </td>
                                                <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                                                    <div onClick={() => {
                                                        cookies.set("BillDesc", user.bill_description)
                                                        cookies.set("BillNotes", user.note)
                                                        cookies.set("BillAmount", user.amount)
                                                        cookies.set("PaymentId", user.id)
                                                        navigate("/PaymentDetailsDetails")
                                                    }}
                                                        className='bg-slate-100   px-2 py-2 rounded-lg text-blue-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer '> View
                                                        Details
                                                    </div>

                                                </td>
                                                <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                                                    <div className='flex justify-center cursor-pointer'><MdDelete onClick={() => {
                                                        deleteMessage(user.id);
                                                    }}
                                                        className='text-2xl text-red-500 hover:text-3xl duration-300 cursor-pointer' /></div>

                                                </td>
                                            </tr>

                                        )
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <EmptyState
                        btn="Add New Payment"
                        img={noProfile}
                        title="No Payment Details"
                        message="There are currently no payment details associated with this account. Add or update your payment information to proceed with transactions."
                        btnLink="/AddPaymentDetails"
                    />

                )}


            </div>
            <div className='text-lg font-semibold mb-4 mx-4 '>UPI :</div>
            <div className='overflow-auto mx-4 rounded-lg   shadow  mb-20'>

                {data.length ? (
                    <div>

                        <table className="w-full ">
                            <thead className="bg-slate-200 border-b-2 border-gray-200">
                                <tr className='text-blue-700 '>
                                    <th className="w-10 p-3 pl-10 text-lg font-semibold tracking-wide text-center">

                                    </th>
                                    <th className="w-40 p-3 text-lg font-semibold tracking-wide text-center">
                                        Account Number
                                    </th>

                                    <th className="w-40 p-3 text-lg font-semibold tracking-wide text-center">
                                        Account Holder Name
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Bank Name
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Details
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Delete
                                    </th>

                                </tr>
                            </thead>


                            <tbody className="divide-y divide-gray-300 text-center">
                                {data.map((user) => {

                                    if (user.detail_type === "UPI") {

                                        return (

                                            <tr

                                                key={user.id}

                                                className="bg-white  hover:bg-slate-100"

                                            >
                                                <td className="p-3  pl-10 text-base text-gray-700 whitespace-nowrap">
                                                    <div className='w-12 '>
                                                        <img src={user.logo} alt="..." className='' />
                                                    </div>


                                                </td>
                                                <td className="p-3  MessageLine text-base text-gray-700 whitespace-nowrap">

                                                    {user.account_no}
                                                </td>

                                                <td className="p-3  text-base text-gray-700 whitespace-nowrap">
                                                    {user.person_name}

                                                </td>
                                                <td className="p-3  text-base text-gray-700 whitespace-nowrap">

                                                    {user.bank_name}

                                                </td>
                                                <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                                                    <div onClick={() => {
                                                        navigate("/PaymentDetailsDetails");
                                                        cookies.set("PaymentId", user.id);
                                                    }}
                                                        className='bg-slate-100   px-2 py-2 rounded-lg text-blue-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer '> View
                                                        Details
                                                    </div>

                                                </td>
                                                <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                                                    <div className='flex justify-center'><MdDelete onClick={() => {
                                                        deleteMessage(user.id);
                                                    }}
                                                        className='text-2xl text-red-500 hover:text-3xl duration-300 cursor-pointer' /></div>

                                                </td>
                                            </tr>

                                        )
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <EmptyState
                        btn="Add New Payment"
                        img={noProfile}
                        title="No Payment Details"
                        message="There are currently no payment details associated with this account. Add or update your payment information to proceed with transactions."
                        btnLink="/AddPaymentDetails"
                    />

                )}


            </div>
            <div className='text-lg font-semibold mb-4 mx-4 '>Wallet :</div>
            <div className='overflow-auto mx-4 rounded-lg   shadow  mb-20'>

                {data.length ? (
                    <div>

                        <table className="w-full ">
                            <thead className="bg-slate-200 border-b-2 border-gray-200">
                                <tr className='text-blue-700 '>
                                    <th className="w-10 p-3 pl-10 text-lg font-semibold tracking-wide text-center">

                                    </th>
                                    <th className="w-40 p-3 text-lg font-semibold tracking-wide text-center">
                                        Account Number
                                    </th>

                                    <th className="w-40 p-3 text-lg font-semibold tracking-wide text-center">
                                        Account Holder Name
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Bank Name
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Details
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Delete
                                    </th>

                                </tr>
                            </thead>


                            <tbody className="divide-y divide-gray-300 text-center">
                                {data.map((user) => {

                                    if (user.detail_type === "Wallet") {

                                        return (

                                            <tr

                                                key={user.id}

                                                className="bg-white  hover:bg-slate-100"

                                            >
                                                <td className="p-3  pl-10 text-base text-gray-700 whitespace-nowrap">
                                                    <div className='w-12 '>
                                                        <img src={user.logo} alt="..." className='' />
                                                    </div>


                                                </td>
                                                <td className="p-3  MessageLine text-base text-gray-700 whitespace-nowrap">

                                                    {user.account_no}
                                                </td>

                                                <td className="p-3  text-base text-gray-700 whitespace-nowrap">
                                                    {user.person_name}

                                                </td>
                                                <td className="p-3  text-base text-gray-700 whitespace-nowrap">

                                                    {user.bank_name}

                                                </td>
                                                <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                                                    <div onClick={() => {
                                                        navigate("/PaymentDetailsDetails");
                                                        cookies.set("BillDesc", user.bill_description);
                                                        cookies.set("BillNotes", user.note);
                                                        cookies.set("BillAmount", user.amount);
                                                        cookies.set("BillId", user.id);
                                                    }}
                                                        className='bg-slate-100   px-2 py-2 rounded-lg text-blue-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer '> View
                                                        Details
                                                    </div>

                                                </td>
                                                <td className="p-3 text-center  text-base text-gray-700 whitespace-nowrap">
                                                    <div className='flex justify-center'><MdDelete onClick={() => {
                                                        deleteMessage(user.id);
                                                    }}
                                                        className='text-2xl text-red-500 hover:text-3xl duration-300 cursor-pointer' /></div>

                                                </td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <EmptyState
                        btn="Add New Payment"
                        img={noProfile}
                        title="No Payment Details"
                        message="There are currently no payment details associated with this account. Add or update your payment information to proceed with transactions."
                        btnLink="/AddPaymentDetails"
                    />

                )}


            </div>

        </>
    )
}

export default PaymentDetails