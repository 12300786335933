import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {useNavigate, useLocation} from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Disclosure, Transition} from '@headlessui/react';
import logo from "../../Images/HRI-Logo.png";
import {BsDownload, BsFillFileEarmarkPdfFill} from "react-icons/bs";
import {BsFacebook} from "react-icons/bs";
import {BsLinkedin} from "react-icons/bs";
import {BsGithub} from "react-icons/bs";
import {getRequest} from "../../utils/request";
import {saveAs} from "file-saver";
import {Card, CardContent} from "@mui/material";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


import Cookies from "universal-cookie";
import image from "../images.jpg";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import axios from "axios";
import { toast } from "react-toastify";

const cookies = new Cookies();

const ProfileDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const navigate = useNavigate();

    const [active, setActive] = useState("btn1");
    const [data, setData] = useState([]);


    const getData = () => {
        axios
            .get(
                `hri_admin/profileshare/${id}`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                toast.error(err.message)
            })
    };

    useEffect(() => {
        if (cookies.get("token")) {
            getData();
        } else {
            navigate("/");
        }
    },[]);

    const steps = [
        {
            label: 'Applied',
        },
        {
            label: 'In-Review',
        },
        {
            label: 'Interview',
        },
        {
            label: 'Selected'
        },
    ];

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };


    return (<div className="bg-[#edebee] h-auto overflow-auto">
        <Header/>
        <Sidebar/>
        <div className="px-4 md:px-10 pb-20 pt-8">

            {active === "btn1" ? <div className="h-screen">
                <Card elevation={0}>
                    <CardContent className="md:mx-8 lg:mx-14 my-6 ">
                        <div
                            onClick={() => navigate("/profile-shared")}
                            className="mb-4 text-gray-400 text-lg font-semibold flex items-center space-x-1 button_effect w-28 hover:text-blue-700  px-3 py-2 rounded-lg cursor-pointer"
                        >
                            <ArrowBackIosNewIcon sx={{fontSize: "large"}}/>
                            <span>Back</span>
                        </div>

                        {data.map((info) => (
                            <div key={info.id} className="space-y-5">
                                <div className=" flex justify-between">
                                    <div className="w-32 h-32">
                                        <img className="rounded-full" src={info.user_pic} alt=""/>
                                    </div>
                                    <div className="col-span-5 md:col-span-4 lg:col-span-7 xl:col-span-8">
                                        <div className="text-2xl md:text-4xl mt-2 font-bold">
                                            {info.user_fname} {info.user_lname}
                                        </div>
                                        {/*<p>*/}
                                        {/*    applied to Content Creator*/}
                                        {/*</p>*/}
                                    </div>
                                    <div className="col-span-7 md:col-span-6 lg:col-span-4 xl:col-span-3 space-y-2">
                                        {/*<div className="space-x-4">*/}
                                        {/*    <button*/}
                                        {/*        className="bg-[#F1F1F1] font-semibold py-1 px-3 md:px-4 rounded hover:text-neutral-500 hover:bg-[#F6F6F6]">*/}
                                        {/*        Message*/}
                                        {/*        <ChatRoundedIcon className="text-[#9A9A9A] ml-1"*/}
                                        {/*                         sx={{fontSize: "large"}}/>*/}
                                        {/*    </button>*/}
                                        {/*    <button*/}
                                        {/*        className="bg-[#F1F1F1] font-semibold py-1 px-3 md:px-4 rounded hover:text-neutral-500 hover:bg-[#F6F6F6]">*/}
                                        {/*        Mail*/}
                                        {/*        <MailOutlineRoundedIcon className="text-[#9A9A9A] ml-1"*/}
                                        {/*                                sx={{fontSize: "large"}}/>*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}
                                        {/* <button
                                                className="col-span-3 text-lg font-semibold hover:text-sky-600 hover:bg-[#F6F6F6] text-[#0865B6] bg-[#F1F1F1] py-2 px-16 rounded"
                                                onClick={() => {
                                                    setActive("btn2");
                                                    // cookies.set("profileSharedByAdminID", user.id)
                                                }}
                                            >
                                                View Details
                                            </button> */}
                                    </div>
                                </div>

                                {/*<p className="text-[#A9A9A9]">*/}
                                {/*    Shared on: 21/5/2022*/}
                                {/*</p>*/}

                                <div>
                                    <p className="text-lg">
                                        Shared with Companies {info.application_status}
                                    </p>
                                    <div className="flex flex-row gap-4 mt-3">
                                        <img className="rounded-full h-12 w-12" src={info.business_logo}
                                             alt="not available"/>
                                        <p className="mt-2 text-xl">
                                            {info.company_name[0].name}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <Box sx={{maxWidth: 400}} className="py-2 pl-5">

                                        <p className=" px-2 py-4 text-[#FFAB00] lg:text-2xl">Status: </p>

                                        <Stepper
                                            activeStep={info.application_status === "Applied" ? 0 : info.application_status === "In-Review" ? 1 : info.application_status === "Interview" ? 2 : 3}
                                            orientation="vertical">

                                            {steps.map((step, index) => (
                                                <Step key={step.label}>
                                                    <StepLabel>
                                                        {step.label}
                                                    </StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>

                                        {/*{activeStep === steps.length && (*/}
                                        {/*    <Paper square elevation={0} sx={{p: 3}}>*/}
                                        {/*        <Typography>All steps completed <br/> Congratulations you&apos;re*/}
                                        {/*            selected</Typography>*/}
                                        {/*        <Button onClick={handleReset} sx={{mt: 1, mr: 1}}>*/}
                                        {/*            Reset*/}
                                        {/*        </Button>*/}
                                        {/*    </Paper>*/}
                                        {/*)}*/}

                                    </Box>
                                </div>
                            </div>
                        ))}


                    </CardContent>
                </Card>
            </div> : <></>}

            {active === "btn2" ? <div>
                <Card elevation={0} className="shadow">
                    <CardContent>
                        <div className="rounded-lg">
                            <div
                                onClick={() => setActive("btn1")}
                                className="text-gray-400 text-lg font-semibold flex items-center space-x-1 button_effect w-28 hover:text-blue-700  px-3 py-2 rounded-lg cursor-pointer"
                            >
                                <ArrowBackIosNewIcon style={{height: 20}}/>
                                <span>Back</span>
                            </div>
                            <div>
                                {data.map((user) => {
                                    const date = new Date(user.user_dob);

                                    const month = date.toLocaleString("default", {
                                        month: "long",
                                    });
                                    return (
                                        <div key={user.id}>
                                        <div className="md:flex  mx-4 md:mx-24 mt-5">
                                            <div className="flex justify-center ">
                                                <div className="w-20 h-20 rounded-full">
                                                    <img
                                                        className="rounded-full w-20 h-20 "
                                                        src={user.user_pic}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="text-xl justify-center font-semibold ml-4 md:ml-6 flex mt-4">
                                                {user.user_fname}&nbsp;{user.user_lname}{" "}
                                                <ContentCopyIcon className="text-blue-700 ml-2 mt-1"/>
                                            </div>
                                        </div>

                                        <div
                                            className="grid  md:text-left md:grid-cols-2 md:mx-24 gap-y-4 md:gap-x-10 lg:gap-x-5 mt-7">

                                            <div className="text-gray-400 font-semibold">
                                                Role Applied{" "}
                                                <div className="text-black">{user.job_name} </div>
                                            </div>

                                            <div className="text-gray-400 font-semibold">
                                                Marital Status{" "}
                                                <div className="text-black">
                                                    {user.user_martial_status}{" "}
                                                </div>
                                            </div>
                                            <div className="text-gray-400 font-semibold">
                                                Gender{" "}
                                                <div className="text-black">{user.user_gender} </div>
                                            </div>
                                            <div className="text-gray-400 font-semibold">
                                                Permanent Address{" "}
                                                <div className="text-black">
                                                    {user.user_address},&nbsp;{user.user_city},&nbsp;
                                                    {user.user_country}{" "}
                                                </div>
                                            </div>
                                            <div className="text-gray-400 font-semibold">
                                                Languages{" "}
                                                <div className="text-black">English, Hindi</div>
                                            </div>
                                            <div className="text-gray-400 font-semibold">
                                                Present Address{" "}
                                                <div className="text-black">
                                                    {user.user_present_address}{" "}
                                                </div>
                                            </div>
                                            <div className="text-gray-400 font-semibold">
                                                Date of Birth{" "}
                                                <div className="text-black">
                                                    {date.getDate()}&nbsp;
                                                    {month.slice(0, 3)}&nbsp;
                                                    {date.getFullYear()}
                                                </div>
                                            </div>
                                            <div className="text-gray-400 font-semibold">
                                                Country of Citizenship{" "}
                                                <div className="text-black">{user.user_country} </div>
                                            </div>
                                        </div>

                                        <div className="border-b-2 mt-10 border-gray-500"/>

                                        <div className="text-xl font-semibold text-center md:text-left md:mx-24 mt-10">
                                            Work Experience
                                        </div>

                                        <div className=" md:text-left md:grid grid-cols-2 md:ml-24 gap-y-10 mt-8 ">
                                            {user.user_work_exp.map((user) => {
                                                const start = new Date(user.start_date);

                                                const month1 = start.toLocaleString("default", {
                                                    month: "long",
                                                });
                                                const end = new Date(user.end_date);

                                                const month2 = end.toLocaleString("default", {
                                                    month: "long",
                                                });
                                                return (<div key={user.id} className=" mt-3 ">
                                                    <div className="text-lg font-semibold">
                                                        {user.position}{" "}
                                                    </div>
                                                    <div className="justify-center md:justify-start  flex">
                                                        <div className="font-semibold">From:</div>
                                                        &nbsp;{start.getDate()}&nbsp;
                                                        {month1.slice(0, 3)}&nbsp;
                                                        {start.getFullYear()}
                                                    </div>
                                                    <div className="justify-center md:justify-start flex  ">
                                                        <div className="font-semibold">To:</div>
                                                        &nbsp;{" "}
                                                        {end.getDate()}&nbsp;
                                                        {month2.slice(0, 3)}&nbsp;
                                                        {end.getFullYear()}
                                                    </div>
                                                    <div className=" justify-center md:justify-start flex">
                                                        <div className="font-semibold ">
                                                            {user.company_name}{" "}
                                                        </div>
                                                        <div className="text-gray-400">
                                                            &nbsp; - {user.city}
                                                        </div>
                                                        <div className="text-gray-400">
                                                            {" "}
                                                            , {user.country}
                                                        </div>
                                                    </div>
                                                    <div className="text-gray-400">
                                                        {/* {!view ? (
                                                              <div className="">
                                                                {user.description.slice(0, 50)}...
                                                                <div
                                                                  onClick={() => setView(!view)}
                                                                  className="text-blue-700 cursor-pointer"
                                                                >
                                                                  view more
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <div className="">
                                                               ...
                                                                <div
                                                                  onClick={() => setView(!view)}
                                                                  className="text-blue-700 cursor-pointer"
                                                                >
                                                                  view less
                                                                </div>
                                                              </div>
                                                            )} */}
                                                        <Disclosure>
                                                            {({open}) => (<>
                                                                <Disclosure.Button
                                                                    className='text-regular flex w-full justify-between rounded-lg py-2  text-right font-medium text-[#4f46e5] underline '>
                                                                    <span>Read More</span>
                                                                </Disclosure.Button>
                                                                <Transition
                                                                    leave='transition duration-100 ease-out'
                                                                    leaveFrom='transform scale-100 opacity-100'
                                                                    leaveTo='transform scale-100 opacity-0'
                                                                >
                                                                    <Disclosure.Panel
                                                                        className='px-2  pb-2 text-sm text-gray-500'>
                                                                        {user.description}
                                                                    </Disclosure.Panel>
                                                                </Transition>
                                                            </>)}
                                                        </Disclosure>
                                                    </div>
                                                </div>);
                                            })}
                                        </div>

                                        <div className="border-b-2 mt-10 border-gray-500"/>

                                        <div
                                            className="text-xl font-semibold text-center md:text-left md:mx-24 mt-10">
                                            Skills / Strengths
                                        </div>
                                        <div className="md:grid grid-cols-2 ">
                                            {user.user_skills.map((user) => {
                                                return (<div key={user.id} className="ml-1 md:ml-24 mt-8">
                                                    <div className="text-lg font-semibold">
                                                        {user.skills}
                                                    </div>
                                                    <div
                                                        className="w-full mt-2 bg-gray-200 h-4 rounded-full mb-6">
                                                        <div
                                                            className="bg-[#0865B6] h-4 rounded-full"
                                                            style={{width: `${user.rating}0%`}}
                                                        ></div>
                                                    </div>
                                                </div>);
                                            })}
                                        </div>

                                        <div className="border-b-2 mt-10 border-gray-500"/>

                                        <div
                                            className="text-xl font-semibold text-center md:text-left md:mx-24 mt-10">
                                            Education / Certifications
                                        </div>
                                        <div className="md:grid grid-cols-2 ">
                                            {user.education_certificate.map((user) => {

                                                return (<div key={user.id} data-bs-toggle="modal"
                                                             data-bs-target="#exampleModalScrollable"
                                                             className="ml-1 cursor-pointer md:ml-24 mt-8">
                                                    <div
                                                        className="modal fade fixed top-0 left-0 hidden w-full  outline-none overflow-x-hidden overflow-y-auto"
                                                        id="exampleModalScrollable"
                                                        tabIndex="-1"
                                                        aria-labelledby="exampleModalScrollableLabel"
                                                        aria-hidden="true"
                                                    >
                                                        <div
                                                            className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                                                            <div
                                                                className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                                                                <div
                                                                    className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                                                    <h5
                                                                        className="text-xl font-medium leading-normal text-gray-800"
                                                                        id="exampleModalScrollableLabel"
                                                                    >
                                                                        {user.degree_name} certificate.pdf
                                                                    </h5>
                                                                </div>
                                                                <div className="modal-body relative p-4">
                                                                    <iframe src={user.certificate}
                                                                            width="100%"
                                                                            className="h-96">

                                                                    </iframe>
                                                                </div>
                                                                <div
                                                                    className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                                                    <button
                                                                        type="button"
                                                                        className="inline-block px-6 py-2.5 bg-black text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                                                        data-bs-dismiss="modal"
                                                                    >
                                                                        Close
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-lg font-semibold">
                                                        {user.degree_name}
                                                    </div>
                                                    <div className="flex">
                                                        <div className="font-semibold ">
                                                            {user.university_name}{" "}
                                                        </div>
                                                        <div className="text-gray-400">
                                                            &nbsp; - {user.city}
                                                        </div>
                                                        <div className="text-gray-400">
                                                            , {user.country}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="bg-slate-100  md:w-80 flex justify-center rounded py-10 mt-2">
                                                        {" "}
                                                        <BsDownload
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModalScrollable"
                                                            className="absolute cursor-pointer ml-24 md:ml-32 mt-[-20px] text-blue-800 text-xl text-semibold"
                                                        />
                                                        <div className="text-center">
                                                            <BsFillFileEarmarkPdfFill
                                                                className="text-blue-600  ml-12 md:ml-12 mb-2  text-6xl"/>{" "}
                                                            <div className="text-center  text-blue-500">
                                                                {user.degree_name} certificate.pdf
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>);
                                            })}
                                        </div>

                                        <div className="border-b-2 mt-10 border-gray-500"/>

                                        <div
                                            className="text-lg text-gray-400 font-semibold text-center md:text-left md:mx-24 mt-10">
                                            Social Media
                                        </div>
                                        <div
                                            className="md:ml-24  mt-8 flex justify-center md:justify-start font-semibold space-x-7 text-4xl">
                                            <BsFacebook className="text-blue-600"/>
                                            <BsLinkedin className="text-blue-700"/>
                                            <BsGithub/>
                                        </div>

                                        <div className="flex rounded px-4 md:px-10 py-2 mt-6 bg-slate-100">
                                            <div className=" md:my-3">By</div>
                                            <img
                                                className="w-12 h-10 mx-3 mt-2 md:mt-1"
                                                src={logo}
                                                alt=""
                                            />
                                            <div>
                                                <p className="text-xl">Infinity HR Consultants</p>
                                                <div className="text-gray-500">
                                                    Filter diamonds on road
                                                </div>
                                            </div>
                                        </div>
                                    </div>);
                                })}
                            </div>
                        </div>

                    </CardContent>
                </Card>
            </div> : <></>}

        </div>
    </div>)
}

export default ProfileDetails;