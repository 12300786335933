/**
 * @typedef {object} SurveyResultModel
 * @property {number} id
 * @property {string} question
 * @property {string} answer
 * @property {"TextFiled" | "ChoiceField" | "File" | "Boolean" | "Video" | "Image" } questionType
 * @property {string} questionMedia 
 * @property {string} options
 * @property {boolean} required
 */


export function createSurveyResultObj(data) {

    /**
     * @type {SurveyResultModel}
     */
    const result = {
        id: data.id,
        question: data.question,
        answer: data.answer,
        questionType: data.question_type,
        questionMedia: data.question_media ? data.question_media : "",
        options: data.options !== null ? data.options : "",
        required: data.required
    }
    return result
}