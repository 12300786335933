import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {Routes, Route, useNavigate} from "react-router-dom";
import AddQA from "./Category/AddQ&A";
import AddTutorial from "./Tutorial/AddTutorial";
import EditCategory from "./Category/EditCategory";
import EditTutorial from "./Tutorial/EditTutorial";
import Category from "./Category/Category";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import Cookies from "universal-cookie";
import Tutorial from "./Tutorial/Tutorial";
import FAQ from "./FAQ/FAQ";
import FaqAdd from "./FAQ/FaqAdd";
import {IconButton} from "@mui/material";
import FaqEdit from "./FAQ/FaqEdit";
import AddCategory from "./Category/AddCategory";

const cookies = new Cookies();

const HelpAndFAQ = () => {

    const navigate = useNavigate();
    // const [active, setActive] = useState("Category");

    useEffect(() => {
        if (cookies.get("token")) {

        } else {
            navigate("/");
        }
    },[]);
    const [type, setType] = useState("Category")

    return (
        <div className="bg-default-background h-screen overflow-auto">
            <Header/>
            <Sidebar/>

            {/*<div className="flex flex-row text-[#0865B6] text-lg font-bold py-1 px-2">*/}
            {/*    <p className="cursor-pointer" onClick={() => navigate("/help")}>*/}
            {/*        Help Desk {" "} /*/}
            {/*    </p>*/}

            {/*    <p className="cursor-pointer">*/}
            {/*        {" "}*/}
            {/*        User*/}
            {/*        {type === "Category" ? " Category" : ""}*/}
            {/*        {type === "FAQ" ? " FAQ's" : ""}*/}
            {/*        {type === "Tutorials" ? " Tutorials" : ""}*/}
            {/*    </p>*/}
            {/*</div>*/}

            {/* Header */}
            <div className="items-center justify-between flex bg-white px-5 py-1.5 m-2 rounded-md z-10">
                <div className="space-x-10">
                    <span>
                        <IconButton onClick={() => navigate(-1)}>
                            <ArrowBackIosNewIcon sx={{fontSize: "large"}}/>
                        </IconButton>
                    </span>
                    <span
                        id="Category"
                        className={`font-semibold hover:bg-default-gray px-4 py-1.5 rounded-md duration-300 cursor-pointer text-lg
                        ${window.location.pathname === "/help/user/category" && "bg-default-gray text-default-blue"} 
                        ${window.location.pathname === "/help/company/category" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/team/category" && "bg-default-gray text-default-blue"}
                        
                        ${window.location.pathname === "/help/user/category/add-qa" && "bg-default-gray text-default-blue"} 
                        ${window.location.pathname === "/help/company/category/add-qa" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/team/category/add-qa" && "bg-default-gray text-default-blue"}
                        
                        ${window.location.pathname === "/help/user/category/add-category" && "bg-default-gray text-default-blue"} 
                        ${window.location.pathname === "/help/company/category/add-category" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/team/category/add-category" && "bg-default-gray text-default-blue"}
                        
                        ${window.location.pathname === "/help/user/category/edit" && "bg-default-gray text-default-blue"} 
                        ${window.location.pathname === "/help/company/category/edit" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/team/category/edit" && "bg-default-gray text-default-blue"}`}
                        onClick={(e) => {
                            // setActive(e.currentTarget.id);
                            setType("Category");
                            navigate(`/help/${localStorage.getItem("help-type")}/category`);
                        }}
                    >
                        Category
                    </span>
                    <span
                        id="FAQ"
                        className={`font-semibold hover:bg-default-gray px-4 py-1.5 rounded-md duration-300 cursor-pointer text-lg
                        ${window.location.pathname === "/help/user/faq" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/company/faq" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/team/faq" && "bg-default-gray text-default-blue"}
                        
                        ${window.location.pathname === "/help/user/faq/edit" && "bg-default-gray text-default-blue"} 
                        ${window.location.pathname === "/help/company/faq/edit" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/team/faq/edit" && "bg-default-gray text-default-blue"}
                        
                        ${window.location.pathname === "/help/user/faq/add" && "bg-default-gray text-default-blue"} 
                        ${window.location.pathname === "/help/company/faq/add" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/team/faq/add" && "bg-default-gray text-default-blue"}`}
                        onClick={(e) => {
                            // setActive(e.currentTarget.id);
                            setType("FAQ");
                            navigate(`/help/${localStorage.getItem("help-type")}/faq`);
                        }}
                    >
                        FAQ’s
                    </span>
                    <span
                        id="Tutorials"
                        className={`font-semibold hover:bg-default-gray px-4 py-1.5 rounded-md duration-300 cursor-pointer text-lg
                        ${window.location.pathname === "/help/user/tutorial" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/company/tutorial" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/team/tutorial" && "bg-default-gray text-default-blue"} 
                        
                        ${window.location.pathname === "/help/user/tutorial/edit" && "bg-default-gray text-default-blue"} 
                        ${window.location.pathname === "/help/company/tutorial/edit" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/team/tutorial/edit" && "bg-default-gray text-default-blue"}
                        
                        ${window.location.pathname === "/help/user/tutorial/add" && "bg-default-gray text-default-blue"} 
                        ${window.location.pathname === "/help/company/tutorial/add" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/team/tutorial/add" && "bg-default-gray text-default-blue"}`}
                        onClick={(e) => {
                            // setActive(e.currentTarget.id);
                            setType("Tutorials");
                            navigate(`/help/${localStorage.getItem("help-type")}/tutorial`)
                        }}
                    >
                        Tutorials
                    </span>
                </div>
                {type === "Category" ? <div
                    className={`bg-[#0865B6] text-white font-semibold rounded-md hover:opacity-60 duration-300 active:opacity-80
                        ${window.location.pathname === "/help/user/category/add-qa" && "bg-default-gray text-neutral-400"} 
                        ${window.location.pathname === "/help/company/category" && "bg-default-gray text-default-blue"}
                        ${window.location.pathname === "/help/team/category" && "bg-default-gray text-default-blue"}`}
                    onClick={() => navigate(`/help/${localStorage.getItem("help-type")}/category/add-qa`)}>
                    <button className="px-4 py-2">
                        + Add New Q & A
                    </button>
                </div> : <></>}

                {type === "FAQ" ? <div
                    className="bg-[#0865B6] text-white font-semibold rounded-md hover:opacity-60 duration-300 active:opacity-80"
                    onClick={() => navigate(`/help/${localStorage.getItem("help-type")}/faq/add`)}>
                    <button className="px-4 py-2">
                        + Add New FAQ
                    </button>
                </div> : <></>}

                {type === "Tutorials" ? <div
                    className="bg-[#0865B6] text-white font-semibold rounded-md hover:opacity-60 duration-300 active:opacity-80"
                    onClick={() => navigate(`/help/${localStorage.getItem("help-type")}/tutorial/add`)}>
                    <button className="px-4 py-2">
                        + Add New Tutorials
                    </button>
                </div> : <></>}
            </div>

            <Routes>
                {/*<Route path=":type/category" element={<Help/>}/>*/}
                <Route path={`${localStorage.getItem("help-type")}/category`} element={<Category/>}/>
                <Route path={`${localStorage.getItem("help-type")}/category/add-qa`} element={<AddQA/>}/>
                <Route path={`${localStorage.getItem("help-type")}/category/add-category`} element={<AddCategory/>}/>
                <Route path={`${localStorage.getItem("help-type")}/category/edit`} element={<EditCategory/>}/>

                <Route path={`${localStorage.getItem("help-type")}/faq`} element={<FAQ/>}/>
                <Route path={`${localStorage.getItem("help-type")}/faq/add`} element={<FaqAdd/>}/>
                <Route path={`${localStorage.getItem("help-type")}/faq/edit`} element={<FaqEdit/>}/>

                <Route path={`${localStorage.getItem("help-type")}/tutorial`} element={<Tutorial/>}/>
                <Route path={`${localStorage.getItem("help-type")}/tutorial/add`} element={<AddTutorial/>}/>
                <Route path={`${localStorage.getItem("help-type")}/tutorial/edit`} element={<EditTutorial/>}/>
            </Routes>

        </div>
    )
}

export default HelpAndFAQ;