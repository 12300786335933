import React, {useEffect} from "react";
import {Card, CardContent} from "@mui/material";
import profilePic from "../../Profile/profile.jpeg";
import Switch from '@mui/material/Switch';
import {useNavigate} from "react-router-dom";


import Cookies from "universal-cookie";

const cookies = new Cookies();

const TestimonialPreview = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (cookies.get("token")) {

        } else {
            navigate("/");
        }
    },[]);

    const label = {inputProps: {'aria-label': 'Switch demo'}};


    return (
        <div className="px-4 md:px-10 py-4 space-y-5">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Card elevation={0} className="drop-shadow">
                    <CardContent>
                        <div className="flex flex-col md:flex-row space-x-5">
                            <div className="flex gap-3">
                                <img className="h-10 w-10 rounded-full" src={profilePic} alt=""/>
                                <div className="flex-grow">
                                    <p className="text-[#5F5F5F] text-xl font-bold">
                                        Certificate A
                                    </p>
                                </div>
                            </div>
                            <Switch {...label} />
                        </div>

                        <div className="flex flex-col md:flex-row space-x-5">
                            <p className="text-xl font-semibold">
                                Designation
                            </p>
                            <p>
                                Green Society of India
                            </p>
                        </div>

                        <div className="flex flex-col md:flex-row space-x-5">
                            <p className="text-xl font-semibold">
                                Testimonial
                            </p>
                            <p>
                                The rising demand for dark stores from e-commerce players in city
                                central locations is prompting growth of urban logistics spaces.
                                While urban logistics is at a nascent and fragmented
                            </p>
                        </div>
                    </CardContent>
                </Card>

                <Card elevation={0} className="drop-shadow">
                    <CardContent>
                        <div className="flex flex-col md:flex-row space-x-5">
                            <div className="flex gap-3">
                                <img className="h-10 w-10 rounded-full" src={profilePic} alt=""/>
                                <div className="flex-grow">
                                    <p className="text-[#5F5F5F] text-xl font-bold">
                                        Certificate A
                                    </p>
                                </div>
                            </div>
                            <Switch {...label} />
                        </div>

                        <div className="flex flex-col md:flex-row space-x-5">
                            <p className="text-xl font-semibold">
                                Designation
                            </p>
                            <p>
                                Green Society of India
                            </p>
                        </div>

                        <div className="flex flex-col md:flex-row space-x-5">
                            <p className="text-xl font-semibold">
                                Testimonial
                            </p>
                            <p>
                                The rising demand for dark stores from e-commerce players in city
                                central locations is prompting growth of urban logistics spaces.
                                While urban logistics is at a nascent and fragmented
                            </p>
                        </div>
                    </CardContent>
                </Card>

                <Card elevation={0} className="drop-shadow">
                    <CardContent>
                        <div className="flex flex-col md:flex-row space-x-5">
                            <div className="flex gap-3">
                                <img className="h-10 w-10 rounded-full" src={profilePic} alt=""/>
                                <div className="flex-grow">
                                    <p className="text-[#5F5F5F] text-xl font-bold">
                                        Certificate A
                                    </p>
                                </div>
                            </div>
                            <Switch {...label} />
                        </div>

                        <div className="flex flex-col md:flex-row space-x-5">
                            <p className="text-xl font-semibold">
                                Designation
                            </p>
                            <p>
                                Green Society of India
                            </p>
                        </div>

                        <div className="flex flex-col md:flex-row space-x-5">
                            <p className="text-xl font-semibold">
                                Testimonial
                            </p>
                            <p>
                                The rising demand for dark stores from e-commerce players in city
                                central locations is prompting growth of urban logistics spaces.
                                While urban logistics is at a nascent and fragmented
                            </p>
                        </div>
                    </CardContent>
                </Card>

                <Card elevation={0} className="drop-shadow">
                    <CardContent>
                        <div className="flex flex-col md:flex-row space-x-5">
                            <div className="flex gap-3">
                                <img className="h-10 w-10 rounded-full" src={profilePic} alt=""/>
                                <div className="flex-grow">
                                    <p className="text-[#5F5F5F] text-xl font-bold">
                                        Certificate A
                                    </p>
                                </div>
                            </div>
                            <Switch {...label} />
                        </div>

                        <div className="flex flex-col md:flex-row space-x-5">
                            <p className="text-xl font-semibold">
                                Designation
                            </p>
                            <p>
                                Green Society of India
                            </p>
                        </div>

                        <div className="flex flex-col md:flex-row space-x-5">
                            <p className="text-xl font-semibold">
                                Testimonial
                            </p>
                            <p>
                                The rising demand for dark stores from e-commerce players in city
                                central locations is prompting growth of urban logistics spaces.
                                While urban logistics is at a nascent and fragmented
                            </p>
                        </div>
                    </CardContent>
                </Card>

            </div>
        </div>
    )
}

export default TestimonialPreview;