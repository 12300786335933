import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import EventIcon from "@mui/icons-material/Event";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { toast } from "react-toastify";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { getRequest, postRequest, updateRequest } from "../utils/request";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import moment from "moment";
import axios from "axios";
import Cookies from "universal-cookie";
import { MobileTimePicker } from "@mui/lab";
import { isValidMeeting } from "../helpers/validate_meeting";

const cookies = new Cookies();

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "scroll",
    // width: 800,
    // height: 500,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
};

const CalendarModal = ({ open, handleClose, id }) => {
    const [active, setActive] = React.useState("");

    const [title, setTitle] = React.useState("");
    const [tagU, setTagU] = React.useState("");
    const [tagC, setTagC] = React.useState("");
    const [tagT, setTagT] = React.useState("");
    const [date, setDate] = React.useState(null);
    const [start_time, setStartTime] = React.useState(null);
    const [duration, setDuration] = React.useState("");
    const [note, setNote] = React.useState("");
    const [link, setLink] = React.useState("");
    const [password, setPassword] = React.useState("");

    const newDate = moment(date).format("YYYY-MM-DD");

    const newStartTimeHour = moment(start_time).format("h");
    const newStartTimeMinutes = moment(start_time).format("mm");
    const newStartTimeVal = moment(start_time).format("A");

    const [user, setUser] = React.useState([]);
    const [company, setCompany] = React.useState([]);
    const [team, setTeam] = React.useState([]);

    const [meeting, setMeeting] = React.useState([]);

    const timeData = [
        {
            id: 1,
            time: "10 min",
            value: 10,
        },
        {
            id: 2,
            time: "30 min",
            value: 30,
        },
        {
            id: 3,
            time: "45 min",
            value: 45,
        },
        {
            id: 4,
            time: "60 min",
            value: 60,
        },
    ];

    const getUserEmails = () => {
        axios
            .get(`hri_admin/username`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(res => {
                console.log("User Email: ", res.data);
                setUser(res.data);
            })
            .catch(err => {
                console.log("Error getting User Emails");
            });
    };

    const getCompantEmails = () => {
        axios
            .get(`hri_admin/companyemail`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(res => {
                console.log("Company Email: ", res.data);
                setCompany(res.data);
            })
            .catch(err => {
                console.log("Error getting Company Emails");
            });
    };

    const getTeamEmails = () => {
        axios
            .get(`hri_admin/teamemail`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(res => {
                setTeam(res.data);
            })
            .catch(err => {
                console.log("Error getting Team Emails");
            });
    };

    const newData = user.concat(company);

    const handleCopyClick = () => {
        if (link !== "") {
            toast.success("Link Copied Successfully");
        } else {
            toast.warning("Enter the link to copy");
        }
        navigator.clipboard.writeText(link);
        document.getSelection().removeAllRanges();
    };

    const getMeetings = () => {
        axios
            .get(`hri_admin/meeting/${id}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(res => {
                console.log(
                    "response from submitting the form successful",
                    res.data
                );
                setMeeting(res?.data);
                setTitle(res?.data[0]?.title);
                setTagU(res?.data[0]?.user);
                setTagC(res?.data[0]?.user);
                setDate(res?.data[0]?.date);
                setStartTime(
                    `${moment(meeting[0]?.date).format("ll")} ${moment(
                        meeting[0]?.start_time
                    ).format("h:mm:ss")}`
                );
                setDuration(res?.data[0]?.duration);
                setActive(res?.data[0]?.duration);
                setNote(res?.data[0]?.note);
                setLink(res?.data[0]?.meeting_link);
                setPassword(
                    res?.data[0]?.meeting_password === "null"
                        ? "not available"
                        : res?.data[0]?.meeting_password
                );
            })
            .catch(err => {
                console.log("ERROR  from update in form", err);
                // toast.error("Some error occurred");
            });
    };

    const createMeet = () => {
        const submitData = {
            title: title,
            date: newDate,
            start_time:
                newStartTimeVal === "PM"
                    ? `${
                          parseInt(newStartTimeHour) + 12
                      }:${newStartTimeMinutes}`
                    : `${newStartTimeHour}:${newStartTimeMinutes}`,
            duration: duration,
            note: note,
            meeting_link: link,
            // cancel: false,
            // cancel_reason: "",
            meeting_password: password,
            user: tagU,
            company: tagC,
        };
        const error = isValidMeeting(submitData);
        if (error) return toast.error(error);

        postRequest(
            "hri_admin/meeting/create",
            submitData,
            window.location.href
        );
    };

    const updateMeeting = () => {
        const formField = new FormData();
        formField.append("title", title);
        formField.append("date", newDate);
        formField.append(
            "start_time",
            newStartTimeVal === "PM"
                ? `${parseInt(newStartTimeHour) + 12}:${newStartTimeMinutes}`
                : `${newStartTimeHour}:${newStartTimeMinutes}`
        );
        formField.append("duration", duration);
        formField.append("note", note);
        formField.append("meeting_link", link);
        // formField.append("cancel", false);
        // formField.append("cancel_reason", "");
        formField.append("meeting_password", password);
        formField.append("user", tagC);
        formField.append("company", tagU);
        updateRequest(
            `hri_admin/meeting/update/${id}`,
            formField,
            window.location.href
        );
    };

    useEffect(() => {
        getUserEmails();
        getCompantEmails();
        getTeamEmails();
        if (id) {
            getMeetings();
        }
    }, [id]);

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                // onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box
                        sx={style}
                        className="w-[300px] sm:w-[500px] md:w-[700px] lg:w-[800px] h-[500px]"
                    >
                        <div className="bg-white flex items-center justify-end">
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className="space-y-5">
                            <div className="space-y-2">
                                <span className="text-gray-400 text-2xl font-semibold">
                                    Add subject of the meet
                                </span>
                                <TextField
                                    id="standard-basic"
                                    variant="standard"
                                    className="w-full text-2xl"
                                    placeholder="Add subject of the meet"
                                    size="large"
                                    value={title}
                                    onChange={e => {
                                        setTitle(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="flex flex-wrap items-center space-y-3 w-full">
                                {/* <Stack spacing={3} sx={{ width: 1000 }}> */}
                                <Autocomplete
                                    sx={{ width: 1000 }}
                                    spacing={3}
                                    multiple
                                    id="tags-outlined"
                                    options={user}
                                    getOptionLabel={option => option.email}
                                    // defaultValue={[newData[0]]}
                                    onChange={(event, value) =>
                                        console.log(
                                            value.forEach((item, i) => {
                                                setTagU(item.user);
                                            })
                                        )
                                    }
                                    filterSelectedOptions
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={`Tag User`}
                                            placeholder="Tags"
                                        />
                                    )}
                                />
                                <Autocomplete
                                    sx={{ width: 1000 }}
                                    spacing={3}
                                    multiple
                                    id="tags-outlined"
                                    options={company}
                                    getOptionLabel={option => option.email}
                                    // defaultValue={[newData[0]]}
                                    onChange={(event, value) =>
                                        console.log(
                                            value.forEach((item, i) => {
                                                setTagC(item.user_id);
                                            })
                                        )
                                    }
                                    filterSelectedOptions
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={`Tag Company`}
                                            placeholder="Tags"
                                        />
                                    )}
                                />
                                <Autocomplete
                                    sx={{ width: 1000 }}
                                    spacing={3}
                                    multiple
                                    id="tags-outlined"
                                    options={team}
                                    getOptionLabel={option => option.email}
                                    // defaultValue={[newData[0]]}
                                    onChange={(event, value) =>
                                        console.log(
                                            value.forEach((item, i) => {
                                                setTagT(item.user);
                                            })
                                        )
                                    }
                                    filterSelectedOptions
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={`Tag Team`}
                                            placeholder="Tags"
                                        />
                                    )}
                                />
                                {/* </Stack> */}
                            </div>
                            <div className="grid items-center justify-center grid-cols-1 md:grid-cols-2 gap-3">
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker
                                        label="Select Date"
                                        disablePast
                                        value={date}
                                        onChange={newValue => {
                                            setDate(newValue);
                                        }}
                                        renderInput={params => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="Start time"
                    value={start_time}
                    onChange={(newValue) => {
                      setStartTime(newValue);
                    }}
                    // shouldDisableTime={(timeValue, clockType) => {
                    //     const day = new Date();
                    //     const hrs = day.getHours();
                    //     console.log("time value", timeValue)
                    //     if (clockType === 'hours' && timeValue > hrs) {
                    //         return true;
                    //     }
                    // }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider> */}
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <Stack width="full">
                                        <MobileTimePicker
                                            views={["hours", "minutes"]}
                                            openTo="hours"
                                            label="Start time"
                                            value={start_time}
                                            onChange={newValue => {
                                                setStartTime(newValue);
                                            }}
                                            renderInput={params => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </div>
                            <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                                {timeData.map((item, i) => (
                                    <button
                                        className={`${
                                            active === item.value
                                                ? "bg-gray-300"
                                                : "bg-blue-200"
                                        } rounded-md px-2 py-2 flex items-center justify-center hover:opacity-50 active:opacity-70 duration-300`}
                                        key={item.id}
                                        id={item.value}
                                        onClick={e => {
                                            setDuration(item.value);
                                            setActive(e.currentTarget.id);
                                        }}
                                    >
                                        <span className="">{item.time}</span>
                                    </button>
                                ))}
                            </div>
                            <div className="text-gray-400 font-semibold text-lg">
                                <p>
                                    Selected time duration is:{" "}
                                    <span className="text-black">
                                        {active} minutes
                                    </span>
                                </p>
                            </div>
                            <div className="space-y-3">
                                <div className="flex items-start space-x-2 text-lg font-semibold">
                                    <FormatListBulletedIcon />
                                    <textarea
                                        id="w3review"
                                        name="w3review"
                                        placeholder="Send any note to @username"
                                        rows="4"
                                        cols="50"
                                        className="px-3 py-2 border-2 border-slate-300 outline-none w-full rounded-md"
                                        value={note}
                                        onChange={e => {
                                            setNote(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center space-x-2 text-lg font-semibold">
                                <EventIcon className="text-gray-400" />
                                <input
                                    className="px-3 py-2 border-2 border-slate-300 outline-none w-full rounded-md"
                                    placeholder="Enter meeting link here"
                                    value={link}
                                    onChange={e => {
                                        setLink(e.target.value);
                                    }}
                                />
                                <IconButton aria-label="delete">
                                    <ContentCopyIcon
                                        className="text-gray-400"
                                        onClick={handleCopyClick}
                                    />
                                </IconButton>
                            </div>
                            <div className="flex items-center space-x-2 text-lg font-semibold">
                                <LockOpenIcon className="text-gray-400" />
                                <input
                                    className="px-3 py-2 border-2 border-slate-300 outline-none w-full rounded-md"
                                    placeholder="Enter passcode if any"
                                    value={password}
                                    onChange={e => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className="flex item-center justify-end my-3"
                            onClick={
                                meeting.length ? updateMeeting : createMeet
                            }
                        >
                            <Button
                                variant="contained"
                                endIcon={<DoneAllIcon />}
                            >
                                {meeting.length ? "Update" : "Save"}
                            </Button>
                        </div>
                        <div>
                            <p id="timer"></p>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default CalendarModal;
