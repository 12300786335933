import React, { useEffect, useState } from "react";
// import Header from '../NavBar-Sidebar/Header'
import { useSelector } from "react-redux";
import { selectHeader } from "../../features/HeaderSlice";
import { useNavigate } from "react-router-dom";
// import Spinner from "../Meeting/Spinner";
import { Link } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";

// import {MdArrowBackIos} from "react-icons/md";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

import Cookies from "universal-cookie";
import { Box, Pagination } from "@mui/material";
import NoMessage from "../../Images/NoMessages.png"
import Spinner from "../../components/Spinner";
import { EmptyState } from "../../components/EmptyStates/EmptyState";

const cookies = new Cookies();

const Message = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading , setLoading] = useState(true)
    const [mHeader, setMHeader] = useState("");
    const [mMessage, setMMessage] = useState("");
    const [status, setStatus] = useState("Received");
    const [toDate, setToDate] = useState("3000-1-1");
    const [fromDate, setFromDate] = useState("1900-1-1");
    const [active, setActive] = useState("Received");
    const [totalPages, setTotalPages] = useState(1);

    const from = new Date(fromDate);
    const to = new Date(toDate);

    const currentState = useSelector(selectHeader);

    async function getData(page = 1) {
        await axios
            .get(`hri_admin/message?message_type=${status}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
                params: {
                    page,
                },
            })
            .then(resp => {
                setData(resp.data.results);
                setLoading(false)
                setTotalPages(Math.ceil(resp.data.count / 10));
            })
            .catch(err => {
                console.log(err);
            });
    }

    async function getMessage(id) {
        await axios
            .get(`hri_admin/message/${id}?message_type=${status}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(resp => {
                setMHeader(resp.data.header);
                setMMessage(resp.data.message);
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        if (cookies.get("token")) {
            getData();
        } else {
            navigate("/");
        }
    }, [toDate, fromDate, status, navigate]);

    const all = () => {
        setActive("Received");
        setStatus("Received");
    };
    const unRead = () => {
        setActive("Un-Read");
        setStatus("Unread");
    };
    const sent = () => {
        setActive("Sent");
        setStatus("Sent");
    };

    function handleClear() {
        setFromDate("1900-1-1")
        setToDate("3000-1-1")
    }


    return (
        <div className="bg-default-background h-screen overflow-auto">
            <Header />
            <Sidebar />

            <div className=" bg-white  m-2  lg:flex h-60 md:h-44 lg:h-12 xl:h-12 justify-between ">
                <div className="bg-white space-x-1 flex lg:space-x-4 text-xs md:text-lg xl:text-xl  font-semibold p-1 text-gray-600">
                    {active === "Received" ? (
                        <div
                            onClick={all}
                            className="pt-1 px-4 rounded-lg  bg-slate-100 text-blue-700 cursor-pointer"
                        >
                            Received
                        </div>
                    ) : (
                        <div
                            onClick={all}
                            className="pt-1 px-4 rounded-lg  hover:bg-slate-100 hover:text-blue-700 cursor-pointer"
                        >
                            Received
                        </div>
                    )}

                    {active === "Un-read" ? (
                        <div
                            onClick={unRead}
                            className="pt-1 px-4 rounded-lg  bg-slate-100 text-blue-700 cursor-pointer"
                        >
                            Un-read
                        </div>
                    ) : (
                        <div
                            onClick={unRead}
                            className="pt-1 px-4 rounded-lg  hover:bg-slate-100 hover:text-blue-700 cursor-pointer"
                        >
                         Un-read
                        </div>
                    )}

                    {active === "Sent" ? (
                        <div
                            onClick={sent}
                            className="pt-1 px-4 rounded-lg bg-slate-100 text-blue-700 cursor-pointer"
                        >
                            Sent
                        </div>
                    ) : (
                        <div
                            onClick={sent}
                            className="pt-1 px-4 rounded-lg hover:bg-slate-100 hover:text-blue-700 cursor-pointer"
                        >
                            Sent
                        </div>
                    )}
                </div>

                <div className="md:flex mb-4 ml-4 md:ml-0 lg:mb-0 pt-2 space-y-3 md:space-y-0">
                    <div className="flex">
                        <span className="text-base text-slate-400 mr-2 mt-1 md:ml-10">
                            <p>From :</p>
                        </span>
                        <input
                            value={fromDate}
                            onChange={e => {
                                setFromDate(e.target.value);
                            }}
                            className="h-8 md:mr-10 lg:mr-4 xl:mr-10 w-44 px-7 py-3 bg-white border shadow-sm border-slate-300 placeholder-text-bold placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-2xl sm:text-sm focus:ring-1"
                            type="date"
                            placeholder=""
                        />
                    </div>
                    <div className="flex">
                        <span className="text-base mr-2 mt-1 text-slate-400 ">
                            <p>To :</p>
                        </span>
                        <input
                            value={toDate}
                            onChange={e => {
                                setToDate(e.target.value);
                            }}
                            className="h-8  md:mr-10 lg:mr-4 xl:mr-10  w-44 px-7 py-3 bg-white border shadow-sm border-slate-300 placeholder-text-bold placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-2xl sm:text-sm focus:ring-1"
                            type="date"
                            placeholder=""
                        />
                    </div>
                    <Button
                        sx={{ marginLeft: "1rem", width: "120px", height: "40px" }}
                        variant="outlined"
                        onClick={handleClear}
                    >
                        Clear
                    </Button>
                </div>
                <div className="mb-5 lg:mb-2 xl:mb-0 lg:mt-2 xl:mt-1 ml-4 md:ml-10">
                    <div className="mt-1 mr-2  md:mr-10">
                        <Link to="/send-message">
                            <Button
                                className="w-auto px-4"
                                size="small"
                                variant="contained"
                            >
                                Send Message
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>

            {data.length ? (
                <>
                <div className="pt-4 px-4 md:px-10 w-full ">
                <div className=" ease-in  duration-300  ml-0 ">
                    <div className="overflow-auto rounded-lg shadow ">
                        <table className="w-full">
                            <thead className="bg-slate-200 border-b-2 border-gray-200">
                                <tr className="text-blue-700 ">
                                    <th className="w-20 p-3 pl-10 text-lg font-semibold tracking-wide text-center">
                                        {active === "Sent" ? "Recipient" : "Sender"}
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Message
                                    </th>
                                    <th className="w-10 p-3 text-lg font-semibold tracking-wide text-center">
                                        Date
                                    </th>
                                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                                        Details
                                    </th>
                                </tr>
                            </thead>

                                <tbody className="divide-y divide-gray-100 text-center">
                                    {data.map(user => {
                                        const date = new Date(
                                            user.updated_at.slice(0, 10)
                                        );
                                        const month = date.toLocaleString(
                                            "default",
                                            {
                                                month: "long",
                                            }
                                        );
                                        if (from <= date && date <= to) {
                                            return (
                                                <tr
                                                    key={user.id}
                                                    onClick={() =>
                                                        getMessage(user.id)
                                                    }
                                                    className="bg-white cursor-pointer hover:bg-slate-100"
                                                >
                                                    <td className="p-3  pl-10 text-base text-gray-700 whitespace-nowrap">
                                                        {user.sender_name ? user.sender_name :"NA"}
                                                    </td>
                                                    <td className="p-3  MessageLine text-base text-gray-700 whitespace-nowrap">
                                                        <div className="font-semibold  text-lg">
                                                            {user.header}
                                                        </div>
                                                        {user.message.slice(
                                                            0,
                                                            50
                                                        )}
                                                    </td>
                                                    <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                                                        {date.getDate()}&nbsp;
                                                        {month.slice(0, 3)}
                                                        &nbsp;
                                                        {date.getFullYear()}
                                                    </td>
                                                    <td className="p-3 text-base text-gray-700 whitespace-nowrap flex flex-row justify-center gap-2">
                                                        <div
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModalCenter"
                                                            onClick={() => {
                                                                cookies.set(
                                                                    "senderName",
                                                                    user.sender_name
                                                                );
                                                                cookies.set(
                                                                    "id",
                                                                    user.sender
                                                                );
                                                            }}
                                                            className="bg-slate-100  py-2 rounded-lg text-blue-600 "
                                                        >
                                                            View Message
                                                        </div>

                                                        <Link
                                                            to={`/user-detail/${user.sender}`}
                                                        >
                                                            <div className="bg-slate-100  py-2 rounded-lg text-blue-600 ">
                                                                View User
                                                            </div>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                        </table>

                        <div
                            className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                            id="exampleModalCenter"
                            tabIndex="-1"
                            aria-labelledby="exampleModalCenterTitle"
                            aria-modal="true"
                            role="dialog"
                        >
                            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                                <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-lg outline-none text-current">
                                    <div className=" bg-blue-200  rounded-t-lg w-full h-auto p-4 md:pl-10 md:pr-10">
                                        <div className="flex justify-between ">
                                            <button
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                className="text-sky-600 flex"
                                            >
                                                <ArrowBackIosIcon
                                                    className="mt-1"
                                                    style={{
                                                        fontSize: "medium",
                                                    }}
                                                />
                                                <p className="font-semibold">
                                                    Back
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="modal-header p-4 rounded-t-md grid grid-cols-5">
                                        {/*<div>*/}
                                        {/*    <img className="w-10 h-10 rounded-full" src={cookies.get("image")} alt=""/>*/}

                                        {/*</div>*/}
                                        <div
                                            className="text-xl ml-4 break-words font-medium leading-normal text-gray-800 col-span-4"
                                            id="exampleModalCenteredScrollableLabel"
                                        >
                                            {mHeader}
                                        </div>
                                    </div>
                                    <div className="modal-body break-words relative p-4">
                                        <div>{mMessage}</div>
                                    </div>
                                    <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-start p-4 border-t border-gray-200 rounded-b-md">
                                        <Link to="/send-reply">
                                            <button
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                type="button"
                                                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded-3xl shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                                            >
                                                Reply
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Box
                sx={{ display: "flex", justifyContent: "center" }}
                marginBottom={"2rem"}
                mt={"1rem"}
            >
                <Pagination
                    count={totalPages}
                    color="primary"
                    shape="rounded"
                    onChange={(e, page) => {
                        getData(page);
                    }}
                />
            </Box>
                </>
            ) : (
                <div>
                    {loading ? (
                        <Spinner />
                    ) : (
                        <EmptyState
                            btn="New Message"
                            img={NoMessage}
                            title="No Messages Found"
                            message="There are currently no messages to display. Stay connected and check back later for any new messages"
                            btnLink="/send-message"
                        />
                    )}
                </div>
            )}
            
        </div>
    );
};

export default Message;
