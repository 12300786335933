import { isValidUrl } from "../utils/validation";

export function isValidMeeting(data) {
    if (!data.title) return "Please add meeting subject";
    if (!data.date || data.date === "Invalid date")
        return "Please select a date";
    if (!data.start_time || data.start_time === "Invalid date:Invalid date")
        return "Please select a start time";
    if (!data.user) return "Please tag an user";
    if (!data.duration) return "Please add a duration";
    if (!data.meeting_link || isValidUrl(data.meeting_link) === false)
        return "Please add a valid meeting link";

    return null;
}
