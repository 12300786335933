import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import moment from "moment";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { TextField } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const EditTutorial = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState([]);
  const [videoPreview, setVideoPreview] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const getTutorial = () => {
    axios
      .get(
        `hri_admin/help-tutorial-detail/${localStorage.getItem(
          "TutorialId"
        )}?help_section=${localStorage.getItem("help-type")}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        console.log("Tutorial detail data: ", res.data);
        setTitle(res.data.title);
        setDescription(res.data.description);
        setVideo(res.data.tutorial_video);
        setVideoUrl(res.data.tutorial_video_url);
      })
      .catch((err) => {
        console.log("Error Loading Tutorial detail data", err.response);
      });
  };

  const updateTutorial = (e) => {
    e.preventDefault();

    let formField = new FormData();

    formField.append("title", title);
    formField.append("description", description);
    formField.append("tutorial_video", video);
    formField.append("tutorial_video_url", videoUrl);

    axios
      .put(
        `hri_admin/help-tutorial-update/${localStorage.getItem(
          "TutorialId"
        )}?help_section=${localStorage.getItem("help-type")}`,
        formField,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        console.log("Updated Successfully", res.data);
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
        navigate(`/help/${localStorage.getItem("help-type")}/tutorial`);
      })
      .catch((err) => {
        console.log("Some Error occurred updating", err.response.data);
        toast.error("Error occurred updating", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  useEffect(() => {
    getTutorial();
  }, []);

  return (
    <div>
      <div className="bg-white px-5 py-4 m-2 rounded-md z-10">
        <p className="text-xl text-[#0856B6] font-bold">Edit Tutorial</p>
        <div className="space-y-5 px-4 my-4">
          <div>
            <TextField
              fullWidth
              type="text"
              label="Tutorial Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div>
            <TextField
              fullWidth
              multiline
              minRows={3}
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="flex">
            <TextField
              type="url"
              className="w-96"
              label="Video URL"
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
            />
          </div>

          <Button
            className="bg-[#0865B6]"
            variant="contained"
            component="label"
          >
            Upload New Video
            <input
              hidden
              accept="video/mp4"
              type="file"
              onChange={(e) => {
                setVideo(e.target.files[0]);
                setVideoPreview(URL.createObjectURL(e.target.files[0]));
              }}
            />
          </Button>

          <ReactPlayer
            url={videoPreview}
            className="react-player"
            width="25%"
            height="25%"
          />

          <div className="flex items-center space-x-3">
            <Button
              variant="contained"
              startIcon={<DoneAllRoundedIcon />}
              onClick={updateTutorial}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                navigate(`/help/${localStorage.getItem("help-type")}/tutorial`)
              }
            >
              x Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTutorial;
