import React, {useState} from "react";
import Button from "@mui/material/Button";
import {TextField} from "@mui/material";
import axios from "axios";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import Cookies from "universal-cookie";
const cookies = new Cookies();

const AddCategory = () => {

    const navigate = useNavigate();

    const [categoryName, setCategoryName] = useState("");
    const [categoryDescription, setCategoryDescription] = useState("");

    const addCategory = () => {
        axios
            .post(
                `hri_admin/help-category-create?help_section=${localStorage.getItem("help-type")}`,
                {
                    name: categoryName,
                    description: categoryDescription,
                },
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                console.log("Category Added Successfully", res.data)
                toast.success("Category Added Successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: {backgroundColor: "#1ab394", color: "white"},
                    icon: false,
                    progressStyle: {backgroundColor: "#BAE8DE"},
                });
                navigate(`/help/${localStorage.getItem("help-type")}/category/add-qa`);
            })
            .catch((err) => {
                console.log("Error: ", err.response);
                if (categoryName === ""){
                    toast.error("Please Enter the Category Name", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: {backgroundColor: "#FF6347", color: "white"},
                    icon: false,
                    progressStyle: {backgroundColor: "#FFB1A3"},
                });
                } else if(categoryDescription === ""){
                    toast.error("Please Enter the Category Description", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: {backgroundColor: "#FF6347", color: "white"},
                    icon: false,
                    progressStyle: {backgroundColor: "#FFB1A3"},
                });
                } else{
                    toast.error("Error Adding Category", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: {backgroundColor: "#FF6347", color: "white"},
                    icon: false,
                    progressStyle: {backgroundColor: "#FFB1A3"},
                });
                }
            })
    }


    return (
        <div className="bg-white px-2 py-4 m-4 rounded-md z-10 md:px-5">
            <div className="mx-4 space-y-4 md:flex-row">
                <p className="text-2xl mb-4 text-[#0856B6] font-bold">
                    Add New Category
                </p>
                <TextField
                    fullWidth
                    label="Category Name"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                />
                <TextField
                    fullWidth
                    multiline
                    minRows={2}
                    label="Category Description"
                    value={categoryDescription}
                    onChange={(e) => setCategoryDescription(e.target.value)}
                />
                <Button
                    variant="contained"
                    className="bg-[#0865B6]"
                    onClick={addCategory}
                    endIcon={<AddCircleOutlineIcon/>}
                >
                    Add
                </Button>
            </div>

        </div>
    )
}

export default AddCategory;