import React, {useEffect} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {Button, Divider} from "@mui/material";
import LandingPage from "./Landing Page";


import Cookies from "universal-cookie";
const cookies = new Cookies();

const Promotion = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (cookies.get("token")) {

        } else {
            navigate("/");
        }
    },[]);

    return (
        <div>
            <div className="flex py-2 px-10 gap-4">
                {/*<Button*/}
                {/*    style={{color: "#0865B6", backgroundColor: "#F1F1F1", borderRadius: "5px", fontWeight: "bold"}}*/}
                {/*    className="my-2 px-4"*/}
                {/*    onClick={() => navigate("/settings/testimonials")}*/}
                {/*>*/}
                {/*    Settings*/}
                {/*</Button>*/}
                <Button
                    style={{color: "#0865B6", backgroundColor: "#F1F1F1", borderRadius: "5px", fontWeight: "bold"}}
                    className="my-2 px-4"
                    onClick={() => navigate(`/settings/promotion`)}
                >
                    Landing Page
                </Button>

            </div>

            <Divider/>

            <Routes>
                {/*<Route path={"/"} element={<Settings/>}/>*/}
                <Route path={"/"} element={<LandingPage/>}/>
            </Routes>
        </div>
    )
}

export default Promotion;