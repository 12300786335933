import { Add, Delete, Edit } from "@mui/icons-material";
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import JobQuestionFormModal from "./JobQuestionFormModal";
import { blue } from "@mui/material/colors";

/**
 * @typedef {object} Props
 * @property {string} title
 * @property {(title:string)=>void}setTitle
 * @property {string} description
 * @property {(description:string)=>void}setDescription
 * @property {import("../../models/JobModel").JobQuestionnaire[]} JobQuestionnaire
 * @property {React.Dispatch<React.SetStateAction<import("../../models/JobModel").JobQuestionnaire[]>>} setJobQuestionnaire
 * @property {React.ReactNode} children
 * @property {(index:number, questionId:number)=>void} removeQuestionFunc
 */

/**
 * @param {Props} props
 */
export default function Questionnaire({
    title,
    setTitle,
    description,
    setDescription,
    JobQuestionnaire,
    setJobQuestionnaire,
    children,
    removeQuestionFunc
}) {
    const [showJobCard, setShowJobCard] = useState(false);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(-1);

    function handleEdit(jobQuestionIndex) {
        setSelectedQuestionIndex(jobQuestionIndex);
        setShowJobCard(true);
    }



    return (
        <Box>
            <TextField
                label="Title"
                value={title}
                onChange={e => setTitle(e.target.value)}
                fullWidth
            />
            <TextField
                label="Description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                fullWidth
                rows={5}
                multiline
                sx={{ marginTop: "1rem" }}
            />
            {children && <Box sx={{ marginTop: "1rem" }}>{children}</Box>}
            <Button
                variant="contained"
                sx={{ marginTop: "2rem" }}
                onClick={() => setShowJobCard(true)}
            >
                <Add fontSize="small" sx={{ marginRight: "10px" }} />
                Add Question
            </Button>

            {JobQuestionnaire.length > 0 && (
                <TableContainer
                    component={Paper}
                    elevation={2}
                    sx={{ width: "100%", marginTop: "2rem" }}
                >
                    <Table>
                        <TableHead sx={{ backgroundColor: blue[700] }}>
                            <TableRow>
                                <TableCell sx={{ color: "white" }}>
                                    Question
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                    Description
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                    Answer mode
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                    Required
                                </TableCell>
                                <TableCell sx={{ color: "white" }}>
                                    Options
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {JobQuestionnaire.map((data, index) => (
                                <TableRow key={index}>
                                    <TableCell>{data.question}</TableCell>
                                    <TableCell>{data.description}</TableCell>
                                    <TableCell>{data.answerMode}</TableCell>
                                    <TableCell>
                                        {data.required
                                            ? "Required"
                                            : "Not Required"}
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            <button
                                                onClick={() =>
                                                    handleEdit(index)
                                                }
                                            >
                                                <Edit color="primary" />
                                            </button>
                                            <button
                                                onClick={() =>
                                                    removeQuestionFunc(
                                                        index,
                                                        data.id
                                                    )
                                                }
                                                style={{ marginLeft: "20px" }}
                                            >
                                                <Delete color="error" />
                                            </button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <JobQuestionFormModal
                index={selectedQuestionIndex}
                open={showJobCard}
                onClose={() => {
                    setSelectedQuestionIndex(-1);
                    setShowJobCard(false);
                }}
                JobQuestionnaire={JobQuestionnaire}
                setJobQuestionnaire={setJobQuestionnaire}
            />
        </Box>
    );
}
