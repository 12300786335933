
export const API = {
    message: {
        getMessage: "/hri_admin/message",
        adminMessages: "/hri_admin/adminemail",
        senMessage: "/hri_admin/message/send",
        deleteMessage: "/hri_admin/message/delete"
    }
};

export const ObjType = {
    businessData: "BusinessData",
    httpResponse: "HttpResponse",
};

export const HTTPStatus = {
    SUCCESS: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    SERVER_ERROR: 500,
  };
  