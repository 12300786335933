import { Box, Button, CircularProgress, Container, Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { getSurveyResult } from "../../../controller/SurveyController";
import { handleControllerResultException } from "../../../utils/handleException";
import { ArrowBackIos } from "@mui/icons-material";

export default function ApplicantServeyResult() {
    const [surveyResultList, setSurveyResultList] =
        useState(surveyResultListType);
    const [fetchingResult, setFetchingResult] = useState(false)
    const { jobId, userId } = useParams();
    const navigate = useNavigate();

    const handleFetchSurveyResult = useCallback(
        async (jobId, userId) => {
            setFetchingResult(true)
            const res = await getSurveyResult(jobId, userId);
            setFetchingResult(false)
            if (res[0] !== null) {
                setSurveyResultList(res[0]);
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    useEffect(() => {
        if (userId && jobId) {
            handleFetchSurveyResult(jobId, userId);
        }
    }, [jobId, userId, handleFetchSurveyResult]);

    if (fetchingResult) {
        return <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <CircularProgress />
        </Box>
    }

    return (
        <Box>
            <Header />
            <Container maxWidth="xl" sx={{ marginY: "5rem" }}>
                <Button onClick={() => navigate(-1)} sx={{ marginBottom: "2rem" }}>
                    <ArrowBackIos />
                    Return
                </Button>
                {surveyResultList.length > 0 &&
                    surveyResultList.map((result, index) => (
                        <Box key={result.id} component={Paper} elevation={2} marginBottom={"1rem"} padding={"1rem"}>
                            <Typography fontSize={"16px"} fontWeight={"bold"}>
                                <span style={{ marginRight: "5px" }}>{index + 1}.</span>{result.question}
                            </Typography>
                            {result.questionType !== "ChoiceField" ? (
                                <Typography fontSize={"16px"}>
                                    Ans: {result.answer}
                                </Typography>
                            ) : (
                                <Typography fontSize={"16px"}>

                                    Ans: {result.options}</Typography>
                            )}
                        </Box>
                    ))}
            </Container>
        </Box>
    );
}

/**
 * @type {import('../../../models/SurveyResultModel').SurveyResultModel[]}
 */
const surveyResultListType = [];
