import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { getApplicationDetail } from "../../controller/UserApplicationController";
import { handleControllerResultException } from "../../utils/handleException";
import {
    ArrowBackIos,
    Call,
    Download,
    Email,
    GitHub,
    Groups,
    LinkedIn,
} from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import ApplicantPersonalDetails from "./ApplicantPersonalDetails";
import ApplicantWorkExperience from "./ApplicantWorkExperience";
import ApplicantCertificates from "./ApplicantCertificates";
import ApplicationLinkedButton from "./ApplicationLinkedButton";
import ApplicantEducation from "./ApplicantEducation";
import ScheduleMeetingModal from "../../components/ScheduleMeet/ScheduleMeetingModal";

/**
 * @type {import("../../models/UserApplicationModel").UserApplicationModel}
 */
const applicationDetailsType = {
    id: 0,
    firstName: "",
    lastName: "",
    profilePic: "",
    martialStatus: "",
    gender: "",
    dateOfBirth: "",
    email: "",
    phoneNumber: "",
    address: "",
    currentAddress: "",
    pinCode: 0,
    city: "",
    state: "",
    country: "",
    education: {
        title: "",
        course: "",
        courseType: "",
        instituteName: "",
        isCompleted: false,
        passingYear: "",
        specialization: "",
    },
    resume: {
        resumeFileLink: "",
        resumeFileName: "",
        resumeVideoFileName: "",
        resumeVideoLink: "",
    },
    language: "",
    linkedinLink: "",
    githubLink: "",
    status: "",
    workExperience: [],
    userSkills: [],
    certificates: [],
};

export default function ApplicationDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const url = new URLSearchParams(window.location.search);
    const applicationId = url.get("applicationId");

    const [applicationDetails, setApplicationDetails] = useState(
        applicationDetailsType
    );

    const [showScheduleMeetingModal, setShowSheduleMeetingModal] =
        useState(false);
    const [loading, setLoading] = useState(false);

    const handleApplicationDetails = useCallback(
        async id => {
            setLoading(true);
            const res = await getApplicationDetail(
                id,
                applicationId
            );
            setLoading(false);
            if (res[0] !== null) {
                setApplicationDetails(res[0]);
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    useEffect(() => {
        if (id) {
            handleApplicationDetails(id);
        }
    }, [id, handleApplicationDetails]);

    if (loading) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <CircularProgress sx={{ height: "400px", width: "400px" }} />
            </Box>
        );
    }

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ marginX: "auto", paddingY: "4rem" }}>
                <Button
                    onClick={() => navigate(-1)}
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                    <ArrowBackIos
                        fontSize="small"
                        sx={{ marginRight: "5px" }}
                    />
                    Return
                </Button>
                <Grid container spacing={2} sx={{ marginTop: "2rem" }}>
                    <Grid item md={6} xs={12}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Avatar
                                src={applicationDetails.profilePic}
                                alt={applicationDetails.firstName}
                                sx={{ height: "80px", width: "80px" }}
                            />
                            <Box marginLeft={"20px"}>
                                <Typography
                                    fontSize={"20px"}
                                    fontWeight={"500px"}
                                    textTransform={"capitalize"}
                                >
                                    {applicationDetails.firstName}{" "}
                                    {applicationDetails.lastName}
                                </Typography>
                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    marginTop={"5px"}
                                >
                                    <ApplicationLinkedButton
                                        Icon={Call}
                                        text="Call"
                                        link={`tel:${applicationDetails.phoneNumber}`}
                                    />
                                    <ApplicationLinkedButton
                                        Icon={Email}
                                        text="Email"
                                        link={`mailto:${applicationDetails.email}`}
                                    />
                                    {applicationDetails.githubLink !== "" && (
                                        <ApplicationLinkedButton
                                            Icon={GitHub}
                                            text="Github"
                                            link={applicationDetails.githubLink}
                                        />
                                    )}
                                    {applicationDetails.linkedinLink !== "" && (
                                        <ApplicationLinkedButton
                                            Icon={LinkedIn}
                                            text={"LinkedIn"}
                                            link={
                                                applicationDetails.linkedinLink
                                            }
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Button
                            sx={{
                                backgroundColor: blue[50],
                                fontWeight: "bold",
                                marginRight: "10px",
                            }}
                            onClick={() => setShowSheduleMeetingModal(true)}
                        >
                            Schedule Meeting
                            <Groups
                                fontSize="small"
                                sx={{ marginLeft: "10px" }}
                            />
                        </Button>
                        <a
                            href={applicationDetails.resume.resumeFileLink}
                            target="_blank"
                        >
                            <Button
                                sx={{
                                    backgroundColor: blue[50],
                                    fontWeight: "bold",
                                }}
                            >
                                Download Resume
                                <Download
                                    fontSize="small"
                                    sx={{ marginLeft: "10px" }}
                                />
                            </Button>
                        </a>
                    </Grid>
                </Grid>

                {/* This componnets show applicant personal information and skills / strengths */}
                <ApplicantPersonalDetails application={applicationDetails} />

                {applicationDetails.workExperience.length > 0 && (
                    <>
                        <hr />

                        {/* This component show applicant work experience  */}
                        <ApplicantWorkExperience
                            application={applicationDetails}
                        />
                    </>
                )}
                <hr />
                <ApplicantEducation education={applicationDetails.education} />
                {applicationDetails.certificates.length > 0 && (
                    <>
                        {" "}
                        <hr />
                        {/* This component show applicant cerificates */}
                        <ApplicantCertificates
                            application={applicationDetails}
                        />
                    </>
                )}
            </Container>
            <ScheduleMeetingModal
                open={showScheduleMeetingModal}
                onClose={() => setShowSheduleMeetingModal(false)}
                userEmail={applicationDetails.email}
            />
        </Box>
    );
}
