import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
// import {  } from 'antd';
import {
    Button,
    MenuItem,
    Stack,
    Select,
    InputLabel,
    FormControl,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
    LocalizationProvider,
    MobileDatePicker,
    MobileTimePicker,
} from "@mui/lab";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { toast } from "react-toastify";
import { MobileNumberInput } from "../../components/Input/MobileNumberInput";
import { isValidInterview } from "../../helpers/validate_interview";

const cookies = new Cookies();

const NewInterview = () => {
    const navigate = useNavigate();

    const [list, setList] = useState([]);
    const [input, setInput] = useState("")
    const [loading, setLoading] = useState(true)
    const [id, setId] = useState("");
    const [phone, setPhone] = useState("");
    const [exp, setExp] = useState("");
    const [skill, setSkill] = useState("");
    const [job_description, setJob_description] = useState({});
    const [jobList, setJobList] = useState(null);
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [duration, setDuration] = useState("30");
    const [qna, setQna] = useState(null);
    const [qnaSel, setQnaSel] = useState(null);

    async function searchText() {
        setLoading(true);
        getInterviewList(input);
        setLoading(false);
      }
    
      useEffect(() => {
        let timeOut = 0;
    
        timeOut = setTimeout(() => {
          searchText();
        }, [700]);
    
        return () => {
          clearTimeout(timeOut);
        };
      }, [input]);

    function getInterviewList(input) {
        axios
            .get(`hri_admin/interview-job-list?keyword=${input}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(response => {
                setJobList(response.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    function createInterview() {
        let ftime = moment(time).format("hh:mm A");

        const data = {
            user: id,
            interviewer_no: phone,
            experience: exp,
            job_description: job_description?.id,
            skill,
            date,
            time: ftime,
            duration,
            review: qnaSel?.id,
        };
        const error = isValidInterview(data);
        if (error) return toast.error(error);

        axios
            .post("hri_admin/interview-create", data, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(response => {
                console.log(response);
                // setItems(response.data)
                navigate("/interview/All");
            })
            .catch(err => {
                if (err.response?.status === 400) {
                    return toast.warning("Check the details again");
                }
                toast.warning(err.response?.data?.message || err.message);
                console.log({ err });
            });
    }

    useEffect(() => {
        axios
            .get("hri_admin/username", {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(response => {
                setList(response.data);
            })
            .catch(err => {
                console.log(err);
            });

        axios
            .get("/survey/interview-review-form-list", {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then(response => {
                setQna(response.data);
            });

        getInterviewList()
    }, []);

    return (
        <div className="bg-default-background w-full">
            <Header />
            <Sidebar />
            <div className="pb-10">
                <div className="mt-2 mx-2 bg-white pb-10">
                    <div
                        onClick={() => navigate(-1)}
                        className="text-blue-600 text-xl cursor-pointer lg:ml-8"
                    >
                        <NavigateBeforeIcon />
                        Back
                    </div>
                    {/* <div className="text-base font-semibold lg:ml-[15rem] mt-[1rem] ml-[5rem]">Date: 12/12/2022</div> */}
                    <div className="grid place-items-center gap-4">
                        <div className="text-lg font-semibold text-gray-600 my-10">
                            Create Interview
                        </div>

                        {/* <div className="flex flex-col gap-4"> */}
                        <div className="grid col-span-1 md:grid-cols-2 gap-4 mt-4">
                            <div className="">
                                <Autocomplete
                                    disablePortal
                                    // value={email}
                                    onChange={(e, newValue) => {
                                        setId(newValue.user_id);
                                    }}
                                    id="combo-box-demo"
                                    getOptionLabel={option => option.email}
                                    options={list}
                                    renderOption={(props, option) => (
                                        <li {...props}>{option.email}</li>
                                    )}
                                    sx={{ width: 300 }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Search User*"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: "search",
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    label="Skills*"
                                    onChange={e => setSkill(e.target.value)}
                                />
                            </div>
                            <div className="">
                                {/* <TextField
                                    type="number"
                                    fullWidth
                                    label="Interviewer number*"
                                    onChange={e => setPhone(e.target.value)}
                                /> */}
                                <MobileNumberInput
                                    onChange={num => setPhone(num)}
                                />
                            </div>
                            <FormControl>
                                {/* <TextField
                                    fullWidth
                                    label="Experience"
                                    onChange={(e) => setExp(e.target.value)}
                                /> */}
                                <InputLabel id="demo-simple-select-label">
                                    Select Experience*
                                </InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    labelId="demo-simple-select-label"
                                    value={exp}
                                    onChange={e => setExp(e.target.value)}
                                    label="Select Experience"
                                    fullWidth
                                >
                                    <MenuItem value="0 to 1 years">
                                        0 to 1 years
                                    </MenuItem>
                                    <MenuItem value="1 to 2 years">
                                        1 to 2 years
                                    </MenuItem>
                                    <MenuItem value="3 to 5 years">
                                        2 to 5 years
                                    </MenuItem>
                                    <MenuItem value="5+ years">
                                        5+ years
                                    </MenuItem>
                                    <MenuItem value="10+ years">
                                        10+ years
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <div className="">
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <Stack width="full">
                                        <MobileDatePicker
                                            disablePast
                                            openTo="day"
                                            views={["year", "month", "day"]}
                                            label="Interview Date*"
                                            value={date}
                                            onChange={newValue => {
                                                setDate(
                                                    moment(newValue).format(
                                                        "YYYY-MM-DD"
                                                    )
                                                );
                                            }}
                                            renderInput={params => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </div>
                            <div>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <Stack width="full">
                                        <MobileTimePicker
                                            views={["hours", "minutes"]}
                                            openTo="hours"
                                            label="Interview Time*"
                                            value={time}
                                            onChange={newValue => {
                                                setTime(newValue);
                                            }}
                                            renderInput={params => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                                {/* <TextField
                                    fullWidth
                                    label="Interview Time"
                                    onChange={(e) => setTime(e.target.value)}
                                /> */}
                            </div>
                            <div>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Duration*
                                    </InputLabel>
                                    <Select
                                        id="demo-simple-select"
                                        labelId="demo-simple-select-label"
                                        value={duration}
                                        onChange={e =>
                                            setDuration(e.target.value)
                                        }
                                        label="Duration"
                                    >
                                        <MenuItem value="15">15 min</MenuItem>
                                        <MenuItem value="30">30 min</MenuItem>
                                        <MenuItem value="45">45 min</MenuItem>
                                        <MenuItem value="60">1 hours</MenuItem>
                                        <MenuItem value="61">1+ hours</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div>
                                <Autocomplete
                                    disablePortal
                                    // value={email}
                                    onChange={(e, newValue) => {
                                        setJob_description(newValue);
                                    }}
                                    id="combo-box-demo"
                                    getOptionLabel={option =>
                                        option.job_unique_id 
                                    }
                                    options={jobList}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            {option.job_unique_id} - {option.position_name}
                                        </li>
                                    )}
                                    sx={{ width: 300 }}
                                    renderInput={params => (
                                        <TextField
                                            value={input}
                                            onChange={(e) => setInput(e.target.value)}
                                            {...params}
                                            label="Select Position*"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: "search",
                                            }}
                                        />
                                    )}
                                />
                                <div className="absolute">
                                    {job_description?.position_name}
                                    {job_description?.company_name
                                        ? ", " + job_description?.company_name
                                        : null}
                                </div>
                            </div>

                            <div>
                                <Autocomplete
                                    disablePortal
                                    // value={email}
                                    onChange={(e, newValue) => {
                                        setQnaSel(newValue);
                                    }}
                                    id="combo-box-demo"
                                    getOptionLabel={option =>
                                        option.form_unique_id
                                    }
                                    options={qna}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            {option.form_unique_id}
                                        </li>
                                    )}
                                    sx={{ width: 300 }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Select Interview Review Form"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: "search",
                                            }}
                                        />
                                    )}
                                />
                                <div className="absolute">{qnaSel?.name}</div>
                            </div>
                        </div>

                        <Button
                            variant="contained"
                            onClick={() => createInterview()}
                            className="rounded-lg border-2 border-[#0865B6] hover:bg-[#055eac] font-bold bg-[#0865B6] lg:w-[15rem] h-[2.5rem] text-white text-lg md:w-[10rem] w-[7rem]"
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewInterview;
