import { Box, Modal} from "@mui/material";
import React from "react";


/**
 * @typedef {object} Props
 * @property {boolean} open
 * @property {()=>void} onClose
 * @property {import("@mui/material").SxProps<import("@mui/material").Theme>} sx
 * @property {React.ReactNode } children
 */


/**
 * @param {Props} props
 */
export default function ModalLayout({
  open,
  onClose,
  sx,
  children,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    p: 4,
    padding:"0px"
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style, ...sx }}>{children}</Box>
    </Modal>
  );
}
