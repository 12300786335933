import React, { useCallback, useEffect, useState } from "react";
import { jobApplicantList } from "../../../controller/JobController";
import { Link, useNavigate } from "react-router-dom";
import { handleControllerResultException } from "../../../utils/handleException";
import {
    Avatar,
    Box,
    Button,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";

/**
 * @typedef {object} Props
 * @property {string | number} jobId
 * @property {boolean} open
 * @property {()=>void} onClose
 * @property {boolean} hasQuestionnaries
 */

/**
 * @param {Props} props
 */
export default function JobAppliedApplicantsModal({
    jobId,
    open,
    onClose,
    hasQuestionnaries,
}) {
    const [applicantList, setApplicantList] = useState(applicantListType);
    const navigate = useNavigate();

    const handleApplicantList = useCallback(
        async jobId => {
            const res = await jobApplicantList(jobId);
            if (res[0] !== null) {
                setApplicantList(res[0]);
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    useEffect(() => {
        if (jobId) {
            handleApplicantList(jobId);
        }
    }, [jobId, handleApplicantList]);
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                        lg: "70%",
                        md: "80%",
                        xs: "90%",
                    },
                    maxHeight: "800px",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    overflow: "auto",
                }}
            >
                {applicantList.length > 0 ? (
                    <TableContainer component={Paper} elevation={2}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead sx={{ backgroundColor: blue[700] }}>
                                <TableRow>
                                    <TableCell sx={{ color: "white" }}>
                                        Name
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Contact details
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Address
                                    </TableCell>
                                    {hasQuestionnaries && (
                                        <TableCell sx={{ color: "white" }}>
                                            Servey result
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {applicantList.map(data => (
                                    <TableRow key={data.id}>
                                        <TableCell
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Avatar
                                                src={data.profilePic}
                                                alt={data.firstName}
                                                sx={{ marginRight: "5px" }}
                                            />
                                            <Typography>
                                                {data.firstName} {data.lastName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {data.phoneNumber}/{data.email}
                                        </TableCell>
                                        <TableCell>
                                            {data.city},{data.state},
                                            {data.country},{data.pinCode}
                                        </TableCell>
                                        {hasQuestionnaries && (
                                            <TableCell>
                                                <Link to={`/survey-result/${jobId}/${data.userId}`}>
                                                    <Button variant="outlined">
                                                        Details
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="h5" sx={{ textAlign: 'center' }}>
                        No Applicants
                    </Typography>
                )}
            </Box>
        </Modal>
    );
}

/**
 * @type {import('../../../models/UserApplicationModel').UserApplicationModel[]}
 */
const applicantListType = [];
